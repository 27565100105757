import {
    Button,
    Card,
    Heading,
    Icon,
    ReorderList,
    TwoColumnLayout,
    ContentLayout,
} from '@oetkerdigital/eden-design-system-react';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { WebsiteScoreValueResponse } from '../../../api-types/search';
import { useHasPermission } from '../../../hooks/useHasPermission';
import { provide } from '../../../util/provide';
import CountrySelector from '../CountrySelector';
import { withSettings } from '../SettingsProvider';
import { MetaTagsValues } from '../util';
import { RankingRow, RankingRowProps } from './RankingRow';
import {
    isDateTag,
    RANGE_VALUES_MAP,
    Tag,
    withRankResults,
    useRankResults,
} from './RankResultsProvider';

/**
 *
 * @param scores
 *
 * @description Transforms scores to array of `'Key': ['Value']` and merges same Keys and concatenates there values
 * @example
 * In:
 * [{Key: "doctype", Value: "Rezepte", ...}, {Key: "doctype", Value: "Tipps & Wissenswertes", ...}]
 *
 * Out:
 * [{doctype: ["Rezepte", "Tipps & Wissenswertes"]}]
 */
const mergeValuesByKey = (scores: WebsiteScoreValueResponse[]) => {
    if (scores.length) {
        return scores.reduce((acc, score) => {
            if (acc[score.Key]) {
                acc[score.Key].push(score.Value);
            } else {
                acc[score.Key] = [score.Value];
            }

            return acc;
        }, {});
    }

    return {};
};

const getTagsValuesForTag = (tag: Tag, tagsValues: MetaTagsValues) => {
    return tagsValues[tag.Name.toLowerCase()];
};

/**
 *
 * @param tags: Array of all available tags in a format like {Name: 'Category', Separators: ',', Type: 'text'}
 * @param tag: Tag coming from the scores like 'meta_tags.category'
 */
const selectUppercasedTag = (tags: Tag[], tag: Tag) => {
    return tags.find(correctTag => correctTag.Name === tag.Name);
};

/**
 *
 * General description of how Ranking Page works,
 * Mainly RankingPage relies on:
 * 1. WebsiteScoreSettings array
 * 2. Array of all available tags
 * 3. Array of all available values for each tag
 */
const __RankResults: FunctionComponent<RouteComponentProps> = () => {
    const {
        scores: initialScores,
        tags,
        metaTagsValues,
        isSaving,
        fetchRequestState,
        save,
        tagChange,
    } = useRankResults();

    const [scores, setScores] = useState(initialScores);

    const [usedTagValues, setUsedTagValues] = useState({});

    const [canSave, setCanSave] = useState(false);

    useEffect(() => {
        setScores(initialScores);
    }, [initialScores]);

    useEffect(() => {
        setUsedTagValues(mergeValuesByKey(scores));
    }, [tags, metaTagsValues, scores]);

    const scoresLength = scores.length;
    const hasWritePermission = useHasPermission('oss:modify');

    const addNewScore = () => {
        const score: WebsiteScoreValueResponse = {
            Weight: 0,
            Key: '',
            Value: '',
            FilterType: 'Match',
        };

        setScores([...scores, score]);
    };

    /**
     *
     * @param index Index from overall scores list to understand in which list index is
     * @param updateFn Callback function to apply changes to the list and not check the list type and setters every time
     */
    const updateScores = (
        index: number,
        updateFn: (scores: WebsiteScoreValueResponse[]) => void
    ) => {
        const updatedScores = [...scores];
        updateFn(updatedScores);
        setScores(updatedScores);
        setCanSave(true);
    };

    /**
     *
     * @param tag
     * @param index
     * @param value
     *
     * @description On tag change there should be API request to get new values per tag and set value to ''
     * But if there is a value change, only value needs to be changed to new one
     */
    const onRowChange = (tag: Tag, index: number, value?: string) => {
        tagChange(tag);

        updateScores(index, (updatedScores: WebsiteScoreValueResponse[]) => {
            updatedScores[index].Key = tag.Name.toLowerCase();
            updatedScores[index].Value = value || '';
            updatedScores[index].FilterType = isDateTag(tag)
                ? 'Range'
                : 'Match';
        });
    };

    const onReorder = (list: WebsiteScoreValueResponse[]) => {
        setScores(list);
        setCanSave(true);
    };

    const onSave = () => {
        save(scores);
        setCanSave(false);
    };

    /**
     * Find a Tag from its Name
     */
    const findTag = (tagName: string) => {
        const tag = tags.find(tag => tag.Name.toLowerCase() === tagName);

        if (tag) {
            return tag;
        }

        return {
            Name: '',
            Separators: '',
            Type: '',
        };
    };

    const renderRow = (tag: Tag, value: string, scoresIndex: number) => {
        const rangeValues = Object.keys(RANGE_VALUES_MAP);

        const rangeValue = rangeValues.indexOf(value) !== -1 ? value : '';

        const props: RankingRowProps = {
            tags,
            tag: selectUppercasedTag(tags, tag),
            // This check is only here to correct invalid publish date values which were date strings before.
            value: isDateTag(tag) ? rangeValue : value,
            // Range type values are always the same and for now hardcoded upper in this file.
            tagValues: isDateTag(tag)
                ? rangeValues
                : getTagsValuesForTag(tag, metaTagsValues),
            usedValues: usedTagValues[tag.Name.toLowerCase()],
            name: `ranking-row-${scoresIndex}`,
            onTagChange: (tag: Tag) => onRowChange(tag, scoresIndex),
            onValueChange: (value: string) =>
                onRowChange(tag, scoresIndex, value),
            isDateTag: isDateTag(tag),
        };

        return <RankingRow {...props} />;
    };

    const isLoading = fetchRequestState === 'loading';
    const showRows = !isLoading && !!tags.length;
    const maxAmount = 10;
    const canAddScore = scoresLength < maxAmount;

    const renderScores = () => {
        return (
            <ContentLayout>
                {!!scoresLength && (
                    <ReorderList
                        aria-label="Positive Scores"
                        items={scores}
                        onReorder={onReorder}
                    >
                        {(item, { index }) =>
                            renderRow(findTag(item.Key), item.Value, index)
                        }
                    </ReorderList>
                )}

                {!canAddScore && <p>Maximum amount of scores is {maxAmount}</p>}

                {canAddScore && (
                    <TwoColumnLayout breakpoint="lg">
                        <Card label="Add ranking" isCTA>
                            <Icon
                                aria-label="Add ranking"
                                name="add"
                                onClick={addNewScore}
                                button
                            />
                        </Card>
                    </TwoColumnLayout>
                )}
            </ContentLayout>
        );
    };

    return (
        <section className="ContentLayout" aria-labelledby="rank-result">
            <Heading id="rank-result">Rank Results</Heading>
            <p>
                To boost areas of your website that are of greater interest to
                your users, and lower the value of those which might not be
                relevant to them, you can rank specific attributes higher in the
                search results. A typical example is prioritising specific
                seasonal recipes above other recipes. This means that ranking
                summer recipes higher will automatically lead to lower ranking
                for Christmas recipes.
            </p>

            <CountrySelector />

            {hasWritePermission && (
                <div className="ContentLayout" aria-label="Scores">
                    {showRows && renderScores()}

                    {isLoading && <div>Loading scores...</div>}

                    <Button disabled={isSaving || !canSave} onClick={onSave}>
                        {isSaving ? 'Saving...' : 'Save'}
                    </Button>
                </div>
            )}
        </section>
    );
};

export const RankResults = provide(
    __RankResults,
    withRankResults(),
    withSettings()
);
