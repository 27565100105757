import { useState } from 'react';

export const useToggle = (
    initialState: boolean,
    persistKey?: string
): [boolean, () => void] => {
    let defaultValue = initialState;

    if (persistKey) {
        const valueFromLocalStorage = localStorage.getItem(persistKey);

        if (valueFromLocalStorage) {
            defaultValue = valueFromLocalStorage === 'true';
        }
    }

    const [value, setValue] = useState(defaultValue);

    const toggle = () => {
        const newValue = !value;
        setValue(newValue);

        if (persistKey) {
            localStorage.setItem(persistKey, newValue ? 'true' : 'false');
        }
    };

    return [value, toggle];
};
