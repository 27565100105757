import React, {
    createContext,
    FunctionComponent,
    useContext,
    useEffect,
    useState,
} from 'react';
import {
    WebsiteScoreSettingResponse,
    WebsiteScoreValueResponse,
} from '../../../api-types/search';
import { useAlerts } from '../../../components/AlertProvider';
import { AuthContext } from '../../../components/AuthGate';
import config from '../../../config';
import { createProviderHoC } from '../../../util/createProviderHoC';
import { ApiRequestResult } from '../../../util/createRequest';
import { useSettings } from '../SettingsProvider';
import {
    addWeightsToScores,
    AggregationsRequestBody,
    formatScores,
    MetaTagsValues,
    RowsValues,
    sortScores,
    WebsiteScoreAggregation,
} from '../util';

type RequestState = 'idle' | 'loading' | 'success';

export type Tag = { Name: string; Separators: string; Type: string };

/**
 * There are some challenges on how aggregations results are returned from ElasticSearch
 * and BE cannot return proper values which can be found in RANGE_VALUES_MAP so we need to hardcode it for now.
 * As Aggregations call return meta tags with values we cannot mark which type they have "Match"(default type) or "Range"
 */
export const RANGE_VALUES_MAP = {
    'now-1M': 'Last month',
    'now-3M': 'Last 3 months',
    'now-6M': 'Last 6 months',
    'now-1y': 'Last year',
    'now-2y': 'Last 2 years',
    'now-3y': 'Last 3 years',
    'now-4y': 'Last 4 years',
    'now-5y': 'Last 5 years',
};

/**
 * Helping util to chose if tag in of type date
 * For sake of clear picture this is equal to ElasticSearch "Range" type
 */
export const isDateTag = (tag: Tag) => tag.Type === 'date';

interface RankResultsContextState {
    scores: WebsiteScoreValueResponse[];
    tags: Tag[];
    error?: string;
    isSaving: boolean;
    fetchRequestState: RequestState;
    save: (positiveScores: WebsiteScoreValueResponse[]) => void;
    tagChange: (tag: Tag) => void;
    metaTagsValues: MetaTagsValues;
}

interface FetchConfig {
    url: string;
    body?: Record<string, any>;
    method?: 'get' | 'post' | 'put';
}

const RankResultsContext = createContext<RankResultsContextState | undefined>(
    undefined
);

const RankResultsProvider: FunctionComponent = ({ children }) => {
    const { searchIndex, getRequestHeaders } = useSettings();
    const { api } = useContext(AuthContext);
    const alerts = useAlerts();

    const [isSaving, setIsSaving] = useState(false);

    const [fetchRequestState, setFetchRequestState] =
        useState<RequestState>('idle');

    /**
     * These scores are from API request /websiteScoreSetting and not actively used in RankingPage
     Just used to setup default scores. Not changed anyhow by the app. Also used to updateScores
    */
    const [scoreSettings, setScoreSettings] = useState<
        Omit<WebsiteScoreSettingResponse, 'WebsiteScoreValues'>
    >({
        AddRanking: true,
        RankingWeight: 0,
        AddVisits: true,
        VisitWeight: 0,
    });
    /**
     * These actively changed scores by the app and used for any changes to the scores
     */
    const [scoreValues, setScoreValues] = useState<WebsiteScoreValueResponse[]>(
        []
    );
    /**
     * These are all available metaTags for Tag Selector in RankingRow
     */
    const [metaTags, setMetaTags] = useState<Tag[]>([]);

    /**
     * These are all available values per metaTag like: { doctype: ['1','2','3'] }
     */
    const [metaTagsValues, setMetaTagsValues] = useState<MetaTagsValues>({});

    const rankResultDataFetcher = async <T extends any>({
        url,
        body,
        method = 'get',
    }: FetchConfig): Promise<T | undefined> => {
        const [err, data]: ApiRequestResult<T> = await api[method]({
            baseUrl: config.SEARCH_OPTIMIZATION_API_BASE_URL,
            url,
            headers: getRequestHeaders(),
            body,
        });

        if (err) {
            alerts.error(err);
            return;
        }

        if (data) {
            return data;
        }

        return;
    };

    const intoPayload = tag => ({
        Field: `meta_tags.${tag.toLowerCase()}.keyword`,
        Size: 0,
    });

    useEffect(() => {
        let isCancelled = false;

        const fetchScores = async () => {
            // If metaTags don't exists yet, we need to fetch them before gettings ranking scores, because they are co-dependent
            if (!metaTags || !metaTags.length) {
                const data = await rankResultDataFetcher({
                    url: '/settings/indexedMetaTags',
                });

                if (data && !isCancelled) {
                    setMetaTags(data);
                } else {
                    return;
                }
            }

            setFetchRequestState('loading');

            const data =
                await rankResultDataFetcher<WebsiteScoreSettingResponse>({
                    url: '/settings/websiteScoreSetting',
                });

            if (isCancelled) {
                return;
            }

            // Catch this error with UK-EN index for Backend test reasons.
            // It should return correct websiteScoreSettings object with empty scores, but it returns null
            if (!data) {
                alerts.error(
                    'Error occured. Please try another index or contact us'
                );
                return;
            }

            const {
                AddRanking,
                AddVisits,
                RankingWeight,
                VisitWeight,
                WebsiteScoreValues,
            } = data;

            setFetchRequestState('idle');
            setScoreSettings({
                AddRanking,
                RankingWeight,
                AddVisits,
                VisitWeight,
            });
            setScoreValues(sortScores(formatScores(WebsiteScoreValues)));
        };

        if (searchIndex) {
            fetchScores();
        }

        return () => {
            isCancelled = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchIndex]);

    useEffect(() => {
        let isCancelled = false;

        const fetchTagValues = async () => {
            setFetchRequestState('loading');

            const intoTagString = score => score.Key;

            // 1. Map array ob objects into array of tag strings
            // 2. Filter by unique Key property (hello Set)
            // 3. Create array out of Set
            // 4. Map Array of string into requestPayload object
            const requestPayload = Array.from(
                new Set(scoreValues.map(intoTagString))
            ).map(intoPayload);

            const data = await rankResultDataFetcher({
                url: '/settings/aggregations',
                body: requestPayload,
                method: 'post',
            });

            if (isCancelled) {
                return;
            }

            const inTagsValues = (acc, entry) => {
                // Response tag looks like meta_tags.{tag}.keyword but
                // for ease of use and consistensy it is transform to {tag}
                const tag = entry[0].split('.')[1];
                acc[tag] = Object.keys(entry[1]);

                return acc;
            };

            const tagsValues = Object.entries(data.Aggregations).reduce(
                inTagsValues,
                {}
            );

            setFetchRequestState('idle');
            setMetaTagsValues(tagsValues);
        };

        if (scoreValues.length) {
            fetchTagValues();
        }

        return () => {
            isCancelled = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scoreValues]);

    const save = async (scoreValues: WebsiteScoreValueResponse[]) => {
        // At this point we want to add weight to the scores
        // Because for previous steps weight is irrelevant
        const weightedScores = addWeightsToScores(scoreValues);

        const requestPayload = {
            ...scoreSettings,
            WebsiteScoreValues: weightedScores,
        };

        setIsSaving(true);

        const data = await rankResultDataFetcher({
            url: '/settings/websiteScoreSetting',
            method: 'put',
            body: requestPayload,
        });

        if (data) {
            alerts.success('The rankings have been successfully updated');
        }

        setIsSaving(false);
    };

    const tagChange = async (tag: Tag) => {
        // Here API expects to have tag being prefixed by 'meta_tags.' and lowerCased
        const requestPayload: AggregationsRequestBody[] = [
            intoPayload(tag.Name),
        ];

        const data = await rankResultDataFetcher({
            url: '/settings/aggregations',
            method: 'post',
            body: requestPayload,
        });

        const aggregations = Object.values<WebsiteScoreAggregation>(
            data.Aggregations
        )[0];

        const tagValues: RowsValues = {
            tag: tag.Name.toLowerCase(),
            values: Object.keys(aggregations),
        };

        const newMetaTagsValues = { ...metaTagsValues };
        newMetaTagsValues[tagValues.tag] = tagValues.values;

        setMetaTagsValues(newMetaTagsValues);
    };

    return (
        <RankResultsContext.Provider
            value={{
                scores: scoreValues,
                metaTagsValues,
                tags: metaTags,
                isSaving,
                fetchRequestState,
                save: save,
                tagChange: tagChange,
            }}
        >
            {children}
        </RankResultsContext.Provider>
    );
};

const withRankResults = createProviderHoC(RankResultsProvider);

export const useRankResults = () => {
    const context = useContext(RankResultsContext);

    /* istanbul ignore if */
    if (!context) {
        throw new Error(
            '`useRankResults` must be used within a `RankResultsProvider`!'
        );
    }

    return context;
};

export { RankResultsContext, withRankResults };
