import React, { FunctionComponent, createContext, useContext } from 'react';
import useSettingsApi, { SettingsApiState } from '../useSettingsApi';
import { createProviderHoC } from '../../../util/createProviderHoC';
import { useErrorAlert } from '../../../components/AlertProvider';
import { identity } from '../util';
import { WebsiteSettingsResponse } from '../../../api-types/search';

type DataType = WebsiteSettingsResponse | undefined;

type WebsiteSettingsProviderState = SettingsApiState<DataType>;

const WebsiteSettingsContext = createContext<
    WebsiteSettingsProviderState | undefined
>(undefined);

const WebsiteSettingsProvider: FunctionComponent = ({ children }) => {
    const settingsApi = useSettingsApi<DataType, DataType>({
        url: '', // `/settings`
        toData: identity,
        toDisplay: identity,
        defaultData: undefined,
    });

    useErrorAlert(settingsApi.fetchError);
    useErrorAlert(settingsApi.saveError);

    return (
        <WebsiteSettingsContext.Provider value={settingsApi}>
            {children}
        </WebsiteSettingsContext.Provider>
    );
};

export const withWebsiteSettings = createProviderHoC(WebsiteSettingsProvider);

export const useWebsiteSettings = () => {
    const context = useContext(WebsiteSettingsContext);

    /* istanbul ignore if */
    if (!context) {
        throw new Error(
            '`useWebsiteSettings` must be used within a `WebsiteSettingsProvider`!'
        );
    }

    return context;
};
