import React, { createContext, FunctionComponent } from 'react';
import { createProviderHoC } from '../../../util/createProviderHoC';
import useSproutApi, { SproutApiState } from '../useSproutApi';

type CountriesContextState = SproutApiState;

const CountriesContext = createContext<CountriesContextState>(
    {} as CountriesContextState
);

const CountriesProvider: FunctionComponent = ({ children }) => {
    const sproutApi = useSproutApi({ categoryCode: 'country' });

    return (
        <CountriesContext.Provider value={sproutApi}>
            {children}
        </CountriesContext.Provider>
    );
};

const withCountries = createProviderHoC(CountriesProvider);

export { CountriesContext, withCountries };
export default CountriesProvider;
