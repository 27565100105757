import { useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { WebsiteAggregationResponse } from '../../../api-types/search';
import { useAlerts } from '../../../components/AlertProvider';
import { useApi } from '../../../components/AuthGate';
import config from '../../../config';
import { ApiRequestResult } from '../../../util/createRequest';
import { useSettings } from '../SettingsProvider';

const emptyTagValues = {};

export default function useAggregatedTagValues(tags: string[]) {
    const alerts = useAlerts();
    const api = useApi();
    const { getRequestHeaders } = useSettings();

    const [tagValues, setTagValues] =
        useState<Record<string, string[]>>(emptyTagValues);

    useDeepCompareEffect(() => {
        // If there's no tags, no need to fetch, we know the results are empty
        if (!tags.length) {
            setTagValues(emptyTagValues);
            return;
        }

        const tagsWithValues = Object.keys(tagValues);

        // Do nothing if we already have the data for all tags fetched
        if (
            tags.every(tag => tagsWithValues.includes(tag)) &&
            tagsWithValues.every(tag => tags.includes(tag))
        ) {
            return;
        }

        const payload = tags.map(tag => ({
            Field: tag,
            Size: 0,
        }));

        let isCancelled = false;

        const loadAggregations = async () => {
            const [error, data]: ApiRequestResult<WebsiteAggregationResponse> =
                await api.post({
                    baseUrl: config.SEARCH_OPTIMIZATION_API_BASE_URL,
                    url: '/settings/aggregations',
                    headers: getRequestHeaders(),
                    body: payload,
                });

            if (isCancelled) {
                return;
            }

            if (error) {
                alerts.error(`Failed to fetch values: ${error}`);
                return;
            }

            const newTagValues = Object.keys(data.Aggregations).reduce(
                (acc, key) => {
                    const tag = key.slice(0, '.keyword'.length * -1);

                    const values = Object.keys(data.Aggregations[key]).filter(
                        value => !!value
                    );

                    return {
                        ...acc,
                        [tag]: values,
                    };
                },
                {}
            );

            setTagValues(newTagValues);
        };

        loadAggregations();

        return () => {
            isCancelled = true;
        };
    }, [tags, alerts, api, getRequestHeaders, tagValues]);

    return tagValues;
}
