import React from 'react';
import {
    Heading,
    Link,
    EmptyLayout,
} from '@oetkerdigital/eden-design-system-react';

interface ErrorBoundaryProps {
    errorHandler: any; // No types for the Stackdriver library :(
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
    state = {
        error: undefined,
    };

    componentDidCatch = (error: Error) => {
        console.error(error);

        this.setState({
            error,
        });

        this.props.errorHandler.report(error);
    };

    render = () => {
        if (this.state.error) {
            return (
                <EmptyLayout>
                    <Heading>An error occured</Heading>
                    <Link href="/">Back to Start</Link>
                </EmptyLayout>
            );
        } else {
            return this.props.children;
        }
    };
}
