// Import this directly from the file instead of the root, so that Cypress
// (which also uses this config file) doesn't have to compile ALL the files.
import { createHasFeature } from './util/createHasFeature';

export interface EnvConfig {
    STACKDRIVER_API_KEY: string;
    STACKDRIVER_PROJECT_ID: string;
    STACKDRIVER_REPORTING_ENABLED: boolean;
    RELEASE_ID: string;
    DISABLED_FEATURES: string[];
    BASE_URL: string;
    SEARCH_OPTIMIZATION_API_BASE_URL: string;
    USER_MANAGER_API_BASE_URL: string;
    SHRTY_API_BASE_URL: string;
    SPROUT_API_BASE_URL: string;
    AUTH_AUTHORITY: string;
    GTM: {
        ENABLED: boolean;
        AUTH: string;
        PREVIEW: string;
    };
}

export interface Config extends EnvConfig {
    /**
     * For how long to delay showing a loading state (in ms)
     */
    showLoadingDelay: number;

    hasFeature: (name: string) => boolean;
}

const envConfig: EnvConfig = (window as any).ENV_CONFIG;

const config: Config = {
    ...envConfig,
    showLoadingDelay: 500,
    hasFeature: createHasFeature(envConfig),
};

export default config;
