import React, { FunctionComponent } from 'react';
import { Heading } from '@oetkerdigital/eden-design-system-react';
import { provide } from '../../../util/provide';
import {
    withCurrentUserProfile,
    useCurrentUserProfile,
} from '../../user-manager/users/CurrentUserProfileProvider';

const __Home: FunctionComponent = () => {
    const { currentUser, isCurrentUserLoading } = useCurrentUserProfile();
    const userName = currentUser ? currentUser.FirstName : '';

    if (isCurrentUserLoading || userName.length === 0) {
        return null;
    }

    return (
        <React.Fragment>
            <Heading>
                Hello {userName}, <br />
                what would you like to do?
            </Heading>

            <p>Please select an EDEN tool from the left side menu</p>
        </React.Fragment>
    );
};

export const Home = provide(__Home, withCurrentUserProfile());
