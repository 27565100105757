import { useContext, useEffect, useState } from 'react';
import { PaginationResponse } from '../api-types/search';
import { AuthContext } from '../components/AuthGate';
import { ApiRequestResult } from '../util/createRequest';

interface PaginatedApiDefaults<ResponseType = any> {
    limit?: number;
    orderingBy: keyof ResponseType;
    isOrderingByDesc?: boolean;
    query?: string;
    filter?: string;
}

interface PaginatedApiParams<ResponseType = any> {
    defaults: PaginatedApiDefaults<ResponseType>;
    baseUrl?: string;
    url: string;
    /**
     * This param will define default fetch url
     * @example
     * {
     *       baseUrl: config.USER_MANAGER_API_BASE_URL,
     *       url: '/roles',
     *       runInitialFetch,
     *      defaults: { ...defaults, limit },
     *   },
     *
     *   where default fetch url would be '/roles'
     *   and if that should be changed due to API update
     *   and new default fetch url should be '/roles/details'
     *   then this param can be used, like:
     *   ...
     *   fetchUrl: '/details',
     *
     *  At the end 'url' will be used as a baseUrl in a Provider for PATCH / DELETE / PUT
     *  and 'fetchUrl' would be used for default GET
     */
    fetchUrl?: string;
    runInitialFetch?: boolean;
    getAdditionalHeaders?: () => Record<string, string> | undefined;
}

export interface PaginatedApiState<ResponseType = any, RequestType = any> {
    data: ResponseType[];
    error: string;
    refetch: () => void;
    create: (body: RequestType) => Promise<ApiRequestResult<ResponseType>>;
    update: (
        id: number | string,
        body: RequestType
    ) => Promise<ApiRequestResult<ResponseType>>;
    patch: (
        id: number | string,
        body: RequestType
    ) => Promise<ApiRequestResult<ResponseType>>;
    delete: (id: number | string) => Promise<ApiRequestResult<null>>;
    fetchOne: (id: number | string) => Promise<ApiRequestResult<ResponseType>>;
    isLoading: boolean;
    limitProps: {
        limit: number;
        limitTo: (limit: number) => void;
    };
    orderByProps: {
        orderingBy: keyof ResponseType;
        isOrderingByDesc: boolean;
        orderBy: (sortKey: keyof ResponseType) => void;
    };
    queryProps: {
        query: string;
        queryBy: (query: string) => void;
    };
    filterProps: {
        filter: string;
        filterBy: (query: string) => void;
    };
}

const ownDefaults = {
    limit: 10,
    isOrderingByDesc: false,
    query: '',
    filter: '',
};

export const paginatedApiStateDefaults: PaginatedApiState = {
    data: [],
    error: '',

    refetch: /* istanbul ignore next - unused default case */ () => {},

    create: /* istanbul ignore next - unused default case */ () =>
        Promise.resolve([null, null]),

    update: /* istanbul ignore next - unused default case */ () =>
        Promise.resolve([null, null]),

    patch: /* istanbul ignore next - unused default case */ () =>
        Promise.resolve([null, null]),

    delete: /* istanbul ignore next - unused default case */ () =>
        Promise.resolve([null, null]),

    fetchOne: /* istanbul ignore next - unused default case */ () =>
        Promise.resolve([null, null]),

    isLoading: false,

    limitProps: {
        limit: ownDefaults.limit,

        limitTo: /* istanbul ignore next - unused default case */ () => {},
    },

    orderByProps: {
        orderingBy: '',

        isOrderingByDesc: ownDefaults.isOrderingByDesc,

        orderBy: /* istanbul ignore next - unused default case */ () => {},
    },

    queryProps: {
        query: ownDefaults.query,

        queryBy: /* istanbul ignore next - unused default case */ () => {},
    },

    filterProps: {
        filter: ownDefaults.filter,

        filterBy: /* istanbul ignore next - unused default case */ () => {},
    },
};

export function usePaginatedApi<ResponseType, RequestType>({
    defaults: theirDefaults,
    baseUrl = '',
    url,
    fetchUrl = '',
    runInitialFetch = true,
    getAdditionalHeaders = () => undefined,
}: PaginatedApiParams<ResponseType>): PaginatedApiState<
    ResponseType,
    RequestType
> {
    type RequestResult = ApiRequestResult<PaginationResponse<ResponseType>>;

    type DataProperty = keyof ResponseType;

    const defaults = {
        ...ownDefaults,
        ...theirDefaults,
    };

    const [isInitialMount, setInitialMount] = useState(true);

    const [isStale, setIsStale] = useState(runInitialFetch);

    const [data, setData] = useState<ResponseType[]>([]);

    const [error, setError] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const [limit, setLimit] = useState(defaults.limit);

    const [orderingBy, setOrderingBy] = useState<DataProperty>(
        defaults.orderingBy
    );

    const [isOrderingByDesc, setIsOrderingByDesc] = useState(
        defaults.isOrderingByDesc
    );

    const [query, setQuery] = useState(defaults.query);

    const [filter, setFilter] = useState(defaults.filter);

    const { api } = useContext(AuthContext);

    // When list fetching parameters change, we mark the data as stale
    useEffect(() => {
        // Do not do this on initial mount - staleness is defined by `runInitialFetch` param
        if (isInitialMount) {
            setInitialMount(false);
            return;
        }

        // No need to set it again if it's already set
        if (isStale) {
            return;
        }

        setIsStale(true);

        // We don't want to re-run this when isInitialMount changes, obviously.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderingBy, isOrderingByDesc, query, limit, filter]);

    useEffect(() => {
        if (!isStale) {
            return;
        }

        let isCancelled = false;

        const loadData = async () => {
            setIsLoading(true);

            const additionalHeaders = getAdditionalHeaders();

            const [error, data]: RequestResult = await api.get({
                baseUrl,
                url: `${url}${fetchUrl}?OrderBy=${orderingBy}&OrderByDesc=${isOrderingByDesc}&Query=${query}&Limit=${limit}${filter}`,
                headers: additionalHeaders,
            });

            if (isCancelled) {
                return;
            }

            setIsStale(false);
            setIsLoading(false);

            if (error) {
                setError(error);
                return;
            }

            setData(data.Results);
        };

        loadData();

        return () => {
            isCancelled = true;
        };

        // We don't want to re-run this automatically when params change, whether this
        // is re-run is solely defined by the `isStale` state, which is manually set
        // for changing query params above or when refetch is triggered below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [api, isStale, baseUrl, url]);

    /**
     * Calculate a new ordering configuration.
     * If the same order is selected again, switch order direction.
     * Otherwise set it, and set sorting direction to the default
     * @param sortKey
     */
    const orderBy = (sortKey: DataProperty) => {
        if (orderingBy === sortKey) {
            setIsOrderingByDesc(!isOrderingByDesc);
        } else {
            setOrderingBy(sortKey);
            setIsOrderingByDesc(defaults.isOrderingByDesc);
        }
    };

    const limitProps = {
        limit,
        limitTo: setLimit,
    };

    const orderByProps = {
        orderingBy,
        isOrderingByDesc,
        orderBy,
    };

    const queryProps = {
        query,
        queryBy: setQuery,
    };

    const filterProps = {
        filter,
        filterBy: setFilter,
    };

    const refetch = () => setIsStale(true);

    const create = async (body: RequestType) => {
        const requestResult = await api.post({
            baseUrl,
            url,
            body,
        });

        return requestResult;
    };

    const update = async (id: string | number, body: RequestType) => {
        const requestResult = await api.put({
            baseUrl,
            url: `${url}/${id}`,
            body,
        });

        return requestResult;
    };

    const patch = async (id: string | number, body: RequestType) => {
        const requestResult = await api.patch({
            baseUrl,
            url: `${url}/${id}`,
            body,
        });

        return requestResult;
    };

    // This cannot be named delete because JS doesn't allow that as an identifier
    const safeDelete = async (id: string | number) => {
        const requestResult = await api.delete({
            baseUrl,
            url: `${url}/${id}`,
        });

        return requestResult;
    };

    const fetchOne = async (id: string | number) => {
        const requestResult = await api.get({
            baseUrl,
            url: `${url}/${id}`,
        });

        return requestResult;
    };

    return {
        data,
        error,
        refetch,
        create,
        update,
        patch,
        delete: safeDelete,
        fetchOne,
        isLoading,
        limitProps,
        orderByProps,
        queryProps,
        filterProps,
    };
}
