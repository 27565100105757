import '@oetkerdigital/eden-design-system-core/dist/css/main.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, RouteComponentProps } from 'react-router-dom';
import { App, AppContainer } from './application/App';
import { ErrorBoundary } from './components/ErrorBoundary';
import config from './config';
import { initErrorTracking } from './util/initErrorTracking';

const errorHandler = initErrorTracking({
    apiKey: config.STACKDRIVER_API_KEY,
    projectId: config.STACKDRIVER_PROJECT_ID,
    enabled: config.STACKDRIVER_REPORTING_ENABLED,
    releaseId: config.RELEASE_ID,
});

ReactDOM.render(
    <ErrorBoundary errorHandler={errorHandler}>
        <AppContainer>
            <Route
                path="*"
                render={(props: RouteComponentProps) => <App {...props} />}
            />
        </AppContainer>
    </ErrorBoundary>,
    document.getElementById('root')
);
