import React, { FunctionComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { useUser } from '../hooks/useUser';
import { createUserPermissionValidator } from '../util/createUserPermissionValidator';
/**
 * Defines specific type to match route with permission, like:
 *
 * @example
 * {
 *      roles: 'user-manager:roles:details'
 * },
 * where [key] is actual route and [value] is a permission which allows user to see this route
 */
type RoutePermissions = {
    [key: string]: string;
};

/**
 *
 * @param prefix: String param which reflects section prefix from routes config, like '/system-configurator'
 * @param routePermissions: RoutePermissions object which connects route with permission
 */
interface DispatcherProps {
    prefix: string;
    routePermissions: RoutePermissions;
}

/**
 * Util component which helps to define on which route user should be redirected in cases when there are no concrete route for default route.abs
 *
 * @example System configurator has no default route and depends on permissions to redirect to either /roles OR /companies and it will be called like:
 *
 * component: () => <RouteDispatcher prefix={prefix} routePermissions={routePermissions} />, where
 *
 * @var prefix = '/system-configurator' and
 * @var routePermissions are:
 * {
 *      roles: 'user-manager:roles:details',
 *      companies: 'user-manager:companies:details'
 * },
 */
export const RouteDispatcher: FunctionComponent<DispatcherProps> = ({
    prefix,
    routePermissions,
}) => {
    const user = useUser();

    /**
     * Get all routes from routesPermissions
     */
    const routes = Object.keys(routePermissions);

    /**
     * Get first accessible route which user has access to
     *
     * @todo: Define a way to choose default route if user has access to many/all routes in the subset
     */
    const accessibleRoute = routes.find(route => {
        const permission = routePermissions[route];

        return !!user && createUserPermissionValidator(permission)(user);
    });

    return (
        <React.Fragment>
            {user && (
                <React.Fragment>
                    {accessibleRoute && (
                        <Redirect to={`${prefix}/${accessibleRoute}`} />
                    )}

                    {!accessibleRoute && (
                        <Redirect to={`${prefix}/not-found`} />
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};
