import React, { FunctionComponent, useContext } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import {
    Heading,
    Switch,
    Button,
} from '@oetkerdigital/eden-design-system-react';
import { CompanyResponse } from '../../../api-types/user-manager';
import { provide } from '../../../util/provide';
import { useHasPermission } from '../../../hooks/useHasPermission';
import { SortableTableHeadCell } from '../../../components/SortableTableHeadCell';
import { useAlerts } from '../../../components/AlertProvider';
import { CompaniesContext, withCompanies } from './CompaniesProvider';

/**
 * TODO: Decide where to move this util AND make it a global status checker for any object with status?
 *
 * Helper util which returns oposite of current company status to difene correct action label
 * @param company: Company which status should be changed
 * @default: 'Activate', because usually newly created company is Inactive and label for it is opposite of current status
 */
const getStatusLabel = (company?: CompanyResponse) => {
    if (company && company.CompanyStatus) {
        return company.CompanyStatus === 'Active' ? 'Deactivate' : 'Activate';
    }

    return 'Activate';
};

const __CompaniesList: FunctionComponent<RouteComponentProps> = () => {
    const {
        data: companies,
        isLoading,
        limitProps,
        orderByProps,
        refetch,
        patch: patchCompany,
        delete: deleteCompany,
    } = useContext(CompaniesContext);

    const alerts = useAlerts();

    const onDelete = (id: number) => {
        const company = companies.find(c => c.Id === id)!;

        alerts.alert({
            message: `Really delete company "${company.Name}"?`,
            content: (
                <React.Fragment>
                    {company.UserCount > 0 && (
                        <p>
                            This company is currently assigned to{' '}
                            {company.UserCount} users.
                        </p>
                    )}

                    <p>This action cannot be undone.</p>

                    <Button
                        onClick={() => onDeleteConfirm(id)}
                        title={`Confirm that company ${company.Name} will be deleted`}
                        className="mr--1"
                    >
                        Yes, delete
                    </Button>

                    <Button
                        variant="secondary"
                        onClick={() => alerts.remove()}
                        title={`Abort deleting company ${company.Name}`}
                    >
                        Cancel
                    </Button>
                </React.Fragment>
            ),
        });
    };

    const onDeleteConfirm = async (id: number) => {
        const company = companies.find(c => c.Id === id)!;

        const [error] = await deleteCompany(id);

        if (error) {
            alerts.error(error);
            return;
        }

        alerts.success(`Company ${company.Name} was deleted`);
        refetch();
    };

    const onStatusChange = (id: number) => {
        const company = companies.find(c => c.Id === id)!;
        const newStatus = getStatusLabel(company).toLowerCase();

        alerts.alert({
            message: `Really ${newStatus} company "${company.Name}"?`,
            content: (
                <React.Fragment>
                    <Button
                        onClick={() => onStatusChangeConfirm(id)}
                        title={`Confirm that company ${company.Name} status will be changed`}
                    >
                        Yes, {newStatus}
                    </Button>

                    <Button
                        variant="secondary"
                        onClick={() => alerts.remove()}
                        title={`Abort changing status for company ${company.Name}`}
                    >
                        Cancel
                    </Button>
                </React.Fragment>
            ),
        });
    };

    const onStatusChangeConfirm = async (id: number) => {
        const company = companies.find(c => c.Id === id)!;
        const newStatus = getStatusLabel(company).toLowerCase();

        const [error] = await patchCompany(id, {
            // TODO: Error in API types from swagger. CompanyStatus is not part of request body
            CompanyStatus:
                company.CompanyStatus === 'Active' ? 'Inactive' : 'Active',
        } as any);

        if (error) {
            alerts.error(error);
            return;
        }

        alerts.success(`Company ${company.Name} was ${newStatus}d`);
        refetch();
    };

    const isModifier = useHasPermission('user-manager:companies:modify');
    const seeDetails = useHasPermission('user-manager:companies:details');
    const isActivator = useHasPermission('user-manager:companies:activate');

    return (
        <React.Fragment>
            <Heading>Companies</Heading>
            <p>
                View and manage the list of available companies. Companies are
                purely administrative and don't have any effects on user
                permissions.
            </p>

            {isModifier && (
                <Link
                    className="Button"
                    to="/system-configurator/companies/new"
                >
                    Create new company
                </Link>
            )}

            <table className="Table mt--1">
                <thead>
                    <tr>
                        <SortableTableHeadCell sortKey="Name" {...orderByProps}>
                            Name
                        </SortableTableHeadCell>

                        <SortableTableHeadCell
                            sortKey="UserCount"
                            {...orderByProps}
                        >
                            User Count
                        </SortableTableHeadCell>

                        {isModifier && <th>Operations</th>}
                    </tr>
                </thead>
                <tbody>
                    {companies.length > 0 &&
                        companies.map(company => (
                            <tr key={`company-${company.Id}`}>
                                <td>
                                    {!seeDetails && company.Name}
                                    {seeDetails && (
                                        <Link
                                            className="Link"
                                            title={`View details for ${company.Name}`}
                                            to={`/system-configurator/companies/${company.Id}`}
                                        >
                                            {company.Name}
                                        </Link>
                                    )}
                                </td>
                                <td>
                                    <Link
                                        className="Link"
                                        title={`Show users of ${company.Name}`}
                                        to={`/user-manager/users?companyIds=${company.Id}`}
                                    >
                                        {company.UserCount}
                                    </Link>
                                </td>
                                {isModifier && (
                                    <td>
                                        {isActivator && (
                                            <Button
                                                className="mr--1"
                                                onClick={() =>
                                                    onStatusChange(company.Id)
                                                }
                                                title={`Change status of ${company.Name}`}
                                                variant="secondary"
                                            >
                                                {getStatusLabel(company)}
                                            </Button>
                                        )}
                                        <Link
                                            className="Button mr--1"
                                            to={`/system-configurator/companies/${company.Id}/edit`}
                                            title={`Edit ${company.Name}`}
                                        >
                                            Edit
                                        </Link>
                                        <Button
                                            className="mr--1"
                                            onClick={() => onDelete(company.Id)}
                                            title={`Delete ${company.Name}`}
                                            variant="secondary"
                                        >
                                            Delete
                                        </Button>
                                    </td>
                                )}
                            </tr>
                        ))}

                    {!isLoading && !companies.length && (
                        <tr>
                            <td colSpan={3}>No results</td>
                        </tr>
                    )}

                    {isLoading && !companies.length && (
                        <tr>
                            <td colSpan={3}>Loading...</td>
                        </tr>
                    )}
                </tbody>
            </table>

            <Switch<number>
                className="mt--1"
                items={[
                    { value: 10, label: '10' },
                    { value: 50, label: '50' },
                    { value: 100, label: '100' },
                    { value: 500, label: '500' },
                ]}
                value={limitProps.limit}
                onValueChange={limitProps.limitTo}
                aria-label="Limit results to"
            />
        </React.Fragment>
    );
};

export const CompaniesList = provide(__CompaniesList, withCompanies());
