import React, { FunctionComponent, createContext, useContext } from 'react';
import useSettingsApi, { SettingsApiState } from '../useSettingsApi';
import { createProviderHoC } from '../../../util/createProviderHoC';
import { useErrorAlert } from '../../../components/AlertProvider';
import { SuggestionSettingModel } from '../../../api-types/search';

type DataType = SuggestionSettingModel[];
type DisplayType = {
    tag: string;
    contexts: SuggestionSettingModel['Contexts'];
}[];

type SuggestionSettingsProviderState = SettingsApiState<DisplayType>;

const SuggestionSettingsContext = createContext<
    SuggestionSettingsProviderState | undefined
>(undefined);

/**
 * Transform from
 * `{ tag: 'meta_tags.ingredients', context: {} }`
 * to
 * `{ TagType: 'meta_tags', TagName: 'ingredients', Context: {} }`
 *
 * @param values
 */
const toData = (values: DisplayType) =>
    values.map(value => {
        const valueSplit = value.tag.split('.');

        return {
            TagType: valueSplit[0] as SuggestionSettingModel['TagType'],
            TagName: valueSplit[1],
            Contexts: value.contexts,
        };
    });

/**
 * Transform from
 * `{ TagType: 'meta_tags', TagName: 'Ingredients', Context: {} }`
 * to
 * `{tag: meta_tags.ingredients, context: {} }`
 *
 * @param values
 */
const toDisplay = (values: DataType) =>
    values.map(value => ({
        tag: `${value.TagType}.${value.TagName.toLowerCase()}`,
        contexts: value.Contexts,
    }));

const SuggestionSettingsProvider: FunctionComponent = ({ children }) => {
    const settingsApi = useSettingsApi<DataType, DisplayType>({
        url: '/suggestions',
        toData,
        toDisplay,
        defaultData: [],
    });

    useErrorAlert(settingsApi.fetchError);
    useErrorAlert(settingsApi.saveError);

    return (
        <SuggestionSettingsContext.Provider value={settingsApi}>
            {children}
        </SuggestionSettingsContext.Provider>
    );
};

export const withSuggestionSettings = createProviderHoC(
    SuggestionSettingsProvider
);

export const useSuggestionSettings = () => {
    const context = useContext(SuggestionSettingsContext);

    /* istanbul ignore if */
    if (!context) {
        throw new Error(
            '`useSuggestionSettings` must be used within a `SuggestionSettingsProvider`!'
        );
    }

    return context;
};
