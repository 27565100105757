import baseRoutes from './application/base/routes';
import searchRoutes from './application/search-optimization/routes';
import serviceConfiguratorRoutes from './application/service-configurator/routes';
import shrtyRoutes from './application/shrty/routes';
import systemConfiguratorRoutes from './application/system-configurator/routes';
import userManagerRoutes from './application/user-manager/routes';
import { RoutingRoute } from './components/Routing';

const routes: RoutingRoute[] = [
    ...baseRoutes,
    ...searchRoutes,
    ...shrtyRoutes,
    ...userManagerRoutes,
    ...serviceConfiguratorRoutes,
    ...systemConfiguratorRoutes,
];

export default routes;
