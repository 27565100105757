import React, {
    FormEvent,
    FunctionComponent,
    useEffect,
    useState,
} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
    Button,
    Textfield,
    Heading,
    Image,
    CenteredLayout,
} from '@oetkerdigital/eden-design-system-react';
import Logo from '@oetkerdigital/eden-design-system-core/dist/img/logo.svg';
import config from '../../../config';
import { createRequest } from '../../../util/createRequest';
import { useAlerts } from '../../../components/AlertProvider';

const request = createRequest({
    defaultBaseUrl: config.USER_MANAGER_API_BASE_URL,
});

export const ForgotPassword: FunctionComponent<RouteComponentProps> = () => {
    const alerts = useAlerts();
    const [emailValue, setEmailValue] = useState('');
    const [emailError, setEmailError] = useState('');

    useEffect(() => {
        document.title = 'EDEN - Password Recovery';
    }, []);

    const isValid = !!emailValue;

    /**
     * On submit, first check if everything is valid, and then send request.
     * @param e
     */
    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (!isValid) {
            return;
        }

        const [error] = await request({
            url: '/accounts/reset',
            method: 'POST',
            body: {
                AccountId: emailValue,
            },
        });

        if (error) {
            setEmailError(error);
            return;
        }

        setEmailError('');
        setEmailValue('');

        alerts.success(
            'An email with instructions to reset your password has been sent to you.'
        );
    };

    return (
        <CenteredLayout>
            <Image width="50" height="55" src={Logo} alt="logo" />

            <Heading>Forgot password</Heading>

            <form className="ContentLayout" onSubmit={onSubmit}>
                <p>Please enter your email address to reset your password.</p>

                <Textfield
                    label="Email address"
                    value={emailValue}
                    onValueChange={setEmailValue}
                    variant={emailError.length > 0 ? 'error' : undefined}
                    hint={emailError}
                />

                <Button disabled={!isValid}>Reset password</Button>
            </form>
        </CenteredLayout>
    );
};
