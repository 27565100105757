/**
 * Utility to streamline wrapping providers around a component.
 *
 * Since some components need many providers, it can get a bit complicated to
 * nest them all into each other. By using this utility, you can wrap many
 * providers around a component in a very linerar way, like this:
 *
 * ```
 * export default provide(
 *     UserRights,
 *     withRoles({ limit: 500, includeAdmin: true }),
 *     withCountries(),
 *     withBrands()
 * );
 * ```
 *
 * @param Component The component to be wrapped with the following functions
 * @param functions Any number of functions to be called on the component
 */
export function provide<ComponentType>(
    // The first parameter is the component itself, its type is inferred here.
    Component: ComponentType,

    // The following parameters are all functions (dumped into an array here)
    // that take a component of the same type as parameter, and return a
    // component of the same type again. This keeps the return type of the
    // original component intact, even when wrapped with multiple providers.
    ...functions: Array<(Component: ComponentType) => ComponentType>
) {
    // Construct a WrappedComponent by calling each function on it
    // This is also fail safe in case no functions are given - it will just
    // return the component itself
    return functions.reduce(
        // Call the current function on the result of the previous iteration
        (WrappedComponent, func) => func(WrappedComponent),
        // Start with the component itself
        Component
    );
}
