import { produce } from 'immer';
import React, { createContext, FunctionComponent, ReactNode } from 'react';
import {
    CompanyRequest,
    CompanyResponse,
} from '../../../api-types/user-manager';
import config from '../../../config';
import {
    PaginatedApiState,
    paginatedApiStateDefaults,
    usePaginatedApi,
} from '../../../hooks/usePaginatedApi';
import { createProviderHoC } from '../../../util/createProviderHoC';

interface CompaniesProviderProps {
    children?: ReactNode;
    runInitialFetch?: boolean;
    limit?: number;
}

type CompaniesContextState = PaginatedApiState<CompanyResponse, CompanyRequest>;

const defaults = {
    orderingBy: 'Name' as keyof CompanyResponse,
};

const CompaniesContext = createContext<CompaniesContextState>(
    produce(paginatedApiStateDefaults, draft => {
        draft.orderByProps.orderingBy = defaults.orderingBy;
    })
);

const CompaniesProvider: FunctionComponent<CompaniesProviderProps> = ({
    children,
    runInitialFetch,
    limit = 10,
}) => {
    const paginatedApiState = usePaginatedApi<CompanyResponse, CompanyRequest>({
        baseUrl: config.USER_MANAGER_API_BASE_URL,
        url: '/companies',
        runInitialFetch,
        defaults: { ...defaults, limit },
    });

    return (
        <CompaniesContext.Provider value={paginatedApiState}>
            {children}
        </CompaniesContext.Provider>
    );
};

const withCompanies = createProviderHoC(CompaniesProvider);

export { CompaniesContext, withCompanies };
export default CompaniesProvider;
