// This file is ignored in coverage reporting, we don't need to test lazy loading or the disabled feature case
import React from 'react';
import { RoutingRoute } from '../../components/Routing';
import { createUserPermissionValidator } from '../../util/createUserPermissionValidator';
import { RouteDispatcher } from '../../components/RouteDispatcher';
import { CompaniesDetail } from './companies/CompaniesDetail';
import { CompaniesForm } from './companies/CompaniesForm';
import { CompaniesList } from './companies/CompaniesList';
import { RoleDetail } from './roles/RoleDetail';
import { RolesForm } from './roles/RolesForm';
import { RolesList } from './roles/RolesList';

const prefix = '/system-configurator';

const routePermissions = {
    roles: 'user-manager:roles:list',
    companies: 'user-manager:companies:list',
};

const subItems = [
    {
        path: `${prefix}/companies`,
        label: 'Companies',
        validateUser: createUserPermissionValidator(
            `user-manager:companies:list`
        ),
        component: CompaniesList,
    },

    {
        path: `${prefix}/companies/new`,
        label: 'Create company',
        component: CompaniesForm,
        validateUser: createUserPermissionValidator(
            `user-manager:companies:modify`
        ),
        hideInMenu: true,
    },

    {
        path: `${prefix}/companies/:companyId`,
        label: 'Company details',
        component: CompaniesDetail,
        validateUser: createUserPermissionValidator(
            `user-manager:companies:details`
        ),
        hideInMenu: true,
    },

    {
        path: `${prefix}/companies/:companyId/edit`,
        label: 'Edit company',
        component: CompaniesForm,
        validateUser: createUserPermissionValidator(
            `user-manager:companies:modify`
        ),
        hideInMenu: true,
    },

    {
        path: `${prefix}/roles`,
        label: 'Role Management',
        validateUser: createUserPermissionValidator(`user-manager:roles:list`),
        component: RolesList,
    },

    {
        path: `${prefix}/roles/new`,
        label: 'Create role',
        component: RolesForm,
        validateUser: createUserPermissionValidator(
            `user-manager:roles:modify`
        ),
        hideInMenu: true,
    },

    {
        path: `${prefix}/roles/:roleId`,
        label: 'Role details',
        component: RoleDetail,
        validateUser: createUserPermissionValidator(
            `user-manager:roles:details`
        ),
        hideInMenu: true,
    },

    {
        path: `${prefix}/roles/:roleId/edit`,
        label: 'Edit role',
        component: RolesForm,
        validateUser: createUserPermissionValidator(
            `user-manager:roles:modify`
        ),
        hideInMenu: true,
    },
];

const routes: RoutingRoute[] = [
    {
        path: prefix,
        label: 'System Configurator',
        validateUser: createUserPermissionValidator([
            routePermissions.companies,
            routePermissions.roles,
        ]),
        component: () => (
            <RouteDispatcher
                prefix={prefix}
                routePermissions={routePermissions}
            />
        ),
        subItems,
    },
];

export default routes;
