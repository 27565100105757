import React, { FunctionComponent } from 'react';

/**
 * Create a HoC for one of our data providers.
 *
 * Note: We use HoC as the term here even though the result is technically more
 * of a function that creates a function that can be wrapped around a component.
 * The principle is the same though, so the name somewhat applies.
 *
 * Calling this with a Provider will return a function that will wrap the provider
 * around a given component if applied:
 *
 * ```
 * const withRoles = createProviderHoC(RolesProvider);
 * ```
 *
 * The created function can then either be instantiated without parameters or
 * with any props the provider allows:
 *
 * ```
 * withRoles()
 * # OR
 * withRoles({ runInitialFetch: false })
 * ```
 *
 * The resulting function after this call is then the actual HoC and can either
 * be directly applied to a component or applied through use of the `provide`
 * function:
 * ```
 * export default withRoles()(RolesList);
 * # OR
 * export default provide(
 *     RolesForm,
 *     withRoles({ runInitialFetch: false }),
 *     withPermissions()
 * );
 * ```
 */
export function createProviderHoC<ProviderComponentProps>(
    ProviderComponent: FunctionComponent<ProviderComponentProps>
) {
    // First step - return a function that takes the props of the
    // provider component as arguments (optional - default to empty object)
    // @ts-ignore TODO: fix this later, no idea what's wrong right now
    return (providerProps: ProviderComponentProps = {}) => {
        // Second step - return a function that takes the component as parameter
        return function withProvider<ComponentProps>(
            Component: FunctionComponent<ComponentProps>
        ) {
            // Third step - return a new component that takes the same props as
            // the original component, and then renders provider and component
            return (componentProps: ComponentProps) => (
                <ProviderComponent {...providerProps}>
                    <Component {...componentProps} />
                </ProviderComponent>
            );
        };
    };
}
