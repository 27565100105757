// This file is ignored in coverage reporting, we don't need to test lazy loading or the disabled feature case
import React from 'react';
import { Redirect } from 'react-router-dom';
import { RoutingRoute } from '../../components/Routing';
import { createUserPermissionValidator } from '../../util/createUserPermissionValidator';
import { PageIndexing } from './page-indexing/PageIndexing';
import { RankResults } from './rank-results/RankResults';
import { Reports } from './reports/Reports';
import { NegationWords, StopWords, Synonyms } from './words/Words';
import { Admin } from './admin/Admin';

const prefix = '/search-optimization';

const routes: RoutingRoute[] = [
    {
        path: prefix,
        label: 'Onsite Search',
        component: () => <Redirect to={`${prefix}/reports`} />,
        validateUser: createUserPermissionValidator('oss:read'),
        subItems: [
            {
                path: `${prefix}/reports`,
                label: 'Reports',
                component: Reports,
            },
            {
                path: `${prefix}/synonyms`,
                label: 'Synonyms',
                component: Synonyms,
            },
            {
                path: `${prefix}/stop-words`,
                label: 'Stop Words',
                component: StopWords,
            },
            {
                path: `${prefix}/negation-words`,
                label: 'Negation Words',
                component: NegationWords,
            },
            {
                path: `${prefix}/rank-results`,
                label: 'Rank Results',
                component: RankResults,
            },
            {
                path: `${prefix}/page-indexing`,
                label: 'Page Indexing',
                component: PageIndexing,
            },
            {
                path: `${prefix}/admin`,
                label: 'Admin',
                component: Admin,
                validateUser: createUserPermissionValidator('oss:admin'),
            },
        ],
    },
];

export default routes;
