import React, {
    FormEvent,
    FunctionComponent,
    useContext,
    useEffect,
    useState,
} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
    Heading,
    Textfield,
    Dropdown,
    Button,
    Icon,
    Card,
    Chip,
    DropdownItem,
    TwoColumnLayout,
    ContentLayout,
} from '@oetkerdigital/eden-design-system-react';
import {
    RoleResponse,
    UserResponse,
    UserRoleResponse,
} from '../../../api-types/user-manager';
import { AuthContext } from '../../../components/AuthGate';
import config from '../../../config';
import { useFormField } from '../../../hooks/useFormField';
import { ApiRequestResult } from '../../../util/createRequest';
import { provide } from '../../../util/provide';
import { validators } from '../../../util/validators';
import {
    BrandsContext,
    withBrands,
} from '../../service-configurator/brands/BrandsProvider';
import {
    CountriesContext,
    withCountries,
} from '../../service-configurator/countries/CountriesProvider';
import {
    CompaniesContext,
    withCompanies,
} from '../../system-configurator/companies/CompaniesProvider';
import {
    RolesContext,
    withRoles,
} from '../../system-configurator/roles/RolesProvider';
import { useHasPermission } from '../../../hooks/useHasPermission';
import { useAlerts } from '../../../components/AlertProvider';
import { UsersContext, withUsers } from './UsersProvider';

interface RouteParams {
    userId?: string;
}

type RoleConfig = {
    roleId: number | null;
    brandCodes: string[];
    countryCodes: string[];
};

type RoleError = {
    index: {
        roleId?: string;
        brandCodes?: string;
        countryCodes?: string;
    };
};

// TODO: API types need to be updated
interface PatchedUserRoleResponse extends UserRoleResponse {
    Role: RoleResponse;
}

interface MultiSelectProps {
    label: string;
    options: DropdownItem<string>[];
    value: DropdownItem<string>['value'][];
    onAdd: (value: string) => void;
    onRemove: (value: string) => void;
}

const MultiSelect: FunctionComponent<MultiSelectProps> = ({
    label,
    options,
    value,
    onAdd,
    onRemove,
}) => (
    <React.Fragment>
        {value.map(code => {
            const brandOption = options.find(o => o.value === code);

            if (!brandOption) {
                return null;
            }

            return (
                <Chip
                    key={code}
                    label={brandOption.label}
                    onRemove={() => onRemove(code)}
                />
            );
        })}

        <Dropdown
            label={label}
            options={options.filter(o => !value.includes(o.value))}
            value={null}
            onValueChange={onAdd}
        />
    </React.Fragment>
);

const __UserForm: FunctionComponent<RouteComponentProps<RouteParams>> = ({
    history,
    match,
}) => {
    const userId = match.params.userId;
    const isEdit = !!userId;

    // TODO: Keeping these here for now as they also handle validation, so it's
    // not as easy to just switch them over to `useState` as we did for the
    // other forms. We should refactor this at some point to have a proper hook
    // that works with the new `Textfield` component (incl. validation).
    const firstNameField = useFormField({
        initialValue: '',
        validators: [validators.required('First name field is required')],
        isRequired: true,
    });
    const lastNameField = useFormField({
        initialValue: '',
        validators: [validators.required('Last name field is required')],
        isRequired: true,
    });
    const emailAddressField = useFormField({
        initialValue: '',
        validators: [
            validators.required('Email address field is required'),
            validators.email('Please enter a valid email address'),
        ],
        isRequired: true,
    });

    const [user, setUser] = useState<UserResponse | null>(null);

    const [companyId, setCompanyId] = useState<number | null>(null);
    const [companyError, setCompanyError] = useState('');

    const [roles, setRoles] = useState<RoleConfig[]>([]);
    const [rolesErrors, setRolesErrors] = useState<RoleError | null>(null);

    const { api } = useContext(AuthContext);

    const alerts = useAlerts();

    const {
        fetchOne: fetchUser,
        update: updateUser,
        create: createUser,
    } = useContext(UsersContext);

    const { data: allRoles, error: rolesError } = useContext(RolesContext);
    const { data: brands, error: brandsError } = useContext(BrandsContext);
    const { data: countries, error: countriesError } =
        useContext(CountriesContext);

    const { data: companies, error: companiesError } =
        useContext(CompaniesContext);

    const isValid =
        firstNameField.isValid &&
        lastNameField.isValid &&
        emailAddressField.isValid &&
        !!companyId;

    const fetchError =
        rolesError || brandsError || countriesError || companiesError;

    const hasData = !!allRoles.length && !!countries.length && !!brands.length;

    const roleOptions = allRoles.map(role => ({
        value: role.Id,
        label: role.Name,
    }));

    const brandOptions = brands.map(brand => ({
        value: brand.Code,
        label: brand.Name,
    }));

    const countryOptions = countries.map(country => ({
        value: country.Code,
        label: country.Name,
    }));

    const companyOptions = companies.map(company => ({
        value: company.Id,
        label: company.Name,
    }));

    useEffect(() => {
        if (fetchError) {
            alerts.error(fetchError);
        }
    }, [fetchError, alerts]);

    /**
     * Fetch user by id if user is on edit page
     */
    useEffect(() => {
        const fetchUserToEdit = async () => {
            if (!userId) {
                return;
            }

            const [error, user] = await fetchUser(userId);

            if (error || !user) {
                alerts.error(`User was not fetched. Error: ${error}`);
                return;
            }

            setUser(user);

            return;
        };

        fetchUserToEdit();
        // We don't want to make fetchUser as a dependency
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    /**
     * Whenever user changes new data should be applied to the form fields and new user should be set
     */
    useEffect(() => {
        if (!user) {
            return;
        }

        firstNameField.setValue(user.FirstName);
        lastNameField.setValue(user.LastName);
        emailAddressField.setValue(user.Email);
        if (user.Companies && user.Companies[0]) {
            setCompanyId(user.Companies[0].Id);
        }

        if (user.UserRoles) {
            const currentRolesMapped = (
                user.UserRoles as PatchedUserRoleResponse[]
            ).map(userRole => {
                const roleId = userRole.Role.Id;
                const brandCodes = userRole.RoleProperties.brand || [];
                const countryCodes = userRole.RoleProperties.country || [];

                return { roleId, brandCodes, countryCodes };
            });

            setRoles(currentRolesMapped);
        }
        // We don't want to make all fields as dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const ROLE_ERRORS = {
        roleId: 'Please choose Name',
        brandCodes: 'Please choose at least one Brand',
        countryCodes: 'Please choose at least one Country',
    };

    const ROLE_FIELDS = ['roleId', 'brandCodes', 'countryCodes'];

    /**
     *
     * @param roles: Roles Array
     * @description: Validate roles before send to apply business rules that every role should be defined, has brand and country
     */
    const validateRoles = (roles: RoleConfig[]): boolean => {
        setRolesErrors(null);

        const errors = roles.reduce((acc, role, index) => {
            // Check if any field is undefined or empty
            const wrongFields = Object.values(role as RoleConfig).some(
                field => {
                    if (Array.isArray(field)) {
                        return field.length === 0;
                    } else {
                        return !field;
                    }
                }
            );
            // If there are empty fields
            if (wrongFields) {
                const erroredRoleFields = {};

                // Create errors object with errors explanations
                ROLE_FIELDS.forEach(field => {
                    if (
                        (Array.isArray(role[field]) &&
                            role[field].length === 0) ||
                        !role[field]
                    ) {
                        erroredRoleFields[field] = ROLE_ERRORS[field];
                    }
                });

                // Add new error object to error fields object
                acc[index] = erroredRoleFields;
            }

            return acc;
        }, {});

        setRolesErrors(errors as RoleError);

        return Object.keys(errors).length === 0;
    };

    const saveUser = async () => {
        /**
         * @description: Transform roles to correct body shape for PUT request
         */
        const transformedRoles = roles.map(role => ({
            RoleId: role.roleId as number,
            Properties: {
                brand: [...role.brandCodes],
                country: [...role.countryCodes],
            },
        }));

        const requestBody = {
            FirstName: firstNameField.value,
            LastName: lastNameField.value,
            Email: emailAddressField.value,
            CompanyIds: [companyId!],
            Roles: transformedRoles,
        };

        // Update User if it is Edit User page
        if (userId) {
            const [error]: ApiRequestResult = await updateUser(
                userId,
                requestBody
            );

            if (error) {
                alerts.error(error);
                return;
            }

            alerts.success('The user has been saved successfully.');
            history.push('/user-manager/users');
            return;
        }

        // Create User if it is a Create User page
        const [error, createdUser]: ApiRequestResult = await createUser(
            requestBody
        );

        if (error) {
            alerts.error(error);
            return;
        }

        // Invitation email should sent manually for now
        // TODO: Migrate invitation email procedure to BE and remove from here
        if (!isEdit) {
            if (createdUser && createdUser.Id) {
                const [invitationError]: ApiRequestResult = await api.post({
                    baseUrl: config.USER_MANAGER_API_BASE_URL,
                    url: `/users/${createdUser.Id}/invite`,
                });

                if (invitationError) {
                    alerts.error(invitationError);
                    return;
                }
            }
        }

        alerts.success('The user has been saved successfully.');
        history.push('/user-manager/users');
    };

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (!companyId) {
            setCompanyError('Please select a company');
        }

        if (!validateRoles(roles)) {
            return;
        }

        if (!isValid) return;

        saveUser();
    };

    // Clean errors per field
    const cleanErrors = (index: number, field: string) => {
        if (rolesErrors && rolesErrors[index]) {
            const rolesErrorsCopy = { ...rolesErrors };
            delete rolesErrorsCopy[index][field];

            // If there are no errors left, remove error object
            if (Object.values(rolesErrorsCopy[index]).length === 0) {
                delete rolesErrorsCopy[index];
            }

            setRolesErrors({ ...rolesErrorsCopy });
        }
    };

    const getError = (index: number, field: string) => {
        return (
            !!rolesErrors && !!rolesErrors[index] && rolesErrors[index][field]
        );
    };

    const onAddRole = () => {
        setRoles([
            ...roles,
            { roleId: null, brandCodes: [], countryCodes: [] },
        ]);
    };

    const onRemoveRole = (index: number) => {
        roles.splice(index, 1);
        setRoles([...roles]);
    };

    const onRoleNameChange = (index: number) => (newValue: number) => {
        const newRoles = [...roles];
        newRoles[index].roleId = newValue;
        setRoles([...newRoles]);
        cleanErrors(index, 'roleId');
    };

    const onRolePropertyRemove =
        (index: number, property: 'brandCodes' | 'countryCodes') =>
        (code: string) => {
            const newRoles = [...roles];
            const newValues = newRoles[index][property].filter(c => c !== code);
            newRoles[index][property] = newValues;
            setRoles([...newRoles]);
            cleanErrors(index, property);
        };

    const onRolePropertyAdd =
        (index: number, property: 'brandCodes' | 'countryCodes') =>
        (code: string) => {
            const newRoles = [...roles];
            const newValues = [...newRoles[index][property], code];
            newRoles[index][property] = newValues;
            setRoles([...newRoles]);
            cleanErrors(index, property);
        };

    const canEditEmail = useHasPermission('user-manager:users:modify-email');

    const cannotEditEmail = !!(userId && !canEditEmail);

    return (
        <form className="ContentLayout" onSubmit={onSubmit}>
            <Heading>
                {!isEdit
                    ? 'Create a new User'
                    : `Edit User: ${
                          user ? user.FirstName + ' ' + user.LastName : ''
                      }`}
            </Heading>
            <p>
                {!isEdit
                    ? 'Please fill out the complete form to create a new user. All fields are mandatory!'
                    : 'User data can be changed here.'}
            </p>

            <Textfield
                label="First Name"
                onValueChange={() => {}}
                value={firstNameField.props.value}
                onChange={firstNameField.props.onChange}
                onBlur={firstNameField.props.onBlur}
                hint={firstNameField.props.error}
                variant={!!firstNameField.props.error ? 'error' : undefined}
            />

            <Textfield
                label="Last Name"
                onValueChange={() => {}}
                value={lastNameField.props.value}
                onChange={lastNameField.props.onChange}
                onBlur={lastNameField.props.onBlur}
                hint={lastNameField.props.error}
                variant={!!lastNameField.props.error ? 'error' : undefined}
            />

            {/* TODO: something is wrong with the Textfield types here when using `disabled`, investigate */}
            {cannotEditEmail ? (
                <Textfield
                    label="Email Address"
                    value={emailAddressField.props.value}
                    disabled
                />
            ) : (
                <Textfield
                    label="Email Address"
                    onValueChange={() => {}}
                    value={emailAddressField.props.value}
                    onChange={emailAddressField.props.onChange}
                    onBlur={emailAddressField.props.onBlur}
                    hint={emailAddressField.props.error}
                    variant={
                        !!emailAddressField.props.error ? 'error' : undefined
                    }
                />
            )}

            {!!companies && (
                <React.Fragment>
                    <Heading className="mt--1" as="h2">
                        Company
                    </Heading>
                    <p>Please select a company which the user belongs to.</p>

                    {!!companyOptions.length && (
                        <Dropdown
                            label="Company"
                            options={companyOptions}
                            value={companyId}
                            onValueChange={setCompanyId}
                        />
                    )}

                    {companyError && <p role="alert">{companyError}</p>}
                </React.Fragment>
            )}

            {hasData && (
                <React.Fragment>
                    <Heading className="mt--1" as="h2">
                        User Roles
                    </Heading>

                    <TwoColumnLayout>
                        {roles.map((role, index) => (
                            <Card
                                aria-label="Role Row"
                                key={`role-${index}`}
                                style={{ position: 'relative' }}
                            >
                                <ContentLayout>
                                    <Dropdown
                                        label="Role"
                                        value={role.roleId}
                                        onValueChange={onRoleNameChange(index)}
                                        options={roleOptions}
                                    />

                                    {!!getError(index, 'roleId') && (
                                        <p role="alert">
                                            {getError(index, 'roleId')}
                                        </p>
                                    )}

                                    <p className="Card__label">Brands</p>
                                    <MultiSelect
                                        label="Add brand"
                                        options={brandOptions}
                                        value={role.brandCodes}
                                        onRemove={onRolePropertyRemove(
                                            index,
                                            'brandCodes'
                                        )}
                                        onAdd={onRolePropertyAdd(
                                            index,
                                            'brandCodes'
                                        )}
                                    />
                                    {!!getError(index, 'brandCodes') && (
                                        <p role="alert">
                                            {getError(index, 'brandCodes')}
                                        </p>
                                    )}

                                    <p className="Card__label">Countries</p>
                                    <MultiSelect
                                        label="Add country"
                                        options={countryOptions}
                                        value={role.countryCodes}
                                        onRemove={onRolePropertyRemove(
                                            index,
                                            'countryCodes'
                                        )}
                                        onAdd={onRolePropertyAdd(
                                            index,
                                            'countryCodes'
                                        )}
                                    />
                                    {!!getError(index, 'countryCodes') && (
                                        <p role="alert">
                                            {getError(index, 'countryCodes')}
                                        </p>
                                    )}

                                    <Icon
                                        role="button"
                                        style={{
                                            position: 'absolute',
                                            right: '15px',
                                            top: 0,
                                            cursor: 'pointer',
                                        }}
                                        name="close"
                                        onClick={() => onRemoveRole(index)}
                                        aria-label={'Click to Remove Row'}
                                    />
                                </ContentLayout>
                            </Card>
                        ))}
                        <Card label="Add New Role" isCTA>
                            <Icon
                                name="add"
                                button
                                aria-label="Add New Role"
                                onClick={onAddRole}
                            />
                        </Card>
                    </TwoColumnLayout>
                </React.Fragment>
            )}

            <Button>Save</Button>
        </form>
    );
};

// TODO: if we ever get more than 500, we have a problem...
export const UserForm = provide(
    __UserForm,
    withRoles({ limit: 500, includeAdmin: true }),
    withCountries(),
    withBrands(),
    withCompanies({ limit: 500 }),
    withUsers({ runInitialFetch: false })
);
