import { startOfDay } from 'date-fns';

/**
 * Return a date string that can be used in a date filter.
 *
 * Removes any time information by using the start of day, as time is irrelevant.
 * @param date
 */
export const getDateFilterString = (date: Date) => {
    return startOfDay(date).toISOString();
};

/**
 * Return a query string that can be used to filter a date range.
 * @param range
 */
export const getRangeFilterQueryString = (range: { to: Date; from: Date }) => {
    const GTE = getDateFilterString(range.from);
    const LTE = getDateFilterString(range.to);

    return `GTE=${GTE}&LTE=${LTE}`;
};
