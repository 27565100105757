import { UserManager } from 'oidc-client';
import React, { FunctionComponent, useEffect, useReducer } from 'react';
import { User } from '../auth';

interface OidcContextState {
    isLoadingUser: boolean;
    user?: User;
    controller: UserManager;
    markUserAsStale: () => void;
}

export const OidcContext = React.createContext<OidcContextState>({
    isLoadingUser: false,
    controller: {} as UserManager,
    markUserAsStale: () => null,
});

interface OidcProviderProps {
    children: any;
    userManager: UserManager;
}

export type OidcState = {
    isLoadingUser: boolean;
    user: User | null;
};

const USER_FOUND = 'oidc/USER_FOUND';
const USER_NOT_FOUND = 'oidc/USER_NOT_FOUND';
const USER_SIGNED_OUT = 'oidc/USER_SIGNED_OUT';
const SESSION_EXPIRED = 'oidc/SESSION_EXPIRED';
const SESSION_TERMINATED = 'oidc/SESSION_TERMINATED';
const SILENT_RENEW_ERROR = 'oidc/SILENT_RENEW_ERROR';

type OidcAction =
    | { type: typeof USER_FOUND; payload: User }
    | { type: typeof USER_NOT_FOUND }
    | { type: typeof SILENT_RENEW_ERROR }
    | { type: typeof SESSION_EXPIRED }
    | { type: typeof SESSION_TERMINATED }
    | { type: typeof USER_SIGNED_OUT };

/* istanbul ignore next: Login is not tested with Jest, see e2e tests */
const reducer = (state: OidcState, action: OidcAction): OidcState => {
    switch (action.type) {
        case USER_FOUND:
            return {
                ...state,
                isLoadingUser: false,
                user: action.payload,
            };
        case USER_NOT_FOUND:
        case SILENT_RENEW_ERROR:
        case SESSION_EXPIRED:
        case SESSION_TERMINATED:
        case USER_SIGNED_OUT:
            return {
                ...state,
                isLoadingUser: false,
                user: null,
            };
    }
};

const initialState: OidcState = {
    isLoadingUser: true,
    user: null,
};

/**
 * Set the inital state of the OidcProvider.
 * Intended to only be used for testing purposes.
 * @param state
 */
export const setInitialOidcState = (state: OidcState) => {
    initialState.isLoadingUser = state.isLoadingUser;
    initialState.user = state.user;
};

export const oidcState = initialState;

/* istanbul ignore next: Login is not tested with Jest, see e2e tests */
export const OidcProvider: FunctionComponent<OidcProviderProps> = ({
    children,
    userManager,
}) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    // Make sure to update this for external access
    oidcState.isLoadingUser = state.isLoadingUser;
    oidcState.user = state.user;

    const onUserFound = (user: User) =>
        dispatch({ type: USER_FOUND, payload: user });

    const onSilentRenewError = () => dispatch({ type: SILENT_RENEW_ERROR });
    const onSessionExpired = () => dispatch({ type: SESSION_EXPIRED });
    const onSessionTerminated = () => dispatch({ type: SESSION_TERMINATED });
    const onUserSignedOut = () => dispatch({ type: USER_SIGNED_OUT });

    useEffect(() => {
        let isCancelled = false;

        if (!state.user || state.user.expired) {
            userManager
                .getUser()
                .then((user: User | null) => {
                    if (isCancelled) {
                        return;
                    }

                    if (!user || user.expired) {
                        onSessionExpired();
                    } else {
                        onUserFound(user);
                    }
                })
                .catch(() => {
                    if (isCancelled) {
                        return;
                    }

                    dispatch({ type: USER_NOT_FOUND });
                });
        }

        userManager.events.addUserLoaded(onUserFound);
        userManager.events.addSilentRenewError(onSilentRenewError);
        userManager.events.addAccessTokenExpired(onSessionExpired);
        userManager.events.addUserUnloaded(onSessionTerminated);
        userManager.events.addUserSignedOut(onUserSignedOut);

        return () => {
            isCancelled = true;
            userManager.events.removeUserLoaded(onUserFound);
            userManager.events.removeSilentRenewError(onSilentRenewError);
            userManager.events.removeAccessTokenExpired(onSessionExpired);
            userManager.events.removeUserUnloaded(onSessionTerminated);
            userManager.events.removeUserSignedOut(onUserSignedOut);
        };
        // Not sure why state.user wasn't in here, but don't want to risk adding it
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userManager]);

    return (
        <OidcContext.Provider
            value={{
                isLoadingUser: state.isLoadingUser,
                user: state.user || undefined,
                controller: userManager,
                markUserAsStale: onSessionExpired,
            }}
        >
            {children}
        </OidcContext.Provider>
    );
};
