import { ApiRequest, createRequest } from '../util/createRequest';

export interface Api {
    get: ApiRequest;
    post: ApiRequest;
    put: ApiRequest;
    delete: ApiRequest;
    patch: ApiRequest;
}

export interface ApiServiceParams {
    baseUrl: string;
    getAccessToken: () => string | undefined;
}

/**
 * Service for making any API calls. When initiated, gets passed a function to get the
 * token from the store. In our case so far, this happens in `configureStore` after the
 * store has been created. The so created API services is then passed to the sagas
 * of the individual store modules, so those can make API requests without having
 * to worry about authentication etc.
 *
 * This services exposes multiple shortcuts for HTTP requests (e.g. `get`, `post`).
 *
 * @param baseUrl The base url to base api requests on. Can be overridden on a case by
 *                case basis by passing `baseUrl` when calling the service method.
 * @param getAccessToken A method to get the access token from the store.
 */
export const ApiService = ({
    baseUrl,
    getAccessToken,
}: ApiServiceParams): Api => {
    const baseRequestConfig = {
        defaultBaseUrl: baseUrl,
        getAccessToken,
    };

    return {
        get: createRequest({
            ...baseRequestConfig,
            defaultMethod: 'GET',
        }),
        post: createRequest({
            ...baseRequestConfig,
            defaultMethod: 'POST',
        }),
        put: createRequest({
            ...baseRequestConfig,
            defaultMethod: 'PUT',
        }),
        delete: createRequest({
            ...baseRequestConfig,
            defaultMethod: 'DELETE',
        }),
        patch: createRequest({
            ...baseRequestConfig,
            defaultMethod: 'PATCH',
        }),
    };
};
