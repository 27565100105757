import {
    Heading,
    Textfield,
    Button,
} from '@oetkerdigital/eden-design-system-react';
import React, {
    FocusEvent,
    FormEvent,
    FunctionComponent,
    useContext,
    useEffect,
    useState,
} from 'react';
import { UpdateUserProfileRequest } from '../../../api-types/user-manager';
import { AuthContext } from '../../../components/AuthGate';
import config from '../../../config';
import { ElementWithValue } from '../../../hooks/useFormField';
import { provide } from '../../../util/provide';
import {
    withCurrentUserProfile,
    useCurrentUserProfile,
} from '../../user-manager/users/CurrentUserProfileProvider';
import {
    PasswordSettingsContext,
    withPasswordSettings,
} from '../PasswordSettingsProvider';
import { useAlerts } from '../../../components/AlertProvider';
import { OidcContext } from '../../../components/OidcProvider';

/**
 *
 * @todo: Don't forget to reuse methods from ResetPassword and here and exclude it to the helper
 */
const __UserProfile: FunctionComponent = () => {
    const alerts = useAlerts();

    const { passwordRequirements, validatePassword: validatePasswordWithApi } =
        useContext(PasswordSettingsContext);

    const {
        currentUser: user,
        currentUserError,
        updateProfile,
    } = useCurrentUserProfile();

    // Set an error in the current view if current user reports a new error
    useEffect(() => {
        if (currentUserError) {
            alerts.error(currentUserError);
        }
    }, [currentUserError, alerts]);

    const { api } = useContext(AuthContext);
    const { markUserAsStale } = useContext(OidcContext);

    const [firstNameFieldValue, setFirstNameFieldValue] = useState('');
    const [lastNameFieldValue, setLastNameFieldValue] = useState('');
    const [emailFieldValue, setEmailFieldValue] = useState('');
    const [departmentFieldValue, setDepartmentFieldValue] = useState('');
    const [phoneNumberFieldValue, setPhoneNumberFieldValue] = useState('');
    const [oldPasswordFieldValue, setOldPasswordFieldValue] = useState('');
    const [newPasswordFieldValue, setNewPasswordFieldValue] = useState('');
    const [repeatPasswordFieldValue, setRepeatPasswordFieldValue] =
        useState('');

    const [newPasswordFieldError, setNewPasswordFieldError] = useState('');
    const [repeatPasswordFieldError, setRepeatPasswordFieldError] =
        useState('');

    const [isUserDataUpdated, setIsUserDataUpdated] = useState(false);

    const canSubmitUserData =
        !!firstNameFieldValue && !!lastNameFieldValue && isUserDataUpdated;

    const canSubmitPassword =
        !!oldPasswordFieldValue &&
        !!newPasswordFieldValue &&
        !!repeatPasswordFieldValue &&
        !newPasswordFieldError &&
        !repeatPasswordFieldError;

    const passwordRequirementsText = `Your password needs to satisfy the following criteria: ${passwordRequirements.join(
        ', '
    )}`;

    useEffect(() => {
        if (!user) {
            return;
        }

        setFirstNameFieldValue(user.FirstName);
        setLastNameFieldValue(user.LastName);
        setEmailFieldValue(user.Email);

        if (!!user.Department) {
            setDepartmentFieldValue(user.Department);
        }

        if (!!user.PhoneNumber) {
            setPhoneNumberFieldValue(user.PhoneNumber);
        }
    }, [user]);

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (!canSubmitUserData) {
            return;
        }

        if (user) {
            const body: UpdateUserProfileRequest = {
                FirstName: firstNameFieldValue,
                LastName: lastNameFieldValue,
                Department: departmentFieldValue,
                PhoneNumber: phoneNumberFieldValue,
            };

            const updateCurrentUser = async () => {
                const error = await updateProfile(body);

                if (error) {
                    // TODO: might want a better error message here?
                    alerts.error('error');
                    return;
                }

                alerts.alert({
                    variant: 'success',
                    message: 'Settings updated successfully!',
                    content:
                        'Your session will now be refreshed, please stand by...',
                });

                setTimeout(() => {
                    markUserAsStale();
                }, 3000);
            };

            updateCurrentUser();
        }
    };

    /**
     * On submit, first check if everything is valid, and then send request.
     * @param e
     */
    const onPasswordResetSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (!canSubmitPassword) {
            return;
        }

        if (user) {
            const updateUserPassword = async () => {
                const [error] = await api.put({
                    baseUrl: config.USER_MANAGER_API_BASE_URL,
                    url: `/accounts/${user.Email}/password`,
                    body: {
                        OldPassword: oldPasswordFieldValue,
                        NewPassword: newPasswordFieldValue,
                    },
                });

                if (error) {
                    alerts.error(error);
                    return;
                }

                alerts.success('Password updated successfully');

                setOldPasswordFieldValue('');
                setNewPasswordFieldValue('');
                setRepeatPasswordFieldValue('');
            };

            updateUserPassword();
        }
    };

    /**
     * Validate the password the user just entered with the API.
     * @param e
     */
    const validatePassword = async (e: FocusEvent<ElementWithValue>) => {
        const [error] = await validatePasswordWithApi(e.target.value);

        if (error) {
            setNewPasswordFieldError(error);
            return;
        }

        setNewPasswordFieldError('');
    };

    /**
     * Compare password from a change or blur event (on the password repeat field)
     * with the password in the first password field to see if they match.
     * @param e
     */
    const comparePasswords = (e: FocusEvent<ElementWithValue> | string) => {
        const value = typeof e === 'string' ? e : e.target.value;

        if (value !== newPasswordFieldValue) {
            setRepeatPasswordFieldError(`Password doesn't match`);
        } else {
            setRepeatPasswordFieldError('');
        }
    };

    return (
        <React.Fragment>
            {user ? (
                <React.Fragment>
                    <Heading>User Profile</Heading>

                    <form className="ContentLayout" onSubmit={onSubmit}>
                        <Textfield
                            label="First Name"
                            required
                            value={firstNameFieldValue}
                            onValueChange={value => {
                                setFirstNameFieldValue(value);
                                setIsUserDataUpdated(true);
                            }}
                        />

                        <Textfield
                            label="Last Name"
                            required
                            value={lastNameFieldValue}
                            onValueChange={value => {
                                setLastNameFieldValue(value);
                                setIsUserDataUpdated(true);
                            }}
                        />

                        <Textfield
                            label="Email Address"
                            required
                            disabled
                            value={emailFieldValue}
                        />

                        <Textfield
                            label="Department"
                            value={departmentFieldValue}
                            onValueChange={value => {
                                setDepartmentFieldValue(value);
                                setIsUserDataUpdated(true);
                            }}
                        />

                        <Textfield
                            label="Phone number"
                            value={phoneNumberFieldValue}
                            onValueChange={value => {
                                setPhoneNumberFieldValue(value);
                                setIsUserDataUpdated(true);
                            }}
                        />

                        <Button disabled={!canSubmitUserData}>Save</Button>
                    </form>

                    <Heading className="mt--1">Change Password</Heading>

                    <form
                        className="ContentLayout"
                        onSubmit={onPasswordResetSubmit}
                    >
                        <Textfield
                            type="password"
                            label="Old password"
                            value={oldPasswordFieldValue}
                            onValueChange={setOldPasswordFieldValue}
                        />

                        <Textfield
                            type="password"
                            label="New password"
                            variant={
                                !!newPasswordFieldError ? 'error' : undefined
                            }
                            hint={passwordRequirementsText}
                            value={newPasswordFieldValue}
                            onValueChange={value => {
                                setNewPasswordFieldValue(value);
                                setNewPasswordFieldError('');
                            }}
                            onBlur={validatePassword}
                        />

                        <Textfield
                            type="password"
                            label="Repeat new password"
                            variant={
                                !!repeatPasswordFieldError ? 'error' : undefined
                            }
                            hint={repeatPasswordFieldError}
                            value={repeatPasswordFieldValue}
                            onValueChange={value => {
                                setRepeatPasswordFieldValue(value);
                                comparePasswords(value);
                            }}
                            onBlur={comparePasswords}
                        />

                        <Button disabled={!canSubmitPassword}>
                            Save Password
                        </Button>
                    </form>
                </React.Fragment>
            ) : (
                <p>Loading...</p>
            )}
        </React.Fragment>
    );
};

export const UserProfile = provide(
    __UserProfile,
    withPasswordSettings(),
    withCurrentUserProfile()
);
