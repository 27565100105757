import React, {
    FormEvent,
    FunctionComponent,
    useContext,
    useState,
} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
    Heading,
    Button,
    Textfield,
    Alert,
    IndexingFact,
    TwoColumnLayout,
} from '@oetkerdigital/eden-design-system-react';
import { lightFormat, parseISO } from 'date-fns';
import { useToggle } from '../../../hooks/useToggle';
import { provide } from '../../../util/provide';
import CountrySelector from '../CountrySelector';
import { useSettings } from '../SettingsProvider';
import { useHasPermission } from '../../../hooks/useHasPermission';
import { IndexingContext, withIndexing } from './IndexingProvider';

type AlertSettingState = {
    role: string;
    variant: 'error' | 'success' | undefined;
    message: string;
};

const formatDate = (date: string) =>
    lightFormat(parseISO(date), 'dd.MM.yyyy hh:mm:ss a');

const numberFormatter = new Intl.NumberFormat('en');

const __PageIndexing: FunctionComponent<RouteComponentProps> = () => {
    const hasWritePermission = useHasPermission('oss:modify');

    const [isSaving, setIsSaving] = useState(false);
    const [isReindexing, setIsReindexing] = useState(false);
    const [urlFieldValue, setUrlFieldValue] = useState('');
    const [urlFieldError, setUrlFieldError] = useState('');
    const [alertSettings, setAlertSettings] = useState<AlertSettingState>({
        role: '',
        variant: undefined,
        message: '',
    });

    const [isAlertVisible, toggleAlert] = useToggle(false);
    const { searchIndex, formatSearchIndex } = useSettings();

    const { settingsStatus, indexOne, indexAll, refetch } =
        useContext(IndexingContext);

    const canSave = !!searchIndex && !!urlFieldValue;

    const onUrlValueChange = (value: string) => {
        if (urlFieldError.length > 0) {
            setUrlFieldError('');
        }

        setUrlFieldValue(value);
    };

    const onIndexOne = async (e: FormEvent) => {
        e.preventDefault();

        if (!canSave) {
            return;
        }

        setIsSaving(true);

        const [error] = await indexOne(urlFieldValue);

        setIsSaving(false);

        if (error) {
            setUrlFieldError(error);
            return;
        }

        setUrlFieldValue('');
        toggleAlert();
        setAlertSettings({
            role: 'dialog',
            message: 'Your page has been added successfully',
            variant: 'success',
        });
    };

    const onIndexAll = async (e: FormEvent) => {
        e.preventDefault();

        if (!searchIndex) {
            return;
        }

        setIsReindexing(true);

        const [error] = await indexAll();

        setIsReindexing(false);

        if (error) {
            setAlertSettings({
                role: 'alert',
                message: `Couldn't reindex the page`,
                variant: 'error',
            });
        } else {
            setAlertSettings({
                role: 'alert',
                message: `Request for re-indexing successfully made. Please be patient as processing the request will take a while.`,
                variant: 'success',
            });
            refetch();
        }

        toggleAlert();
    };

    return (
        <React.Fragment>
            <section className="ContentLayout" aria-labelledby="page-indexing">
                <Heading id="page-indexing">Page indexing</Heading>
                <p>
                    Our crawler automatically re-indexes your content at regular
                    intervals. However, there may be instances when you add or
                    update pages on your site and need these changes to be
                    reflected in the site search results immediately.
                </p>

                <CountrySelector />

                {!!settingsStatus ? (
                    <React.Fragment>
                        <IndexingFact
                            icon="docs"
                            value={`${numberFormatter.format(
                                settingsStatus.DocumentCount
                            )} docs`}
                            description="indexed"
                        />

                        <IndexingFact
                            icon="calendar"
                            value={formatDate(
                                settingsStatus.Settings.LastIndexingInitiatedAt
                            )}
                            description="last manual indexing"
                        />

                        <IndexingFact
                            icon="time"
                            value={formatDate(
                                settingsStatus.SyncSettings.NextIndexTime
                            )}
                            description="automatically re-indexing"
                        />
                    </React.Fragment>
                ) : (
                    <p>Fetching status data...</p>
                )}
            </section>

            {hasWritePermission && (
                <React.Fragment>
                    <section className="mt--1" aria-labelledby="add-page">
                        <form className="ContentLayout" onSubmit={onIndexOne}>
                            <Heading as="h3" id="add-page">
                                Add Page
                            </Heading>
                            <p>
                                By using “Add Page”, these pages will
                                appear/change in the results instantly without
                                having to wait for the automatic crawler to
                                re-index your site.
                            </p>
                            <TwoColumnLayout columns="2-1" alignEnd>
                                <Textfield
                                    label="Page URL"
                                    required
                                    hint={urlFieldError}
                                    variant={
                                        urlFieldError.length > 0
                                            ? 'error'
                                            : undefined
                                    }
                                    onValueChange={onUrlValueChange}
                                    value={urlFieldValue}
                                />
                                <Button disabled={!canSave || isSaving}>
                                    {isSaving ? 'Saving...' : 'Save'}
                                </Button>
                            </TwoColumnLayout>
                        </form>
                    </section>
                    <section className="mt--1" aria-labelledby="reindex-page">
                        <form className="ContentLayout" onSubmit={onIndexAll}>
                            <Heading as="h3" id="reindex-page">
                                Re-Index Page
                            </Heading>
                            <p>
                                By using “Re-Index Page”, the selected search
                                index will be set for re-indexing immediately.
                                Use it cautiously as it requires a lot of server
                                resources so don't spam the button!
                            </p>
                            <p>
                                The site will be re-indexed automatically at:{' '}
                                {settingsStatus &&
                                    formatDate(
                                        settingsStatus.SyncSettings
                                            .NextIndexTime
                                    )}
                            </p>

                            {hasWritePermission && (
                                <Button
                                    type="submit"
                                    variant="secondary"
                                    disabled={isReindexing}
                                >
                                    {isReindexing
                                        ? 'Re-Indexing'
                                        : `Re-Index ${formatSearchIndex(
                                              searchIndex
                                          )}`}
                                </Button>
                            )}
                        </form>
                    </section>
                </React.Fragment>
            )}

            {/* TODO: Migrate to AlertProvider */}
            {isAlertVisible && (
                <Alert {...alertSettings}>
                    <Button onClick={toggleAlert} variant="secondary">
                        Ok
                    </Button>
                </Alert>
            )}
        </React.Fragment>
    );
};

export const PageIndexing = provide(__PageIndexing, withIndexing());
