import {
    Button,
    Heading,
    Rangepicker,
    Switch,
    Textfield,
    TwoColumnLayout,
} from '@oetkerdigital/eden-design-system-react';
import React, {
    FormEvent,
    FunctionComponent,
    useContext,
    useEffect,
    useState,
} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { SortableTableHeadCell } from '../../../components/SortableTableHeadCell';
import { useHasPermission } from '../../../hooks/useHasPermission';
import { getRangeFilterQueryString } from '../../../util/dateFormatting';
import { provide } from '../../../util/provide';
import CountrySelector from '../CountrySelector';
import { useAlerts } from '../../../components/AlertProvider';
import {
    IndexingDocumentsContext,
    initialIndexingRange,
    withIndexingDocuments,
} from './IndexingDocumentsProvider';
import {
    initialReportsRange,
    ReportsContext,
    withReports,
} from './ReportsProvider';

const roundToTwo = (number: number) => Math.round(number * 100) / 100;

// Reporting Datepicker should be restricted to be available from 01.01.2018
// because there is no data earlier than that and till current month
const reportsFromMonth = { month: 1, year: 2018 };
const reportsToMonth = {
    month: initialReportsRange.to.getMonth() + 1,
    year: initialReportsRange.to.getFullYear(),
};

// Indexing Datepicker should be restricted to be available from 01.11.2019
// because there is no data earlier than that and till current month
const indexingDocumentsFromMonth = { month: 11, year: 2019 };
const indexingDocumentsToMonth = reportsToMonth;

const __Reports: FunctionComponent<RouteComponentProps> = () => {
    const {
        data: reports,
        isLoading: isLoadingReports,
        orderByProps: reportsOrderByProps,
        limitProps: reportsLimitProps,
        queryProps: reportsQueryProps,
        filterProps: reportsFilterProps,
    } = useContext(ReportsContext);

    const { documents, documentsError, areDocumentsLoading, changeDates } =
        useContext(IndexingDocumentsContext);

    const alerts = useAlerts();

    useEffect(() => {
        if (documentsError) {
            alerts.error(documentsError);
        }
    }, [documentsError, alerts]);

    const [searchQuery, setSearchQuery] = useState('');

    // Update local value if it changes in the state
    useEffect(() => {
        setSearchQuery(reportsQueryProps.query);
    }, [reportsQueryProps.query]);

    const hasChangedSearchQuery = searchQuery !== reportsQueryProps.query;

    const onSearchQuerySubmit = (e: FormEvent) => {
        e.preventDefault();

        if (!hasChangedSearchQuery) {
            return;
        }

        reportsQueryProps.queryBy(searchQuery);
    };

    const noDocuments = !documents || (documents && !documents.length);

    const hasIndexingStatisticsPermission = useHasPermission(
        'oss:indexing-statistics'
    );

    const [dateRange, setDateRange] = useState(initialReportsRange);

    useEffect(() => {
        reportsFilterProps.filterBy(`&${getRangeFilterQueryString(dateRange)}`);
    }, [dateRange, reportsFilterProps]);

    const [indexingDateRange, setIndexingDateRange] =
        useState(initialIndexingRange);

    useEffect(() => {
        changeDates(getRangeFilterQueryString(indexingDateRange));
    }, [indexingDateRange, changeDates]);

    return (
        <React.Fragment>
            <section className="ContentLayout" aria-labelledby="reports">
                <Heading id="reports">Reports</Heading>
                <p>
                    Every search request is saved to our database. Using the
                    search Report page you can find and analyse requests. Based
                    on these requests you can optimise the results (by adding
                    ranking to certain page types or adding synonyms to return
                    more results) or create new content for highly searched
                    content.
                </p>
                <p>
                    The reporting page enables you to make data driven decisions
                    and efficiently adjust your search settings.
                </p>

                <CountrySelector />

                <Rangepicker
                    title="Date range for reports"
                    fromLabel="Start"
                    toLabel="End"
                    onValueChange={setDateRange}
                    value={dateRange}
                    fromMonth={reportsFromMonth}
                    untilMonth={reportsToMonth}
                />

                <form onSubmit={onSearchQuerySubmit}>
                    <TwoColumnLayout columns="2-1" alignEnd>
                        <Textfield
                            label="Filter by search term"
                            value={searchQuery}
                            onValueChange={setSearchQuery}
                        />
                        <Button disabled={!hasChangedSearchQuery} block>
                            Search
                        </Button>
                    </TwoColumnLayout>
                </form>

                <table aria-label="Reports Table" className="Table mt--1">
                    <thead>
                        <tr>
                            <SortableTableHeadCell
                                sortKey="Query"
                                {...reportsOrderByProps}
                            >
                                Search term
                            </SortableTableHeadCell>
                            <SortableTableHeadCell
                                sortKey="DocumentCount"
                                {...reportsOrderByProps}
                            >
                                Requests
                            </SortableTableHeadCell>
                            <SortableTableHeadCell
                                sortKey="AvgTotalResults"
                                {...reportsOrderByProps}
                            >
                                Results
                            </SortableTableHeadCell>
                        </tr>
                    </thead>
                    <tbody>
                        {/* Display list if there's results */}
                        {!isLoadingReports &&
                            reports.length > 0 &&
                            reports.map(row => (
                                <tr key={row.Query}>
                                    <td>{row.Query}</td>
                                    <td>{row.DocumentCount}</td>
                                    <td>{roundToTwo(row.AvgTotalResults)}</td>
                                </tr>
                            ))}

                        {/* Display empty state if there's no results and loading is done */}
                        {!isLoadingReports && reports.length === 0 && (
                            <tr>
                                <td colSpan={3}>No results</td>
                            </tr>
                        )}

                        {/* Display loading state if there's no results and still loading */}
                        {isLoadingReports && (
                            <tr>
                                <td colSpan={3}>Loading...</td>
                            </tr>
                        )}
                    </tbody>
                </table>

                <Switch<number>
                    className="mt--1"
                    items={[
                        { value: 10, label: '10' },
                        { value: 50, label: '50' },
                        { value: 100, label: '100' },
                    ]}
                    value={reportsLimitProps.limit}
                    onValueChange={reportsLimitProps.limitTo}
                    aria-label="Limit results to"
                />
            </section>

            {hasIndexingStatisticsPermission && (
                <section
                    className="ContentLayout mt--1"
                    aria-labelledby="index-statistics"
                >
                    <Heading id="index-statistics">Indexing Statistics</Heading>
                    <p>
                        The indexing statistics helps you to understand how many
                        pages were indexed per month per country.
                    </p>

                    <Rangepicker
                        title="Date range for indexing statistics"
                        fromLabel="Start"
                        toLabel="End"
                        onValueChange={setIndexingDateRange}
                        value={indexingDateRange}
                        fromMonth={indexingDocumentsFromMonth}
                        untilMonth={indexingDocumentsToMonth}
                    />

                    <table
                        className="Table"
                        aria-label="Indexing Documents Table"
                    >
                        <thead>
                            <tr>
                                <th>Country code</th>
                                <th>Documents indexed</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* Display list if there's results */}
                            {!areDocumentsLoading &&
                                documents &&
                                documents.length > 0 &&
                                documents.map((document, index) => (
                                    <React.Fragment key={index}>
                                        {document.Items.map(
                                            (item, itemIndex) => (
                                                <tr key={itemIndex}>
                                                    <td>{item.IndexName}</td>
                                                    <td>
                                                        {roundToTwo(
                                                            item.AvgCount
                                                        )}
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </React.Fragment>
                                ))}

                            {/* Display empty state if there's no results and loading is done */}
                            {!areDocumentsLoading && noDocuments && (
                                <tr>
                                    <td colSpan={2}>No results</td>
                                </tr>
                            )}

                            {/* Display loading state if there's no results and still loading */}
                            {areDocumentsLoading && (
                                <tr>
                                    <td colSpan={2}>Loading...</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </section>
            )}
        </React.Fragment>
    );
};

export const Reports = provide(
    __Reports,
    withReports(),
    withIndexingDocuments()
);
