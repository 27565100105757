import React, {
    createContext,
    FunctionComponent,
    ReactNode,
    useContext,
    useEffect,
    useState,
    useCallback,
} from 'react';
import { ListWebsiteSettingsResponse } from '../../api-types/search';
import { AuthContext } from '../../components/AuthGate';
import config from '../../config';
import { createProviderHoC } from '../../util/createProviderHoC';
import { ApiRequestResult } from '../../util/createRequest';

/**
 * Remove `oetker` prefix from index name, e.g. oetker_uk-en => UK-EN
 * @param indexName
 */
const getCountryCode = (indexName: string): string => {
    return indexName.replace('oetker_', '').toUpperCase();
};

interface SettingsProviderProps {
    children?: ReactNode;
    runInitialFetch?: boolean;
}

type SettingsContextState = {
    searchIndices: string[];
    error: string;
    isLoading: boolean;
    searchIndex: string;
    formatSearchIndex: (searchIndex: string) => string;
    setSearchIndex: (searchIndex: string) => void;
    getRequestHeaders: () => Record<string, string> | undefined;
};

const defaults: Pick<
    SettingsContextState,
    'searchIndices' | 'error' | 'isLoading' | 'searchIndex'
> = {
    searchIndices: [],
    error: '',
    isLoading: false,
    searchIndex: '',
};

/**
 * Since don't want to reload the settings for every page (and especially don't
 * want the user to have to choose the index again every time), we persist these
 * settings across page navigations here. For testing purposes (since we will
 * usually want to test the first page load), a reset method is provided below.
 */
const cache = {
    searchIndices: defaults.searchIndices,
    searchIndex: defaults.searchIndex,
};

/**
 * Reset the cache - for testing purposes
 */
export const resetSettingsCache = () => {
    cache.searchIndices = defaults.searchIndices;
    cache.searchIndex = defaults.searchIndex;
};

const SettingsContext = createContext<SettingsContextState | undefined>(
    undefined
);

const SettingsProvider: FunctionComponent<SettingsProviderProps> = ({
    children,
}) => {
    const [searchIndices, setSearchIndices] = useState(cache.searchIndices);
    const [error, setError] = useState(defaults.error);
    const [isLoading, setIsLoading] = useState(defaults.isLoading);

    const [searchIndex, setSearchIndex] = useState(cache.searchIndex);

    const { api } = useContext(AuthContext);

    useEffect(() => {
        let isCancelled = false;

        const loadData = async () => {
            setIsLoading(true);

            const [error, data]: ApiRequestResult<ListWebsiteSettingsResponse> =
                await api.get({
                    baseUrl: config.SEARCH_OPTIMIZATION_API_BASE_URL,
                    url: `/settings/list`,
                });

            /* istanbul ignore if */
            if (isCancelled) {
                return;
            }

            setIsLoading(false);

            if (error) {
                setError(error);
                return;
            }

            setSearchIndices(data.AvailableSettings);

            // Set default search index only if it wasn't set before
            if (searchIndex || !data.AvailableSettings.length) {
                return;
            }

            // If the user previously had a search index selected, and it still
            // exists, restore that, otherwise select the first one
            const storedSearchIndex = localStorage.getItem(
                'SettingsContext.searchIndex'
            );

            if (
                storedSearchIndex &&
                data.AvailableSettings.indexOf(storedSearchIndex) > -1
            ) {
                setSearchIndex(storedSearchIndex);
                return;
            }

            setSearchIndex(data.AvailableSettings[0]);
        };

        if (!searchIndices.length) {
            loadData();
        }

        return () => {
            isCancelled = true;
        };

        // We don't want to re-run when searchIndex changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [api]);

    useEffect(() => {
        cache.searchIndex = searchIndex;
        if (searchIndex) {
            localStorage.setItem('SettingsContext.searchIndex', searchIndex);
        }
    }, [searchIndex]);

    useEffect(() => {
        cache.searchIndices = searchIndices;
    }, [searchIndices]);

    /**
     * Provide utility to get request headers, since we need them to send any
     * requests to settings based endpoints.
     */
    const getRequestHeaders = useCallback(() => {
        if (!searchIndex) {
            return;
        }

        return {
            'x-index-name': searchIndex,
        };
    }, [searchIndex]);

    return (
        <SettingsContext.Provider
            value={{
                searchIndices,
                error,
                isLoading,
                searchIndex,
                formatSearchIndex: getCountryCode,
                setSearchIndex,
                getRequestHeaders,
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
};

const withSettings = createProviderHoC(SettingsProvider);

export { withSettings };
export default SettingsProvider;

export function useSettings() {
    const context = useContext(SettingsContext);

    /* istanbul ignore if */
    if (!context) {
        throw new Error(
            '`useSettings` must be used within a `SettingsProvider`!'
        );
    }

    return context;
}
