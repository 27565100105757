import { useContext, useEffect, useState } from 'react';
import {
    CreateSproutItemRequest,
    SproutItemResponse,
    UpdateSproutItemRequest,
    PaginationResult,
} from '../../api-types/sprout';
import { AuthContext } from '../../components/AuthGate';
import config from '../../config';
import { ApiRequestResult } from '../../util/createRequest';

interface SproutApiParams {
    // Theoretically, this could be any string, but we currenly only use these
    categoryCode: 'brand' | 'country';
    limit?: number;
}

export interface SproutApiState {
    data: SproutItemResponse[];
    error: string;
    isLoading: boolean;
    create: (body: CreateSproutItemRequest) => Promise<ApiRequestResult>;
    update: (
        id: number | string,
        body: UpdateSproutItemRequest
    ) => Promise<ApiRequestResult>;
    delete: (id: number | string) => Promise<ApiRequestResult<null>>;
    fetchAll: () => void;
    fetchOne: (
        id: number | string
    ) => Promise<ApiRequestResult<SproutItemResponse | null>>;
}

function useSproutApi({
    categoryCode,
    limit = 100,
}: SproutApiParams): SproutApiState {
    const [data, setData] = useState<SproutItemResponse[]>([]);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [isStale, setIsStale] = useState(true);

    const { api } = useContext(AuthContext);

    useEffect(() => {
        if (!isStale) {
            return;
        }

        let isCancelled = false;

        const loadData = async () => {
            setIsLoading(true);

            const [error, data]: ApiRequestResult<
                PaginationResult<SproutItemResponse>
            > = await api.get({
                baseUrl: config.SPROUT_API_BASE_URL,
                url: `/items?CategoryCode=${categoryCode}&Limit=${limit}`,
            });

            if (isCancelled) {
                return;
            }

            setIsStale(false);
            setIsLoading(false);

            if (error) {
                setError(error);
                return;
            }

            setData(
                [...data.Results].sort((a, b) => (a.Code > b.Code ? 1 : -1))
            );
        };

        loadData();

        return () => {
            isCancelled = true;
        };
    }, [api, categoryCode, isStale, limit]);

    const create = async (body: CreateSproutItemRequest) => {
        const requestResult = await api.post({
            baseUrl: config.SPROUT_API_BASE_URL,
            url: `/items`,
            body: {
                SproutCategoryCode: categoryCode,
                ...body,
            },
        });

        return requestResult;
    };

    const update = async (
        id: string | number,
        body: UpdateSproutItemRequest
    ) => {
        const requestResult = await api.put({
            baseUrl: config.SPROUT_API_BASE_URL,
            url: `/items/${id}`,
            body,
        });

        return requestResult;
    };

    // This cannot be named delete because JS doesn't allow that as an identifier
    const safeDelete = async (id: string | number) => {
        const requestResult = await api.delete({
            baseUrl: config.SPROUT_API_BASE_URL,
            url: `/items/${id}`,
        });

        return requestResult;
    };

    const fetchAll = () => setIsStale(true);

    const fetchOne = async (id: string | number) => {
        const requestResult = await api.get({
            baseUrl: config.SPROUT_API_BASE_URL,
            url: `/items/${id}`,
        });

        return requestResult;
    };

    return {
        data,
        error,
        isLoading,
        create,
        update,
        delete: safeDelete,
        fetchAll,
        fetchOne,
    };
}

export default useSproutApi;
