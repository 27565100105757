import {
    UserResponse,
    CompanyListResponse,
} from '../../api-types/user-manager';

// TODO: Might make sense to move this to core?
export const truncate = (text: string, length: number): string =>
    text.length < length - 3 ? text : text.slice(0, length - 3).concat('...');

const toName = (company: CompanyListResponse) => company.Name;

export const getCompaniesReadable = (data: UserResponse): string => {
    return truncate(data.Companies.map(toName).join(', '), 30);
};

export const getRolesReadable = (data: UserResponse): string => {
    /** @todo: Fix the proble with UserRoleResponse auto typings generator */
    return truncate(
        data.UserRoles.map((role: any) => role.Name).join(', '),
        25
    );
};
