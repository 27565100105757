import {
    Dropdown,
    Icon,
    TwoColumnLayout,
    ContentLayout,
} from '@oetkerdigital/eden-design-system-react';
import React, { FunctionComponent } from 'react';
import { SuggestionSettingContextModel } from '../../../api-types/search';

const toOption = (value: string) => ({
    label: value,
    value,
});

interface SuggestionContextRowProps {
    context: string;
    contextOptions: string[];
    onUpdate: (context: string) => void;
    onRemove: () => void;
    values: SuggestionSettingContextModel[];
    valueOptions: string[];
    onValueAdd: (value: string) => () => void;
    onValueUpdate: (index: number) => (value: string) => void;
    onValueRemove: (index: number) => () => void;
}

const SuggestionContextRow: FunctionComponent<SuggestionContextRowProps> = ({
    context,
    contextOptions,
    onUpdate,
    onRemove,
    values,
    valueOptions,
    onValueAdd,
    onValueUpdate,
    onValueRemove,
}) => {
    const contextDropdownOptions = contextOptions.map(toOption);
    const contextExistsInDropdown = contextOptions.includes(context);

    const valueDropdownOptions = valueOptions.map(toOption);

    const usedValues = values.map(context => context.Context);

    const firstUnusedValue = valueOptions.find(
        value => !usedValues.includes(value)
    );

    return (
        <TwoColumnLayout>
            <div style={{ display: 'flex' }}>
                <Dropdown
                    label="Tag"
                    options={contextDropdownOptions}
                    value={
                        contextExistsInDropdown
                            ? context /* istanbul ignore next : This test should be in dropdown  */
                            : null
                    }
                    onValueChange={onUpdate}
                />
                <Icon
                    aria-label="Remove context"
                    name="close"
                    button
                    onClick={onRemove}
                />
            </div>
            <ContentLayout>
                {values.map((contextValue, contextValueIndex) => {
                    const valueExistsInDropdown = valueOptions.includes(
                        contextValue.Context
                    );

                    return (
                        <div
                            style={{ display: 'flex' }}
                            key={contextValue.Context}
                        >
                            <Dropdown
                                label="Value"
                                options={valueDropdownOptions}
                                value={
                                    valueExistsInDropdown
                                        ? contextValue.Context
                                        : /* istanbul ignore next : This test should be in dropdown  */ null
                                }
                                onValueChange={onValueUpdate(contextValueIndex)}
                            />
                            <Icon
                                aria-label="Remove context value"
                                name="close"
                                button
                                onClick={onValueRemove(contextValueIndex)}
                            />
                        </div>
                    );
                })}

                {firstUnusedValue && (
                    <Icon
                        aria-label="Add context value"
                        name="add_small"
                        button
                        onClick={onValueAdd(firstUnusedValue)}
                    />
                )}
            </ContentLayout>
        </TwoColumnLayout>
    );
};

export default SuggestionContextRow;
