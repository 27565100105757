import { ChangeEvent, FocusEvent, useEffect, useState } from 'react';
import { Validator } from '../util/validators';
import { useFormFieldState } from './useFormFieldState';

export interface ElementWithValue extends Element {
    value: string;
}

export interface UseFormFieldParameters {
    initialValue: string;
    /**
     * Defines validation functions which will be applied to the formField value
     * Validators definition comes from the config, like:
     * {    ...,
     *      validators: [minLength(5), hasNoAt]
     * },
     * where functions are like:
     *
     * const minLength = (length) => (value) => {
     *      if (value.length < length) {
     *          return { message: 'Too short! Value should has at least 5 symbols' }
     * `    }
     * }
     * OR
     * const hasNoAt = (value) => {
     *      if (/@/g.test(value)) {
     *          return { message: 'Value should not have @ symbol' }
     *      }
     * }
     */
    validators: Array<Validator>;
    isRequired?: boolean;
}

/**
 * The actual implementation, taking either of the two options as parameter.
 * @param initialValueParams
 */
function useFormField(initialValueParams: UseFormFieldParameters) {
    const { initialValue, ...params } = initialValueParams;

    const [value, setFromEvent, setValue] =
        useFormFieldState<ElementWithValue>(initialValue);

    // There are two errors in this hook: `error` and `validationError`
    // `error` is the one is controlled via api and `validationError` in not exposed to api and triggered only internally in the hook
    // to not mess up `error` which was setted up via api.
    const [error, setError] = useState('');

    const [validationError, setValidationError] = useState('');

    const [isValid, setIsValid] = useState(true);

    const [touched, setTouched] = useState(false);

    /**
     * This method is calling each validator function one by one AND
     * @returns validator OR empty string if everything is fine
     * OR no validators were specified.
     *
     * @param validators: Array of validator functions
     * @param value: Input value to pass by each validator
     */
    const getValidatorMessage = (
        value: string,
        validators: Array<Validator>
    ): string => {
        const validatorsLength = validators.length;

        for (let index = 0; index < validatorsLength; index++) {
            const validator = validators[index](value);

            if (!!validator) {
                return validator;
            }
        }

        return '';
    };

    useEffect(() => {
        // TODO: This operation is operating slowly on `localhost` with visible stucks. Understand why?
        // Doesn't happen for minified and gzipped version
        setIsValid(!getValidatorMessage(value, params.validators));
    }, [params.validators, value]);

    // Build a props object that can directly be spread into a `FormField` component
    const props = {
        value,

        /**
         * For the `FormField` props, only display a potential error if the field has been touched
         * Includes also validationError because validationError is internal error and `error` is external accessible one
         */
        error: touched ? validationError || error : '',

        /**
         * Run our own change handler first to persist the new value, then run any other handlers if given
         */
        onChange: (e: ChangeEvent<ElementWithValue>) => {
            setFromEvent(e);
        },

        /**
         * Run our own blur handler first to persist touched state, then run any other handlers if given
         */
        onBlur: (e: FocusEvent<ElementWithValue>) => {
            setTouched(true);

            // Setup validation message if validation was not passed
            if (!isValid) {
                const validationMessage = getValidatorMessage(
                    e.target.value,
                    params.validators
                );

                setValidationError(validationMessage);
            } else {
                setValidationError('');
            }
        },

        required: params.isRequired,
    };

    const api = {
        props,
        value,
        setValue,
        error: validationError || error,
        setError,
        touched,
        setTouched,
        isValid,
    };

    return api;
}

export { useFormField };
