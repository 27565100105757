import { produce } from 'immer';
import React, {
    createContext,
    FunctionComponent,
    ReactNode,
    useEffect,
} from 'react';
import { useLocation } from 'react-router-dom';
import {
    UpdateUserRequest,
    UserResponse,
} from '../../../api-types/user-manager';
import config from '../../../config';
import {
    PaginatedApiState,
    paginatedApiStateDefaults,
    usePaginatedApi,
} from '../../../hooks/usePaginatedApi';
import { createProviderHoC } from '../../../util/createProviderHoC';
import { getQueryParam } from '../../../util/getQueryParam';

interface UsersProviderProps {
    children?: ReactNode;
    runInitialFetch?: boolean;
    limit?: number;
}

type UsersContextState = PaginatedApiState<UserResponse, UpdateUserRequest>;

const defaults = {
    orderingBy: 'FirstName' as keyof UserResponse,
    isOrderingByDesc: true,
};

const UsersContext = createContext<UsersContextState>(
    produce(paginatedApiStateDefaults, draft => {
        draft.orderByProps.orderingBy = defaults.orderingBy;
    })
);

const UsersProvider: FunctionComponent<UsersProviderProps> = ({
    children,
    runInitialFetch,
    limit = 10,
}) => {
    const location = useLocation();

    const companyIds = getQueryParam(location.search, 'companyIds');

    const filter = companyIds ? `&companyIds=${companyIds}` : '';

    const paginatedApiState = usePaginatedApi<UserResponse, UpdateUserRequest>({
        baseUrl: config.USER_MANAGER_API_BASE_URL,
        url: '/users',
        runInitialFetch,
        defaults: {
            ...defaults,
            filter,
            limit,
        },
    });

    // When the query string updates, update the filter to re-run request
    useEffect(() => {
        if (paginatedApiState.filterProps.filter !== filter) {
            paginatedApiState.filterProps.filterBy(filter);
        }
    }, [filter, paginatedApiState.filterProps]);

    return (
        <UsersContext.Provider value={paginatedApiState}>
            {children}
        </UsersContext.Provider>
    );
};

const withUsers = createProviderHoC(UsersProvider);

export { UsersContext, withUsers };
export default UsersProvider;
