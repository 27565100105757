import React, { FunctionComponent, useContext } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import {
    Button,
    Heading,
    Card,
    TwoColumnLayout,
} from '@oetkerdigital/eden-design-system-react';
import { useHasPermission } from '../../../hooks/useHasPermission';
import { useAlerts } from '../../../components/AlertProvider';
import { CountriesContext, withCountries } from './CountriesProvider';

const __CountriesList: FunctionComponent<RouteComponentProps> = () => {
    const {
        data: countries,
        delete: deleteCountry,
        fetchAll: refetch,
    } = useContext(CountriesContext);

    const alerts = useAlerts();

    const onDelete = (id: number) => {
        const countryToDelete = countries.find(c => c.Id === id)!;

        alerts.alert({
            message: `Really delete country "${countryToDelete.Name}"?`,
            content: (
                <React.Fragment>
                    <p>This action cannot be undone.</p>

                    <Button
                        onClick={() => onDeleteConfirm(id)}
                        title={`Confirm that country ${countryToDelete.Name} will be deleted`}
                        className="mr--1"
                    >
                        Yes, delete
                    </Button>

                    <Button
                        variant="secondary"
                        onClick={() => alerts.remove()}
                        title={`Abort deleting country ${countryToDelete.Name}`}
                    >
                        Cancel
                    </Button>
                </React.Fragment>
            ),
        });
    };

    const onDeleteConfirm = async (id: number) => {
        const [error] = await deleteCountry(id);

        if (error) {
            // TODO: Handle error
            return;
        }

        alerts.success('The country has been deleted successfully.');
        refetch();
    };

    const isModifier = useHasPermission('sprout:modify');

    return (
        <React.Fragment>
            <Heading>Countries</Heading>
            <p>All countries currently available in the system.</p>

            {isModifier && (
                <Link
                    className="Button"
                    to="/service-configurator/countries/new"
                >
                    Create new country
                </Link>
            )}

            <TwoColumnLayout>
                {countries.map(country => (
                    <Card label={country.Code} key={`country-${country.Code}`}>
                        <p>{country.Name}</p>
                        {isModifier && (
                            <div className="mt--1">
                                <Link
                                    className="Button mr--1"
                                    to={`/service-configurator/countries/${country.Id}/edit`}
                                    title={`Edit ${country.Name}`}
                                >
                                    Edit
                                </Link>
                                <Button
                                    onClick={() => onDelete(country.Id)}
                                    title={`Delete ${country.Name}`}
                                    variant="secondary"
                                >
                                    Delete
                                </Button>
                            </div>
                        )}
                    </Card>
                ))}
            </TwoColumnLayout>
        </React.Fragment>
    );
};

export const CountriesList = withCountries()(__CountriesList);
