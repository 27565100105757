// This file is ignored in coverage reporting, we don't need to test lazy loading or the disabled feature case
import React from 'react';
import { Redirect } from 'react-router-dom';
import { RoutingRoute } from '../../components/Routing';
import { createUserPermissionValidator } from '../../util/createUserPermissionValidator';
import { UserDetails } from './users/UserDetails';
import { UserForm } from './users/UserForm';
import { UsersList } from './users/UsersList';

const prefix = '/user-manager';

const usersRoute: RoutingRoute = {
    path: `${prefix}/users`,
    label: 'Users',
    component: UsersList,
};

const createUserRoute: RoutingRoute = {
    path: `${prefix}/users/new`,
    label: 'Create User',
    component: UserForm,
    validateUser: createUserPermissionValidator('user-manager:users:modify'),
    hideInMenu: true,
};

const manageUser: RoutingRoute = {
    path: `${prefix}/users/:userId/edit`,
    label: 'Edit User',
    component: UserForm,
    validateUser: createUserPermissionValidator('user-manager:users:modify'),
    hideInMenu: true,
};

const userDetails: RoutingRoute = {
    path: `${prefix}/users/:userId`,
    label: 'User Details',
    component: UserDetails,
    hideInMenu: true,
};

const subItems = [usersRoute, createUserRoute, manageUser, userDetails];

const routes: RoutingRoute[] = [
    {
        path: prefix,
        label: 'User Manager',
        component: () => <Redirect to={`${prefix}/users`} />,
        validateUser: createUserPermissionValidator('user-manager:users:list'),
        subItems,
    },
];

export default routes;
