import React, { FunctionComponent, useContext } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import {
    Heading,
    Card,
    Button,
    TwoColumnLayout,
} from '@oetkerdigital/eden-design-system-react';
import { useHasPermission } from '../../../hooks/useHasPermission';
import { useAlerts } from '../../../components/AlertProvider';
import { BrandsContext, withBrands } from './BrandsProvider';

const __BrandsList: FunctionComponent<RouteComponentProps> = () => {
    const {
        data: brands,
        delete: deleteBrand,
        fetchAll: refetch,
    } = useContext(BrandsContext);

    const alerts = useAlerts();

    const onDelete = (id: number) => {
        const brandToDelete = brands.find(b => b.Id === id)!;

        alerts.alert({
            message: `Really delete brand "${brandToDelete.Name}"?`,
            content: (
                <React.Fragment>
                    <p>This action cannot be undone.</p>

                    <Button
                        onClick={() => onDeleteConfirm(id)}
                        title={`Confirm that brand ${brandToDelete.Name} will be deleted`}
                        className="mr--1"
                    >
                        Yes, delete
                    </Button>

                    <Button
                        variant="secondary"
                        onClick={() => alerts.remove()}
                        title={`Abort deleting brand ${brandToDelete.Name}`}
                    >
                        Cancel
                    </Button>
                </React.Fragment>
            ),
        });
    };

    const onDeleteConfirm = async (id: number) => {
        const [error] = await deleteBrand(id);

        if (error) {
            // TODO: Handle error
            return;
        }

        alerts.success('The brand has been deleted successfully.');
        refetch();
    };

    const isModifier = useHasPermission('sprout:modify');

    return (
        <React.Fragment>
            <Heading>Brands</Heading>
            <p>All brands currently available in the system.</p>

            {isModifier && (
                <Link className="Button" to="/service-configurator/brands/new">
                    Create new brand
                </Link>
            )}

            <TwoColumnLayout>
                {brands.map(brand => (
                    <Card label={brand.Code} key={`brand-${brand.Code}`}>
                        <p>{brand.Name}</p>
                        {isModifier && (
                            <div className="mt--1">
                                <Link
                                    className="Button mr--1"
                                    to={`/service-configurator/brands/${brand.Id}/edit`}
                                    title={`Edit ${brand.Name}`}
                                >
                                    Edit
                                </Link>
                                <Button
                                    onClick={() => onDelete(brand.Id)}
                                    title={`Delete ${brand.Name}`}
                                    variant="secondary"
                                >
                                    Delete
                                </Button>
                            </div>
                        )}
                    </Card>
                ))}
            </TwoColumnLayout>
        </React.Fragment>
    );
};

export const BrandsList = withBrands()(__BrandsList);
