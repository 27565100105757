import { UserManager, UserManagerSettings } from 'oidc-client';

export interface OidcSettings {
    clientId: string;
    scope: string;
    authority: string;
    loginRedirectUrl?: string;
    logoutRedirectUrl?: string;
    silentRedirectUrl?: string;
}

export const createController = (oidcSettings: OidcSettings) => {
    const userManagerConfig: UserManagerSettings = {
        client_id: oidcSettings.clientId,
        redirect_uri:
            window.location.origin +
            (oidcSettings.loginRedirectUrl || '/complete-login'),
        post_logout_redirect_uri:
            window.location.origin +
            (oidcSettings.logoutRedirectUrl || '/complete-logout'),
        silent_redirect_uri:
            window.location.origin +
            (oidcSettings.silentRedirectUrl || '/complete-refresh'),
        response_type: 'code',
        scope: oidcSettings.scope,
        authority: oidcSettings.authority,
        automaticSilentRenew: true,
        filterProtocolClaims: true,
        loadUserInfo: true,
        monitorSession: true,
    };

    const userManager = new UserManager(userManagerConfig);

    return userManager;
};
