import { produce } from 'immer';
import React, { createContext, FunctionComponent, ReactNode } from 'react';
import { RoleRequest, RoleResponse } from '../../../api-types/user-manager';
import config from '../../../config';
import {
    PaginatedApiState,
    paginatedApiStateDefaults,
    usePaginatedApi,
} from '../../../hooks/usePaginatedApi';
import { createProviderHoC } from '../../../util/createProviderHoC';

interface RolesProviderProps {
    children?: ReactNode;
    runInitialFetch?: boolean;
    limit?: number;
    includeAdmin?: boolean;
}

type RolesContextState = PaginatedApiState<RoleResponse, RoleRequest>;

const defaults = {
    orderingBy: 'Name' as keyof RoleResponse,
};
const RolesContext = createContext<RolesContextState>(
    produce(paginatedApiStateDefaults, draft => {
        draft.orderByProps.orderingBy = defaults.orderingBy;
    })
);

const RolesProvider: FunctionComponent<RolesProviderProps> = ({
    children,
    runInitialFetch = true,
    limit = 10,
    includeAdmin = false,
}) => {
    const { data, ...paginatedApiState } = usePaginatedApi<
        RoleResponse,
        RoleRequest
    >({
        baseUrl: config.USER_MANAGER_API_BASE_URL,
        url: '/roles',
        runInitialFetch,
        defaults: { ...defaults, limit },
    });

    return (
        <RolesContext.Provider
            value={{
                // Remove roles with "hidden" Ids (e.g. Admin)
                data: includeAdmin ? data : data.filter(role => role.Id > 0),
                ...paginatedApiState,
            }}
        >
            {children}
        </RolesContext.Provider>
    );
};

const withRoles = createProviderHoC(RolesProvider);

export { RolesContext, withRoles };
export default RolesProvider;
