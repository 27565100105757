import React, { FunctionComponent, useContext } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import {
    Button,
    Heading,
    Switch,
} from '@oetkerdigital/eden-design-system-react';
import { RoleResponse } from '../../../api-types/user-manager';
import { truncate } from '../../user-manager/util';
import { provide } from '../../../util/provide';
import { useHasPermission } from '../../../hooks/useHasPermission';
import { useAlerts } from '../../../components/AlertProvider';
import { SortableTableHeadCell } from '../../../components/SortableTableHeadCell';
import { RolesContext, withRoles } from './RolesProvider';

const getPermissionsText = (role: RoleResponse) =>
    role.Permissions.map(p => p.Scope).join(', ');

const __RolesList: FunctionComponent<RouteComponentProps> = () => {
    const {
        data: roles,
        refetch,
        delete: deleteRole,
        isLoading,
        limitProps,
        orderByProps,
    } = useContext(RolesContext);

    const alerts = useAlerts();

    const onDelete = (id: number) => {
        const role = roles.find(r => r.Id === id)!;

        alerts.alert({
            message: `Really delete role "${role.Name}"?`,
            content: (
                <React.Fragment>
                    {role.UserCount > 0 && (
                        <p>
                            This role is currently assigned to {role.UserCount}{' '}
                            users.
                        </p>
                    )}

                    <p>This action cannot be undone.</p>

                    <Button
                        onClick={() => onDeleteConfirm(id)}
                        title={`Confirm that role ${role.Name} will be deleted`}
                        className="mr--1"
                    >
                        Yes, delete
                    </Button>

                    <Button
                        variant="secondary"
                        onClick={() => alerts.remove()}
                        title={`Abort deleting role ${role.Name}`}
                    >
                        Cancel
                    </Button>
                </React.Fragment>
            ),
        });
    };

    const onDeleteConfirm = async (id: number) => {
        const [error] = await deleteRole(id);

        if (error) {
            // TODO: Handle error
            return;
        }

        alerts.success('The role has been deleted successfully.');
        refetch();
    };

    const isModifier = useHasPermission('user-manager:roles:modify');
    const seeDetails = useHasPermission('user-manager:roles:details');

    return (
        <React.Fragment>
            <Heading>Role Management</Heading>
            <p>
                Manage user roles to assign them to users within the user
                manager.
            </p>

            {isModifier && (
                <Link className="Button" to="/system-configurator/roles/new">
                    Create new role
                </Link>
            )}

            <table className="Table mt--1">
                <thead>
                    <tr>
                        <SortableTableHeadCell sortKey="Name" {...orderByProps}>
                            Name
                        </SortableTableHeadCell>

                        <SortableTableHeadCell
                            sortKey="Description"
                            {...orderByProps}
                        >
                            Description
                        </SortableTableHeadCell>

                        <SortableTableHeadCell
                            sortKey="UserCount"
                            {...orderByProps}
                        >
                            Amount of users
                        </SortableTableHeadCell>

                        <th>Permissions</th>

                        {isModifier && <th>Operations</th>}
                    </tr>
                </thead>
                <tbody>
                    {!!roles.length &&
                        roles.map(role => (
                            <tr key={`role-${role.Id}`}>
                                <td>
                                    {!seeDetails && role.Name}
                                    {seeDetails && (
                                        <Link
                                            className="Link"
                                            title={`View details for ${role.Name}`}
                                            to={`/system-configurator/roles/${role.Id}`}
                                        >
                                            {role.Name}
                                        </Link>
                                    )}
                                </td>
                                <td>{role.Description}</td>
                                <td>{role.UserCount}</td>
                                <td title={getPermissionsText(role)}>
                                    {truncate(getPermissionsText(role), 40)}
                                </td>
                                {isModifier && (
                                    <td>
                                        <Link
                                            className="Button"
                                            to={`/system-configurator/roles/${role.Id}/edit`}
                                            title={`Edit ${role.Name}`}
                                        >
                                            Edit
                                        </Link>
                                        <Button
                                            onClick={() => onDelete(role.Id)}
                                            title={`Delete ${role.Name}`}
                                            variant="secondary"
                                        >
                                            Delete
                                        </Button>
                                    </td>
                                )}
                            </tr>
                        ))}

                    {!roles.length && !isLoading && (
                        <tr>
                            <td colSpan={3}>No results</td>
                        </tr>
                    )}

                    {!roles.length && !isLoading && (
                        <tr>
                            <td colSpan={3}>Loading...</td>
                        </tr>
                    )}
                </tbody>
            </table>

            <Switch<number>
                className="mt--1"
                items={[
                    { value: 10, label: '10' },
                    { value: 50, label: '50' },
                    { value: 100, label: '100' },
                    { value: 500, label: '500' },
                ]}
                value={limitProps.limit}
                onValueChange={limitProps.limitTo}
                aria-label="Limit results to"
            />
        </React.Fragment>
    );
};

export const RolesList = provide(__RolesList, withRoles());
