import React, {
    FormEvent,
    FunctionComponent,
    ReactNode,
    useContext,
    useState,
    useEffect,
} from 'react';
import {
    Button,
    Heading,
    Textarea,
} from '@oetkerdigital/eden-design-system-react';
import CountrySelector from '../CountrySelector';
import { useHasPermission } from '../../../hooks/useHasPermission';
import { useAlerts } from '../../../components/AlertProvider';
import { WordsContext } from './WordsProvider';

interface WordsTemplateProps {
    children: ReactNode;
    label: string;
}

export const WordsTemplate: FunctionComponent<WordsTemplateProps> = ({
    children,
    label,
}) => {
    const {
        value: words,
        isLoading,
        isSaving,
        save,
    } = useContext(WordsContext);
    const hasWritePermission = useHasPermission('oss:modify');

    const alerts = useAlerts();

    const [wordsValue, setWordsValue] = useState(words);

    const canSave = wordsValue !== words;

    useEffect(() => {
        setWordsValue(words);
    }, [words]);

    const onSave = async (e: FormEvent) => {
        e.preventDefault();

        if (!canSave) {
            return;
        }

        // There's no reason to validate the words on the FE
        // - the API will do that and return a proper error message, that is
        // already handled and displayed correctly through our error handling
        const success = await save(wordsValue);

        if (success) {
            alerts.success(
                `The ${label.toLowerCase()} have been successfully updated`
            );
            return;
        }

        alerts.error(`The ${label.toLowerCase()} could not be updated`);
    };

    return (
        <React.Fragment>
            {children}

            <CountrySelector />

            <Heading as="h2" size={4}>
                Sets
            </Heading>

            <form className="ContentLayout" onSubmit={onSave}>
                <Textarea
                    tooltipMessage="To edit the text field, please click on the pencil icon in the upper right corner"
                    rows={30}
                    label={label}
                    disabled={!hasWritePermission}
                    onValueChange={val => setWordsValue(val)}
                    value={wordsValue}
                />

                {hasWritePermission && (
                    <Button disabled={!canSave || isSaving}>
                        {isSaving ? 'Saving...' : 'Save'}
                    </Button>
                )}
            </form>

            {isLoading && <p>Fetching {label.toLowerCase()}...</p>}
        </React.Fragment>
    );
};
