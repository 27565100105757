import React, { FunctionComponent, createContext, useContext } from 'react';
import useSettingsApi, { SettingsApiState } from '../useSettingsApi';
import { createProviderHoC } from '../../../util/createProviderHoC';
import { useErrorAlert } from '../../../components/AlertProvider';
import { identity } from '../util';

type DataType = string[];

type IndexedHtmlTagsProviderState = SettingsApiState<DataType>;

const IndexedHtmlTagsContext = createContext<
    IndexedHtmlTagsProviderState | undefined
>(undefined);

const IndexedHtmlTagsProvider: FunctionComponent = ({ children }) => {
    const settingsApi = useSettingsApi<DataType, DataType>({
        url: '/indexedHtmlTags',
        toData: identity,
        toDisplay: identity,
        defaultData: [],
    });

    useErrorAlert(settingsApi.fetchError);
    useErrorAlert(settingsApi.saveError);

    return (
        <IndexedHtmlTagsContext.Provider value={settingsApi}>
            {children}
        </IndexedHtmlTagsContext.Provider>
    );
};

export const withIndexedHtmlTags = createProviderHoC(IndexedHtmlTagsProvider);

export const useIndexedHtmlTags = () => {
    const context = useContext(IndexedHtmlTagsContext);

    /* istanbul ignore if */
    if (!context) {
        throw new Error(
            '`useIndexedHtmlTags` must be used within a `IndexedHtmlTagsProvider`!'
        );
    }

    return context;
};

/**
 * Convert to a simple string array with `html_tags.` prefix
 */
export const useTextIndexedHtmlTags = () => {
    const allIndexedHtmlTags = useIndexedHtmlTags();

    return allIndexedHtmlTags.value.map(tag => `html_tags.${tag}`);
};
