import React, { FunctionComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
    Heading,
    TwoColumnLayout,
    ContentLayout,
} from '@oetkerdigital/eden-design-system-react';
import {
    textToWordArray,
    textToWordArrays,
    wordArraysToText,
    wordArrayToText,
} from '../util';
import { withWords } from './WordsProvider';
import { WordsTemplate } from './WordsTemplate';

const NegationWords: FunctionComponent<RouteComponentProps> = () => {
    return (
        <WordsTemplate label="Negation Words">
            <Heading>Manage your Negation Words</Heading>
            <p>
                A negation word (such as "without") is commonly used to filter
                out specific terms from the search results. For example when a
                user searches for "cake without chocolate" and "without" was
                added as negation word, all recipes with chocolate will be
                filtered out in the results. Each new row indicates a new
                negation word.
            </p>
        </WordsTemplate>
    );
};

const ConnectedNegationWords = withWords({
    url: '/negationWords',
    toText: wordArrayToText,
    toData: textToWordArray,
})(NegationWords);

const StopWords: FunctionComponent<RouteComponentProps> = () => {
    return (
        <WordsTemplate label="Stop Words">
            <Heading>Manage your Stop Words</Heading>
            <p>
                A stop word is a commonly used word (such as “the”, “a”, “an”,
                “in”) which adds no meaning to a search request. They can be
                ignored safely without sacrificing the quality of results. By
                adding stop words, they will be ignored within the search and
                will not deliver any results. Each new row indicates a new stop
                word. By default we have added the most common stopwords for
                each language.
            </p>
        </WordsTemplate>
    );
};

const ConnectedStopWords = withWords({
    url: '/stopWords',
    toText: wordArrayToText,
    toData: textToWordArray,
})(StopWords);

const Synonyms: FunctionComponent<RouteComponentProps> = () => {
    return (
        <WordsTemplate label="Synonyms">
            <Heading>Manage your synonyms</Heading>
            <p>
                Creating synonyms for selected keywords increases the
                possibility that your onsite search users will get a relevant
                search result. For instance, you might have users searching for
                “muffin”, but the correct term in your business is “cupcake”.
                Setting up synonyms helps to overcome differences in vocabulary,
                and helps your users find the right information quickly. Each
                line is a new set of synonyms. You can define synonyms in two
                different ways:
            </p>

            <TwoColumnLayout breakpoint="lg">
                <ContentLayout>
                    <Heading as="h4" className="mt--1">
                        Bi-directional mapping
                    </Heading>

                    <p>
                        For words that are fully equivalent (e.g. typos, words
                        used interchangeably), you can separate words by comma (
                        <kbd>,</kbd>).
                    </p>

                    <p>
                        Examples: <br />
                        <kbd>cupcake, muffin</kbd>
                        <br />
                        <kbd>pasta, noodles</kbd>
                        <br />
                    </p>
                </ContentLayout>

                <ContentLayout>
                    <Heading as="h4" className="mt--1">
                        One directional mapping
                    </Heading>

                    <p>
                        For words that are not fully equivalent (e.g. for words
                        that describe a group of products), you can separate
                        words by arrow (<kbd>={'>'}</kbd>).
                    </p>

                    <p>
                        Examples: <br />
                        <kbd>beef, pork, chicken ={'>'} meat</kbd>
                        <br />
                        <kbd>spaghetti, penne ={'>'} pasta</kbd>
                        <br />
                    </p>
                </ContentLayout>
            </TwoColumnLayout>
        </WordsTemplate>
    );
};

const ConnectedSynonyms = withWords({
    url: '/synonyms ',
    toText: wordArraysToText,
    toData: textToWordArrays,
})(Synonyms);

export {
    ConnectedNegationWords as NegationWords,
    ConnectedStopWords as StopWords,
    ConnectedSynonyms as Synonyms,
};
