import {
    Button,
    Dropdown,
    Heading,
    Icon,
    ReorderList,
    ContentLayout,
} from '@oetkerdigital/eden-design-system-react';
import { produce } from 'immer';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useAlerts } from '../../../components/AlertProvider';
import { provide } from '../../../util/provide';
import CountrySelector from '../CountrySelector';
import { withSettings } from '../SettingsProvider';
import SuggestionContextRow from './SuggestionContextRow';
import {
    useTextIndexedHtmlTags,
    withIndexedHtmlTags,
} from './IndexedHtmlTagsProvider';
import {
    useTextIndexedMetaTags,
    withIndexedMetaTags,
} from './IndexedMetaTagsProvider';
import {
    useSuggestionSettings,
    withSuggestionSettings,
} from './SuggestionSettingsProvider';
import useAggregatedTagValues from './useAggregatedTagValues';
import {
    useWebsiteSettings,
    withWebsiteSettings,
} from './WebsiteSettingsProvider';

const toWithoutWeight = (value: string) => value.split('^')[0];

const toOption = (value: string) => ({
    label: value,
    value,
});

function byUnique<T>(value: T, index: number, array: T[]) {
    return array.indexOf(value) === index;
}

const __Admin: FunctionComponent<RouteComponentProps> = () => {
    const alerts = useAlerts();

    const { value: websiteSettings } = useWebsiteSettings();
    const htmlTags = useTextIndexedHtmlTags();
    const metaTags = useTextIndexedMetaTags();
    const { value: suggestionsFromApi, save } = useSuggestionSettings();

    // Track suggestions in a copy which is updated when the API state changes
    const [suggestions, setSuggestions] = useState(suggestionsFromApi);
    useEffect(() => {
        setSuggestions(suggestionsFromApi);
    }, [suggestionsFromApi]);

    const suggestionFieldOptions = websiteSettings
        ? websiteSettings.SearchFields.map(toWithoutWeight).map(toOption)
        : [];

    const allIndexedTags = [...htmlTags, ...metaTags];

    const indexedTagsUsedInContexts = suggestions
        // Get selected values of contexts
        .map(suggestion => Object.keys(suggestion.contexts))
        // Merge them together
        .flat()
        // Only keep one of each
        .filter(byUnique);

    const availableValuesForIndexedTags = useAggregatedTagValues(
        indexedTagsUsedInContexts
    );

    /**
     * Add a new row with empty tag value
     */
    const onAdd = () => {
        setSuggestions(
            produce(suggestions, draft => {
                draft.push({ tag: '', contexts: {} });
            })
        );
    };

    /**
     * Update the main tag value of a row
     */
    const onRowUpdate = (rowIndex: number) => (tag: string) => {
        setSuggestions(
            produce(suggestions, draft => {
                draft[rowIndex].tag = tag;
            })
        );
    };

    /**
     * Add a new context to a given row
     */
    const onContextAdd = (rowIndex: number, tag: string) => () => {
        setSuggestions(
            produce(suggestions, draft => {
                draft[rowIndex].contexts[tag] = [];
            })
        );
    };

    /**
     * Remove a context from a given row
     */
    const onContextRemove = (rowIndex: number, tag: string) => () => {
        setSuggestions(
            produce(suggestions, draft => {
                delete draft[rowIndex].contexts[tag];
            })
        );
    };

    /**
     * Update the main tag for a context for a given row
     */
    const onContextUpdate =
        (rowIndex: number, previousTag: string) => (tag: string) => {
            if (previousTag === tag) {
                return;
            }

            setSuggestions(
                produce(suggestions, draft => {
                    draft[rowIndex].contexts[tag] = [];
                    delete draft[rowIndex].contexts[previousTag];
                })
            );
        };

    /**
     * Add a new value for a context for a given row
     */
    const onContextValueAdd =
        (rowIndex: number, tag: string) => (value: string) => () => {
            setSuggestions(
                produce(suggestions, draft => {
                    draft[rowIndex].contexts[tag].push({
                        Context: value,
                    });
                })
            );
        };

    /**
     * Remove a value by index for a context for a given row
     */
    const onContextValueRemove =
        (rowIndex: number, tag: string) => (valueIndex: number) => () => {
            setSuggestions(
                produce(suggestions, draft => {
                    draft[rowIndex].contexts[tag].splice(valueIndex, 1);
                })
            );
        };

    /**
     * Update a tag value for a given context, by row index
     */
    const onContextValueUpdate =
        (rowIndex: number, tag: string) =>
        (valueIndex: number) =>
        (value: string) => {
            setSuggestions(
                produce(suggestions, draft => {
                    draft[rowIndex].contexts[tag][valueIndex] = {
                        Context: value,
                    };
                })
            );
        };

    /**
     * Validate and save suggestion settings
     */
    const onSave = async () => {
        alerts.alert({
            message: 'Saving your settings...',
            content: 'This might take a while, please stand by.',
        });

        if (await save(suggestions)) {
            alerts.success(
                'The suggestion settings have been updated successfully.'
            );
        }
    };

    return (
        <React.Fragment>
            <Heading>Suggestion settings</Heading>

            <p>
                Adjust settings for the auto-suggestion feature. Hands-off if
                you don't know what you're doing!
            </p>

            <CountrySelector />

            <ReorderList items={suggestions} onReorder={setSuggestions}>
                {(suggestion, { index }) => {
                    // It can happen that an option that was previously available
                    // here does not exist anymore, in which case the `Dropdown`
                    // component would error, so we pass `null` if that happens.
                    const valueExistsInDropdown =
                        suggestionFieldOptions
                            .map(o => o.value)
                            .indexOf(suggestion.tag) > -1;

                    // Since the context dropdown cannot be empty, we need to
                    // figure out here first if we still have available values
                    // and otherwise hide the button to add a new one
                    const usedTagsInThisRow = Object.keys(suggestion.contexts);
                    const firstUnusedTagInThisRow = allIndexedTags.find(
                        tag => !usedTagsInThisRow.includes(tag)
                    );

                    return (
                        <ContentLayout className="mt--1 mb--1">
                            <Dropdown
                                label="Tag Name"
                                options={suggestionFieldOptions}
                                value={
                                    valueExistsInDropdown
                                        ? suggestion.tag
                                        : null
                                }
                                onValueChange={onRowUpdate(index)}
                            />
                            <Heading as="h2" size={6}>
                                Contexts
                            </Heading>

                            {Object.keys(suggestion.contexts).map(
                                contextKey => (
                                    <SuggestionContextRow
                                        key={contextKey}
                                        context={contextKey}
                                        contextOptions={allIndexedTags}
                                        onUpdate={onContextUpdate(
                                            index,
                                            contextKey
                                        )}
                                        onRemove={onContextRemove(
                                            index,
                                            contextKey
                                        )}
                                        values={suggestion.contexts[contextKey]}
                                        valueOptions={
                                            availableValuesForIndexedTags[
                                                contextKey
                                            ] || []
                                        }
                                        onValueAdd={onContextValueAdd(
                                            index,
                                            contextKey
                                        )}
                                        onValueUpdate={onContextValueUpdate(
                                            index,
                                            contextKey
                                        )}
                                        onValueRemove={onContextValueRemove(
                                            index,
                                            contextKey
                                        )}
                                    />
                                )
                            )}

                            {firstUnusedTagInThisRow && (
                                <Icon
                                    aria-label="Add context"
                                    name="add"
                                    button
                                    onClick={onContextAdd(
                                        index,
                                        firstUnusedTagInThisRow
                                    )}
                                />
                            )}
                        </ContentLayout>
                    );
                }}
            </ReorderList>

            <Button onClick={onAdd}>Add tag</Button>

            <Button
                onClick={onSave}
                disabled={suggestions === suggestionsFromApi}
            >
                Save
            </Button>
        </React.Fragment>
    );
};

export const Admin = provide(
    __Admin,
    withWebsiteSettings(),
    withIndexedMetaTags(),
    withIndexedHtmlTags(),
    withSuggestionSettings(),
    withSettings()
);
