import { createUserPermissionValidator } from '../../util/createUserPermissionValidator';
import { ForgotPassword } from './password-reset/ForgotPassword';
import { ResetPassword } from './password-reset/ResetPassword';
import { Home } from './system/Home';
import { UserProfile } from './system/UserProfile';

const routes = [
    {
        path: '/',
        component: Home,
        label: 'Home',
    },
    {
        path: '/forgot-password',
        label: 'Forgot Password',
        component: ForgotPassword,
        public: true,
        noLayout: true,
        hideInMenu: true,
    },
    {
        path: '/reset-password',
        label: 'Reset Password',
        component: ResetPassword,
        public: true,
        noLayout: true,
        hideInMenu: true,
    },
    {
        path: `/user-profile`,
        label: 'User Profile',
        component: UserProfile,
        hideInMenu: true,
    },
    {
        label: 'Adam',
        path: 'https://adam.oetkerdigital.io',
        externalLink: true,
        validateUser: createUserPermissionValidator([
            'adam:read',
            'adam:write',
        ]),
    },
];

export default routes;
