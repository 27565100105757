import React, { FunctionComponent, createContext, useContext } from 'react';
import useSettingsApi, { SettingsApiState } from '../useSettingsApi';
import { createProviderHoC } from '../../../util/createProviderHoC';
import { useErrorAlert } from '../../../components/AlertProvider';
import { WebsiteMetaTagResponse } from '../../../api-types/search';
import { identity } from '../util';

type DataType = WebsiteMetaTagResponse[];

type IndexedMetaTagsProviderState = SettingsApiState<DataType>;

const IndexedMetaTagsContext = createContext<
    IndexedMetaTagsProviderState | undefined
>(undefined);

const IndexedMetaTagsProvider: FunctionComponent = ({ children }) => {
    const settingsApi = useSettingsApi<DataType, DataType>({
        url: '/indexedMetaTags',
        toData: identity,
        toDisplay: identity,
        defaultData: [],
    });

    useErrorAlert(settingsApi.fetchError);
    useErrorAlert(settingsApi.saveError);

    return (
        <IndexedMetaTagsContext.Provider value={settingsApi}>
            {children}
        </IndexedMetaTagsContext.Provider>
    );
};

export const withIndexedMetaTags = createProviderHoC(IndexedMetaTagsProvider);

export const useIndexedMetaTags = () => {
    const context = useContext(IndexedMetaTagsContext);

    /* istanbul ignore if */
    if (!context) {
        throw new Error(
            '`useIndexedMetaTags` must be used within a `IndexedMetaTagsProvider`!'
        );
    }

    return context;
};

/**
 * Get only the text-y meta tags, and convert them to a simple string array
 * with `meta_tags.` prefix
 */
export const useTextIndexedMetaTags = () => {
    const allIndexedMetaTags = useIndexedMetaTags();

    return allIndexedMetaTags.value
        .filter(tag => tag.Type === 'text')
        .map(tag => `meta_tags.${tag.Name.toLowerCase()}`);
};
