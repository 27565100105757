import React, {
    createContext,
    FunctionComponent,
    useContext,
    useEffect,
    useState,
} from 'react';
import { AuthContext } from '../../components/AuthGate';
import config from '../../config';
import { createProviderHoC } from '../../util/createProviderHoC';
import { ApiRequestResult, createRequest } from '../../util/createRequest';

// Collection of functions to generate human readable strings from the password
// requirements from the API.
const passwordRequirementsMap = {
    MinimumLength: (val: number) => `a minimum length of ${val}`,
    RequireCaseDifference: (val: boolean) =>
        val ? 'at least one lower and upper case letter' : '',
    RequireSpecialChar: (val: boolean) =>
        val ? 'at least one special character' : '',
    RequireNumber: (val: boolean) => (val ? 'at least one number' : ''),
};

// Since this doesn't depend on any authentication, don't use api from AuthContext
const request = createRequest({
    defaultBaseUrl: config.USER_MANAGER_API_BASE_URL,
});

type PasswordSettingsContextState = {
    passwordRequirements: string[];
    validatePassword: (password: string) => Promise<ApiRequestResult>;
};

const defaults: PasswordSettingsContextState = {
    passwordRequirements: [],

    validatePassword: /* istanbul ignore next - unused default case */ () =>
        Promise.resolve([null, null]),
};

const cache: Pick<PasswordSettingsContextState, 'passwordRequirements'> = {
    passwordRequirements: defaults.passwordRequirements,
};

/**
 * Reset the cache to the defaults - for testing purposes
 */
export const resetPasswordSettingsCache = () => {
    cache.passwordRequirements = defaults.passwordRequirements;
};

const PasswordSettingsContext =
    createContext<PasswordSettingsContextState>(defaults);

/**
 * A provider to fetch the requirements for passwords and validate passwords.
 *
 * Wrap this around views that might require the user to enter a new password.
 * The last fetched password requirements will be cached, since they are not
 * expected to change all that often.
 */
const PasswordSettingsProvider: FunctionComponent = ({ children }) => {
    const [requirements, setRequirements] = useState(
        cache.passwordRequirements
    );

    const { api } = useContext(AuthContext);

    useEffect(() => {
        let isCancelled = false;

        /**
         * Fetch password settings from the API. They will look like this:
         * ```
         * {
         *     MinimumLength: 8,
         *     RequireCaseDifference: true,
         *     ...
         * }
         * ```
         * So we transform the using the map defined above
         */
        const load = async () => {
            const [error, data] = await request({
                url: '/accounts/password/settings',
            });

            /* istanbul ignore if */
            if (isCancelled) {
                return;
            }

            if (error || !data) {
                return;
            }

            // Map requirements by key
            const mappedRequirements = Object.keys(data)
                // Call the functions defined above by key with the value to generate strings
                .map(key => passwordRequirementsMap[key](data[key]))
                // If booleans are false they will return empty string, filter those out
                .filter(s => !!s);

            setRequirements(mappedRequirements);
        };

        if (!requirements.length) {
            load();
        }

        return () => {
            isCancelled = true;
        };
    }, [api, requirements]);

    // Whenever the requirements change, update the cache
    useEffect(() => {
        cache.passwordRequirements = requirements;
    }, [requirements]);

    /**
     * Validate a given password with the API
     */
    const validatePassword = async (password: string) => {
        const requestResult = await request({
            url: `/accounts/password/test?password=${password}`,
            method: 'POST',
        });

        return requestResult;
    };

    return (
        <PasswordSettingsContext.Provider
            value={{ passwordRequirements: requirements, validatePassword }}
        >
            {children}
        </PasswordSettingsContext.Provider>
    );
};

const withPasswordSettings = createProviderHoC(PasswordSettingsProvider);

export { PasswordSettingsContext, withPasswordSettings };
export default PasswordSettingsProvider;
