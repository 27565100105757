import {
    Dropdown,
    DropdownItem,
    DropdownValue,
    TwoColumnLayout,
} from '@oetkerdigital/eden-design-system-react';
import React, { FunctionComponent } from 'react';
import { useSettings } from './SettingsProvider';

/**
 * Sort the options alphabetically by label
 * @param a
 * @param b
 */
const alphabetically = (
    a: DropdownItem<DropdownValue>,
    b: DropdownItem<DropdownValue>
) =>
    a.label > b.label ? 1 /* istanbul ignore next - unused default case */ : -1;

/**
 * # CountrySelector
 *
 * Component to select the country and language on pages based on those (e.g. search).
 * Using this component requires the containing page to use the SettingsProvider,
 * otherwise this will not work (obviously). The country selector will then automatically
 * pick up the loaded indices.
 */
const CountrySelector: FunctionComponent = () => {
    const { searchIndices, searchIndex, setSearchIndex, formatSearchIndex } =
        useSettings();

    const selectOptions = searchIndices.map(index => ({
        value: index,
        label: formatSearchIndex(index),
    }));

    return (
        <TwoColumnLayout>
            <Dropdown
                className="mt--1 mb--1"
                label="Country and language"
                options={selectOptions.sort(alphabetically)}
                onValueChange={setSearchIndex}
                value={searchIndex}
            />
        </TwoColumnLayout>
    );
};

export default CountrySelector;
