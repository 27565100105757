import {
    DefaultLayout as BaseDefaultLayout,
    Header,
    Sidebar,
    UserMenu,
    useSidebar,
} from '@oetkerdigital/eden-design-system-react';
import React, {
    FunctionComponent,
    useContext,
    useEffect,
    useState,
} from 'react';
import { Link } from 'react-router-dom';
import { User } from '../../auth';
import { AuthContext } from '../../components/AuthGate';
import { RoutingRoute } from '../../components/Routing';
import { useToggle } from '../../hooks/useToggle';
import routes from '../../routes';

export const byValid = (user: User | null) => (route: RoutingRoute) =>
    !!user && (!route.validateUser || route.validateUser(user));

const rootMenuTitles = routes
    .map(item => item.label)
    .filter(title => title !== 'Home');

export const DefaultLayout: FunctionComponent = ({ children }) => {
    const [menuTitle, setMenuTitle] = useState('');
    const [{ activeMenuItem }] = useSidebar();
    const { onLogout, user } = useContext(AuthContext);
    const [isLightMode, toggleLightMode] = useToggle(false, 'isLightMode');

    useEffect(() => {
        if (
            rootMenuTitles.includes(activeMenuItem) ||
            activeMenuItem.length === 0
        ) {
            setMenuTitle(activeMenuItem);
        }
    }, [activeMenuItem]);

    useEffect(() => {
        document.documentElement.classList[isLightMode ? 'add' : 'remove'](
            'Mode--light'
        );
    }, [isLightMode]);

    // TODO: Add possibility to handle initial route/menu item in SideBar component
    return (
        <BaseDefaultLayout
            header={
                <Header heading={menuTitle}>
                    {/*TODO: just show first name and last name for label*/}
                    <UserMenu label={(user && user.profile.name) || ''}>
                        <Link to="/user-profile" className="UserMenu__link">
                            Profile
                        </Link>
                        <div
                            onClick={toggleLightMode}
                            role="button"
                            aria-label="Toggle Mode"
                        >
                            Change to {isLightMode ? 'dark' : 'light'} mode
                        </div>
                        <div onClick={onLogout} role="button">
                            Logout
                        </div>
                    </UserMenu>
                </Header>
            }
            sidebar={<Sidebar />}
        >
            {children}
        </BaseDefaultLayout>
    );
};
