import React, {
    FormEvent,
    FunctionComponent,
    useContext,
    useEffect,
    useState,
} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
    Heading,
    Button,
    Textfield,
    Checkbox,
} from '@oetkerdigital/eden-design-system-react';
import { provide } from '../../../util/provide';
import {
    PermissionsContext,
    withPermissions,
} from '../permissions/PermissionsProvider';
import { useAlerts } from '../../../components/AlertProvider';
import { RolesContext, withRoles } from './RolesProvider';

interface RouteParams {
    roleId: string;
}

const __RolesForm: FunctionComponent<RouteComponentProps<RouteParams>> = ({
    history,
    match,
}) => {
    const roleId = match.params.roleId;

    const isUpdating = !!roleId;

    const { data: permissions } = useContext(PermissionsContext);
    const {
        create: createRole,
        update: updateRole,
        fetchOne: fetchRole,
    } = useContext(RolesContext);

    const [nameValue, setNameValue] = useState('');
    const [descriptionValue, setDescriptionValue] = useState('');
    const [permissionIds, setPermissionIds] = useState<number[]>([]);

    const alerts = useAlerts();

    useEffect(() => {
        // Load role data only if roleId is present in url
        // Also wait for permissions to load first, so we can check/uncheck checkboxes
        if (!roleId || !permissions || !permissions.length) {
            return;
        }

        const fetch = async () => {
            const [error, data] = await fetchRole(roleId);

            if (error) {
                // TODO: handle error
                return;
            }

            setNameValue(data.Name);
            setDescriptionValue(data.Description);
            setPermissionIds(data.Permissions.map(p => p.Id));
        };

        fetch();

        // We do not want to re-run this when the name or description field change!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roleId, permissions]);

    const onCheckboxChange = (isChecked: boolean, permissionId: number) => {
        if (isChecked) {
            setPermissionIds([...permissionIds, permissionId]);
        } else {
            setPermissionIds(permissionIds.filter(id => id !== permissionId));
        }
    };

    const isValid = !!nameValue;

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();

        // Since we're not sending the SeqNr, it will be set to 0.
        // Since we're not using any custom ordering, that's fine for now.
        const body = {
            Name: nameValue,
            Description: descriptionValue,
            PermissionIds: permissionIds,
        };

        const [error] = isUpdating
            ? await updateRole(roleId, body)
            : await createRole(body);

        if (error) {
            // TODO: handle errors
            return;
        }

        alerts.success('The role has been saved successfully.');
        history.push('/system-configurator/roles');
    };

    return (
        <React.Fragment>
            {isUpdating ? (
                <React.Fragment>
                    <Heading>Edit role: {nameValue}</Heading>
                    <p>
                        Please update the data for the role. Please note that
                        the name field is mandatory.
                    </p>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Heading>Create a new role</Heading>
                    <p>
                        Please enter the data for the new role. Please note that
                        the name field is mandatory.
                    </p>
                </React.Fragment>
            )}

            <form className="ContentLayout" onSubmit={onSubmit}>
                <Textfield
                    label="Name"
                    value={nameValue}
                    onValueChange={setNameValue}
                    required
                />

                <Textfield
                    label="Description"
                    value={descriptionValue}
                    onValueChange={setDescriptionValue}
                />

                <Heading as="h2" className="mt--1">
                    Permissions
                </Heading>
                <table className="Table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {permissions.map(permission => (
                            <tr key={`permission-${permission.Id}`}>
                                <td>
                                    <Checkbox
                                        label={permission.Scope}
                                        value={permission.Id}
                                        checked={permissionIds.includes(
                                            permission.Id
                                        )}
                                        onValueChange={isChecked =>
                                            onCheckboxChange(
                                                isChecked,
                                                permission.Id
                                            )
                                        }
                                    />
                                </td>
                                <td>{permission.Description}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <Button disabled={!isValid}>Save</Button>
            </form>
        </React.Fragment>
    );
};

export const RolesForm = provide(
    __RolesForm,
    withRoles({ runInitialFetch: false }),
    withPermissions()
);
