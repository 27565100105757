import React, { FunctionComponent, useEffect, useState } from 'react';
import {
    Dropdown,
    TwoColumnLayout,
} from '@oetkerdigital/eden-design-system-react';
import { Tag, RANGE_VALUES_MAP } from './RankResultsProvider';

export interface RankingRowThemeAttributes {}

export interface RankingRowProps {
    /**
     * Name prefix for all selectors
     */
    name: string;
    /**
     * Array of tags to select in tag selector
     * @default []
     */
    tags: Tag[];
    /**
     * Preselected tagValues
     */
    tagValues: string[];
    /**
     * Already selected tagValues.
     * These values are tag values which where already selected by user
     * to prevent the user to choose same values over and over again
     */
    usedValues: string[];
    /**
     * Preselected tag
     */
    tag?: Tag;
    /**
     * Preselected value for preselected tag
     */
    value?: string;
    /**
     * Callback on change for tag selector
     */
    onTagChange?: (event: any) => void;
    /**
     * Callback on change for value selector
     */
    onValueChange?: (event: any) => void;

    /**
     * If tag is date tag
     */
    isDateTag?: boolean;
}

const getTags = (tags: Tag[]) => {
    return tags.map(tag => {
        return {
            value: tag.Name,
            label: tag.Name,
        };
    });
};

/**
 * @description Returns all values for specified tag.
 * If there are tagValues means that updated tag values should be shown so in this case these updated
 * values would be returned
 */
const getTagValues = (
    tagValues: string[] = [],
    usedValues: string[] = [],
    isDate?: boolean
) => {
    if (tagValues.length) {
        return tagValues.map(value => ({
            value,
            label: isDate ? RANGE_VALUES_MAP[value] : value,
        }));
    }

    return [];
};
/**
 * ## RankingRow
 *
 *
 */
export const RankingRow: FunctionComponent<RankingRowProps> = props => {
    const {
        tags,
        tagValues,
        usedValues,
        onTagChange,
        onValueChange,
        tag,
        value = '',
        isDateTag,
    } = props;

    const parsedTags = getTags(tags);

    const [selectedTag, setSelectedTag] = useState<Tag | undefined>(tag);
    const [selectedValues, setSelectedValues] = useState(
        getTagValues(tagValues, usedValues, isDateTag)
    );

    const [selectedValue, setSelectedValue] = useState(value);

    // Update selectedTag, Values if they exist
    useEffect(() => {
        setSelectedTag(tag);
        setSelectedValues(getTagValues(tagValues, usedValues, isDateTag));
    }, [tag, tagValues, usedValues, isDateTag]);

    useEffect(() => {
        setSelectedValue(value);
    }, [value]);

    // Internal callback on tag change to apply binding between tag and values.
    // Whenever Tag is changed, Value and Weight will de setted up to default
    const onTagSelectorChange = tagName => {
        const tag = tags.find(tag => tag.Name === tagName);

        if (tag !== selectedTag) {
            setSelectedTag(tag);
            setSelectedValues(getTagValues(tagValues, usedValues, isDateTag));
            onTagChange && onTagChange(tag);
        }
    };

    // Internal callback on value change to apply binding between value and weight
    // Whenever new Value will be selected, Weight would be changed accordinly
    const onValueSelectorChange = value => {
        if (value !== selectedValue) {
            setSelectedValue(value);
            onValueChange && onValueChange(value);
        }
    };

    // By default, if there is no tag selected, the value selector should be hidden
    const showValue = !!selectedTag;

    const isValidValue = selectedValues.some(
        item => item.value === selectedValue
    );

    return (
        <TwoColumnLayout>
            <Dropdown
                label="Tag"
                options={parsedTags}
                value={selectedTag ? selectedTag.Name : ''}
                onValueChange={value => onTagSelectorChange(value)}
            />
            {showValue && (
                <Dropdown
                    label="Value"
                    options={selectedValues}
                    value={isValidValue ? selectedValue : ''}
                    onValueChange={value => onValueSelectorChange(value)}
                />
            )}
        </TwoColumnLayout>
    );
};
