export interface CreateRequestParams {
    defaultBaseUrl?: string;
    defaultMethod?: string;
    getAccessToken?: Function;
    unknownErrorMessage?: string;
}
export interface ApiRequestParams {
    url: string;
    baseUrl?: string;
    headers?: Record<string, string>;
    body?: Record<string, any>;
}

export interface CustomApiRequestParams extends ApiRequestParams {
    method?: string;
}

export type ApiRequestResult<DataType = any> = [string | null, DataType];

export type ApiRequest = (
    params: ApiRequestParams
) => Promise<ApiRequestResult>;

export type CustomApiRequest = (
    params: CustomApiRequestParams
) => Promise<ApiRequestResult>;

export const DEFAULT_UNKNOWN_ERROR_MESSAGE =
    'An unknown error occured. Please check your network connection or try again later.';

/**
 * # A generic request utility, intended to be used both in API services and on its own.
 *
 * This method is intended to take care of all error handling and return
 * an array of the form `[error, data]`. If there's no error and/or no data, the respective
 * part will be `null`. This enables us to make requests like this (example API service):
 *
 * ```
 * const [error, data] = await api.get({...});
 *
 * if (error) {
 *     // handle error
 * }
 *
 * // work with data
 * ```
 *
 * without having to wrap every API request in a try-catch. For now, the returned
 * error will always be a string, but we could re-evaluate this and see if it makes
 * more sense to e.g. return an actual `Error`.
 *
 * Due to returning error and data as an array, we can also give both any variable name,
 * enabling for example easy processing of multiple requests at a time:
 * ```
 * const [getError, getData] = await api.get({...});
 * const [postError, postData] = await api.post({...});
 * ```
 *
 * Parameters for `createRequest`:
 * @param defaultBaseUrl The base url to use by default - usually the `API_BASE_URL`
 * @param defaultMethod Can be used to create a `request` function that defaults to a certain method, e.g. for an API service
 * @param getAccessToken A method to getrieve an access token for use in an API service
 * @param unknownErrorMessage The message to show if no errors could be extracted from the response
 *
 * Parameters for the method returned by `createRequest`:
 * @param url The url, will be appended to the base url + `/api/v1`
 * @param baseUrl The baseUrl, if different from the `defaultBaseUrl`
 * @param method The method, if different from the `defaultMethod`
 * @param headers An object of additional headers to send with the request
 * @param body The body of the request
 */
export function createRequest({
    defaultBaseUrl,
    defaultMethod = 'GET',
    getAccessToken,
    unknownErrorMessage = DEFAULT_UNKNOWN_ERROR_MESSAGE,
}: CreateRequestParams = {}) {
    return async ({
        url,
        baseUrl = defaultBaseUrl,
        method = defaultMethod,
        headers = {},
        body,
    }: CustomApiRequestParams) => {
        // Get access token if a getter method is given
        const accessToken = getAccessToken ? getAccessToken() : undefined;

        // Use any additional headers that were passed
        const requestHeaders: Record<string, string> = {
            ...headers,
        };

        // If we're sending a body, set Content-Type to json
        if (body && !requestHeaders['Content-Type']) {
            requestHeaders['Content-Type'] = 'application/json';
        }

        // If we have a user with an access token and no `Authorization` header is set, set it
        if (accessToken && !requestHeaders.Authorization) {
            requestHeaders.Authorization = `Bearer ${accessToken}`;
        }

        // Always add method and headers
        const requestParams: RequestInit = {
            method,
            headers: requestHeaders,
        };

        // Add stringified body if given
        if (body) {
            requestParams.body = JSON.stringify(body);
        }

        try {
            const response = await fetch(
                `${baseUrl || ''}/api/v1${url}`,
                requestParams
            );

            // Grab content type header from response since we don't always get json
            const contentTypeHeader = response.headers.get('Content-Type');

            // Check if the content-type is json - will be `application/json; charset=utf-8`
            // so check for presence in string instead of ==
            const isJson =
                !!contentTypeHeader &&
                contentTypeHeader.indexOf('application/json') > -1;

            // Check for any problems with request
            if (!response.ok) {
                // If we have a non-server error, check if we have any error messages
                // from the API that could be displayed to the user
                if (response.status < 500 && isJson) {
                    let json: any;

                    try {
                        json = await response.json();
                    } catch (e) {
                        // If the conversion to JSON fails, throw a generic
                        // error to default to the default unknown error message
                        // istanbul ignore next - this should never happen if BE
                        // always sends correct headers, but we catch just in case
                        throw new Error();
                    }

                    if (json && json.Errors) {
                        // If the response has errors, pass them on
                        throw new Error(json.Errors.join(' '));
                    }
                }

                // If there was a server error or no error messages, throw a generic
                // error to default to the default unknown error message
                throw new Error();
            }

            return [
                null,
                isJson ? await response.json() : null,
            ] as ApiRequestResult;
        } catch (e) {
            return [e.message || unknownErrorMessage, null] as ApiRequestResult;
        }
    };
}
