import React, { FunctionComponent, useContext, useEffect } from 'react';
import { User } from '../auth';
import { OidcContext } from './OidcProvider';

export interface CallbackProps {
    /**
     * Callback type
     */
    type: 'login' | 'logout' | 'refresh';

    /**
     * Success callback with current user
     */
    onSuccess?: (user: User) => void;

    /**
     * Error callback with error
     */
    onError?: (error: Error) => void;
}

/* istanbul ignore next: Untestable background token refresh */
const noop = () => {};

export const Callback: FunctionComponent<CallbackProps> = ({
    type,
    onSuccess,
    onError,
    children,
}) => {
    const { controller } = useContext(OidcContext);

    let method: string;

    switch (type) {
        case 'login':
            method = 'signinRedirectCallback';
            break;
        case 'logout':
            method = 'signoutRedirectCallback';
            break;
        /* istanbul ignore next: Untestable background token refresh */
        case 'refresh':
            method = 'signinSilentCallback';
            break;
    }

    useEffect(() => {
        controller[method]()
            .then(
                onSuccess /* istanbul ignore next: Untestable background token refresh */ ||
                    noop
            )
            .catch(
                onError /* istanbul ignore next: Untestable background token refresh */ ||
                    noop
            );
    });

    // Just render whatever children we've been given, will be displayed while things are loading
    return <React.Fragment>{children}</React.Fragment>;
};
