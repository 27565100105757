import { User } from 'oidc-client';

/**
 * A utility to validate that a given user has the necessary permission to access a page
 * @param requiredPermission One or multiple permissions. For multiple requiredPermission at least one has to match
 */
export const createUserPermissionValidator =
    (requiredPermission: string | string[]) => (user: User) => {
        let permissions = user.profile.permission || '';

        if (typeof permissions === 'string') {
            permissions = [permissions];
        }

        // Check is user has at least one of the required permissions
        if (Array.isArray(requiredPermission)) {
            return requiredPermission.some(item => permissions.includes(item));
        }

        // If the user either has no permissions at all or not at least the
        // permission to read stuff, they're not allowed to see this
        const isInvalid = !permissions.includes(requiredPermission);

        return !isInvalid;
    };
