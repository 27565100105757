import { startOfMonth } from 'date-fns';
import React, {
    createContext,
    FunctionComponent,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { IndexingStatisticResponse } from '../../../api-types/search';
import { AuthContext } from '../../../components/AuthGate';
import config from '../../../config';
import { createProviderHoC } from '../../../util/createProviderHoC';
import { ApiRequestResult } from '../../../util/createRequest';
import { getRangeFilterQueryString } from '../../../util/dateFormatting';

interface IndexingDocumentsProviderProps {
    runInitialFetch?: boolean;
}

/**
 * State of the context - use property names scoped to this provider since
 * they will otherwise clash with other providers.
 */
type IndexingDocumentsContextState = {
    documents?: IndexingStatisticResponse[];
    documentsError: string;
    areDocumentsLoading: boolean;
    dateFilter?: string;
    refetch: () => void;
    changeDates: (dateFilter: string) => void;
};

const today = new Date();

export const initialIndexingRange = {
    from: startOfMonth(today),
    to: today,
};

export const initialIndexingFilter =
    getRangeFilterQueryString(initialIndexingRange);

const defaults: IndexingDocumentsContextState = {
    documentsError: '',
    dateFilter: initialIndexingFilter,
    areDocumentsLoading: false,
    refetch: /* istanbul ignore next - unused default case */ () => {},
    changeDates: /* istanbul ignore next - unused default case */ () => {},
};

const IndexingDocumentsContext =
    createContext<IndexingDocumentsContextState>(defaults);

/**
 * A provider to fetch the indexed documents.
 *
 * Wrap this around views that need access to the indexed documents.
 */
const IndexingDocumentsProvider: FunctionComponent<IndexingDocumentsProviderProps> =
    ({ children, runInitialFetch = true }) => {
        const [isStale, setIsStale] = useState(runInitialFetch);

        const [documents, setDocuments] = useState<IndexingStatisticResponse[]>(
            []
        );
        const [error, setError] = useState(defaults.documentsError);
        const [isLoading, setIsLoading] = useState(
            defaults.areDocumentsLoading
        );
        const [dateFilter, setDateFilter] = useState(defaults.dateFilter);

        const { api } = useContext(AuthContext);

        useEffect(() => {
            if (!isStale) {
                return;
            }

            let isCancelled = false;

            const load = async () => {
                setIsLoading(true);

                const [error, data]: ApiRequestResult<
                    IndexingStatisticResponse[]
                > = await api.get({
                    baseUrl: config.SEARCH_OPTIMIZATION_API_BASE_URL,
                    url: `/indexingStatistic?${dateFilter}`,
                });

                if (isCancelled) {
                    return;
                }

                setIsStale(false);
                setIsLoading(false);

                if (error) {
                    setError(error);
                    return;
                }

                setDocuments(data);
            };

            if (isStale) {
                load();
            }

            return () => {
                isCancelled = true;
            };
        }, [api, documents, dateFilter, isStale]);

        /**
         * Refetch the documents (or run initial fetch if runInitialFetch was false)
         */
        const refetch = useCallback(() => setIsStale(true), [setIsStale]);

        // Make sure we memoize this, as otherwise it will trigger unnecessary re-renders
        const changeDates = useCallback(
            (dateFilter: string) => {
                setDateFilter(dateFilter);
                refetch();
            },
            [setDateFilter, refetch]
        );

        return (
            <IndexingDocumentsContext.Provider
                value={{
                    documents,
                    documentsError: error,
                    areDocumentsLoading: isLoading,
                    refetch: refetch,
                    changeDates,
                }}
            >
                {children}
            </IndexingDocumentsContext.Provider>
        );
    };

const withIndexingDocuments = createProviderHoC(IndexingDocumentsProvider);

export { IndexingDocumentsContext, withIndexingDocuments };
export default IndexingDocumentsProvider;
