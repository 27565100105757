import StackdriverErrorReporter from 'stackdriver-errors-js';

interface ErrorTrackingOptions {
    apiKey: string;
    projectId: string;
    enabled: boolean;
    releaseId: string;
}

export const initErrorTracking = ({
    apiKey,
    projectId,
    enabled,
    releaseId,
}: ErrorTrackingOptions) => {
    const errorHandler = new StackdriverErrorReporter();

    errorHandler.start({
        key: apiKey,
        projectId,
        service: 'eden-frontend',
        version: releaseId,
        disabled: !enabled,
    });

    return errorHandler;
};
