import React, {
    FunctionComponent,
    useContext,
    useEffect,
    useState,
} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Heading } from '@oetkerdigital/eden-design-system-react';
import { RoleResponse } from '../../../api-types/user-manager';
import { AuthContext } from '../../../components/AuthGate';
import config from '../../../config';
import { ApiRequestResult } from '../../../util/createRequest';
import { useAlerts } from '../../../components/AlertProvider';

interface RouteParams {
    roleId: string;
}

export const RoleDetail: FunctionComponent<RouteComponentProps<RouteParams>> =
    ({ match }) => {
        const roleId = match.params.roleId;

        const [isLoading, setIsLoading] = useState(false);
        const [roleData, setRoleData] = useState<RoleResponse | null>(null);

        const { api } = useContext(AuthContext);

        const alerts = useAlerts();

        useEffect(() => {
            let isCancelled = false;

            const loadData = async () => {
                setIsLoading(true);

                const [error, data]: ApiRequestResult<RoleResponse> =
                    await api.get({
                        baseUrl: config.USER_MANAGER_API_BASE_URL,
                        url: `/roles/${roleId}`,
                    });

                if (isCancelled) {
                    return;
                }

                setIsLoading(false);

                if (error) {
                    alerts.error(error);
                    return;
                }

                setRoleData(data);
            };

            loadData();

            return () => {
                isCancelled = true;
            };
        }, [api, roleId, alerts]);

        // Display loading if still loading or otherwise no roleData
        if (isLoading || !roleData) {
            return <p>Loading...</p>;
        }

        return (
            <React.Fragment>
                <Heading>Role: {roleData.Name}</Heading>
                <p>{roleData.Description}</p>

                <Heading as="h2" className="mt--1">
                    Permissions
                </Heading>

                {roleData.Permissions.length ? (
                    <ul>
                        {roleData.Permissions.map(permission => (
                            <li key={permission.Scope}>{permission.Scope}</li>
                        ))}
                    </ul>
                ) : (
                    <p>This role does not have any permissions.</p>
                )}
            </React.Fragment>
        );
    };
