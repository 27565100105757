import React, { FunctionComponent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import config from '../../config';

declare global {
    interface Window {
        dataLayer: any[];
    }
}

// These pages only redirect to other pages, so it doesn't make sense to
// track their page views (since the page they redirect to will track its own
// page view immediately afterwards). Once we get to restructuring our routing
// solution, this should be obsolete, and ignore redirect-only pages by default.
const doNotTrackPages = [
    '/search-optimization',
    '/user-manager',
    '/service-configurator',
    '/system-configurator',
];

/**
 * Provider for all things analytics.
 *
 * Functionality includes but is not limited to:
 * - Loading (or not) the GTM depending on current environment configuration
 * - Tracking page view events through integration with react-router (TODO)
 * - Providing context methods to track custom events (TODO)
 */
export const AnalyticsProvider: FunctionComponent = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    const { pathname, hash, search } = useLocation();

    /* istanbul ignore next: We never actually load the GTM in tests */
    const loadGtm = () => {
        // Track loading state to make sure we're never doing this twice
        setIsLoading(true);

        // Construct a new script tag that will load the GTM script
        const gtmScriptTag = document.createElement('script');
        gtmScriptTag.async = true;
        gtmScriptTag.src = `https://www.googletagmanager.com/gtm.js?id=GTM-KPFR5XD&gtm_auth=${config.GTM.AUTH}&gtm_preview=${config.GTM.PREVIEW}&gtm_cookies_win=x`;
        gtmScriptTag.onload = () => {
            setIsLoaded(true);
            setIsLoading(false);
        };

        // Append it at the end of the body
        document.body.append(gtmScriptTag);
    };

    useEffect(() => {
        // If we already ran this effect or are currently running it, do nothing
        if (isLoading || isLoaded) {
            return;
        }

        // Ensure dataLayer is an array if it doesn't exist already
        window.dataLayer = window.dataLayer || [];

        // Push starting event with current time stamp
        window.dataLayer.push({
            'gtm.start': Date.now(),
            event: 'gtm.js',
        });

        // We need the complete original location, except for any hashes
        const originalLocation = window.location.href.replace(
            window.location.hash,
            ''
        );

        // Push original location before GTM is initialized (see PRJEDEN-688)
        window.dataLayer.push({
            pageValues: { originalLocation },
        });

        // If we're in a testing environment, we definitely NEVER want to load
        // the actual GTM script, and only assert on data layer pushes
        /* istanbul ignore else: Cannot proceed past here in tests */
        if (process.env.NODE_ENV === 'test') {
            return;
        }

        // If the GTM is not enabled, don't load the actual GTM script
        /* istanbul ignore next: Cannot get to here in tests */
        if (!config.GTM.ENABLED) {
            return;
        }

        /* istanbul ignore next: Cannot get to here in tests */
        loadGtm();
    }, [isLoading, isLoaded]);

    /**
     * Track a virtual page view for the given path
     * @param path The full path (.pathname + .hash + .search)
     */
    const trackPageView = (path: string) => {
        window.dataLayer.push({
            event: 'virtualPageView',
            pageValues: {
                pagePath: path,
            },
        });
    };

    // Whenever either path, hash or query string changes, track a page view
    useEffect(() => {
        // Do not track a page view for pages that shouldn't be tracked (duh!)
        if (doNotTrackPages.includes(pathname)) {
            return;
        }

        trackPageView(`${pathname}${hash}${search}`);
    }, [pathname, hash, search]);

    return <React.Fragment>{children}</React.Fragment>;
};
