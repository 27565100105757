import React, { FunctionComponent } from 'react';
import { BrowserRouter, RouteComponentProps } from 'react-router-dom';
import { Routing } from '../components/Routing';
import config from '../config';
import routes from '../routes';
import { createController } from '../util/createController';
import { AlertProvider } from '../components/AlertProvider';
import { AnalyticsProvider } from './base/AnalyticsProvider';
import { NotFound } from './base/NotFound';

export const scope = [
    'openid',
    'od-user-manager',
    'od-on-site-search',
    'od-adam',
    'od-link-shortener',
    'od-sprout',
].join(' ');

const authController = createController({
    clientId: 'od-eden-js',
    scope,
    authority: config.AUTH_AUTHORITY,
});

export const App: FunctionComponent<RouteComponentProps> = () => {
    return (
        <AnalyticsProvider>
            <Routing
                routes={routes}
                authController={authController}
                NotFound={NotFound}
            />
        </AnalyticsProvider>
    );
};

export const AppContainer: FunctionComponent = ({ children }) => (
    <BrowserRouter>
        <AlertProvider>{children}</AlertProvider>
    </BrowserRouter>
);
