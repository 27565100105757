import React, { FunctionComponent, useContext } from 'react';
import { RouteComponentProps, Link, useHistory } from 'react-router-dom';
import {
    Button,
    Heading,
    Switch,
    Card,
    Textfield,
    Dropdown,
    Label,
    TwoColumnLayout,
    ContentLayout,
} from '@oetkerdigital/eden-design-system-react';
import { UserResponse } from '../../../api-types/user-manager';
import { getQueryParam } from '../../../util/getQueryParam';
import { provide } from '../../../util/provide';
import { useHasPermission } from '../../../hooks/useHasPermission';
import { useUser } from '../../../hooks/useUser';
import { useAlerts } from '../../../components/AlertProvider';
import { UsersContext, withUsers } from './UsersProvider';

type OperationValue = 'Edit' | 'Delete' | 'View' | 'Status';

type Operation = { label: string; value: OperationValue };

interface UserCardProps {
    status?: 'Active' | 'Inactive';
    operations: Operation[];
    user: UserResponse;
    onUserStatusChange: (id: number) => void;
    onDelete: (id: number) => void;
}

/**
 * Helper util to get company status of the user
 * @param user: User which company status should be returned
 */
const getCompanyStatus = (user?: UserResponse) => {
    if (!user || !user.Companies[0]) {
        return;
    }

    return user.Companies[0].CompanyStatus;
};

/**
 * Helper util to bind company status to user status
 * @param user: User which status should be returned
 */
const getUserStatus = (user?: UserResponse) => {
    if (!user) {
        return;
    }

    const companyStatus = getCompanyStatus(user);

    if (!companyStatus) {
        return user.UserStatus;
    }

    return companyStatus === 'Active' ? user.UserStatus : 'Inactive';
};

/**
 * TODO: Find a better home for this lovely util. Create common status label getter in core?
 *
 * Helper util which returns oposite of current user status to difene correct action label
 * @param user: User which button text should be returned
 * @default: 'Activate', because usually newly created user is Inactive and label for it is opposite of current status
 */
const getStatusLabel = (user: UserResponse) => {
    return getUserStatus(user) === 'Active' ? 'Deactivate' : 'Activate';
};

const getOperationOptions = (
    user: UserResponse,
    permission: {
        isActivator: boolean;
        isDetailViewer: boolean;
        isModifier: boolean;
    }
) => {
    const operations: Operation[] = [];

    if (permission.isActivator) {
        operations.push({
            label: getStatusLabel(user),
            value: 'Status',
        });
    }

    if (permission.isDetailViewer) {
        operations.push({
            label: 'View',
            value: 'View',
        });
    }

    if (permission.isModifier) {
        operations.push({
            label: 'Edit',
            value: 'Edit',
        });
        operations.push({
            label: 'Delete',
            value: 'Delete',
        });
    }

    return operations;
};

const UserCard: React.FC<UserCardProps> = ({
    status,
    user,
    operations,
    onDelete,
    onUserStatusChange,
}) => {
    const history = useHistory();

    const handleOperations = (value: OperationValue) => {
        switch (value) {
            case 'Edit':
                history.push(`/user-manager/users/${user.Id}/edit`);
                break;
            case 'Delete':
                onDelete(user.Id);
                break;
            case 'Status':
                onUserStatusChange(user.Id);
                break;
            case 'View':
                history.push(`/user-manager/users/${user.Id}`);
                break;
        }
    };

    return (
        <Card aria-label={`${user.FirstName} ${user.LastName}`}>
            {/* TODO */}
            <div className="row mb--1" style={{ alignItems: 'center' }}>
                <div className="col-12 col-md-2">
                    <Label variant={status === 'Active' ? 'success' : 'error'}>
                        {status}
                    </Label>
                </div>

                <div className="col-6 col-md-3">
                    <Textfield
                        value={user.FirstName}
                        label="First Name"
                        readOnly
                        disabled={false}
                        onValueChange={() => null}
                    />
                </div>
                <div className="col-6 col-md-3">
                    <Textfield
                        value={user.LastName}
                        label="Last Name"
                        readOnly
                        disabled={false}
                        onValueChange={() => null}
                    />
                </div>
                <div className="col-12 col-md-4">
                    <Dropdown<OperationValue>
                        label="Operations"
                        options={operations}
                        value={null}
                        onValueChange={handleOperations}
                    />
                </div>
            </div>
        </Card>
    );
};

const __UsersList: FunctionComponent<RouteComponentProps> = ({ location }) => {
    const {
        data,
        isLoading,
        limitProps,
        orderByProps,
        delete: deleteUser,
        patch: patchUser,
        refetch,
    } = useContext(UsersContext);

    const alerts = useAlerts();

    const currentUser = useUser()!;

    // Remove current user from the list
    const users = data.filter(u => u.SubjectId !== currentUser.profile.sub);

    // To check if there is any company to filter users by this company
    const hasCompanyFilter = () =>
        !!getQueryParam(location.search, 'companyIds');

    const onDelete = (id: number) => {
        const user = users.find(u => u.Id === id)!;

        alerts.alert({
            message: `Really delete user "${user.FirstName} ${user.LastName}"?`,
            content: (
                <React.Fragment>
                    <p>This action cannot be undone.</p>

                    <Button
                        onClick={() => onDeleteConfirm(id)}
                        title={`Confirm that user ${user.FirstName} ${user.LastName} will be deleted`}
                        className="mr--1"
                    >
                        Yes, delete
                    </Button>

                    <Button
                        variant="secondary"
                        onClick={() => alerts.remove()}
                        title={`Abort deleting user ${user.FirstName} ${user.LastName}`}
                    >
                        Cancel
                    </Button>
                </React.Fragment>
            ),
        });
    };

    const onDeleteConfirm = async (id: number) => {
        const user = users.find(u => u.Id === id)!;

        const [error] = await deleteUser(id!);

        if (error) {
            alerts.error(error);
            return;
        }

        alerts.success(`User ${user.FirstName} ${user.LastName} was deleted`);
        refetch();
    };

    const onUserStatusChange = (id: number) => {
        const user = users.find(u => u.Id === id)!;

        if (getCompanyStatus(user) === 'Inactive') {
            alerts.error(
                `Sorry, ${user.FirstName} ${user.LastName} status cannot be changed because company ${user.Companies[0].Name} is not active`
            );

            return;
        }

        const newStatus = getStatusLabel(user).toLowerCase();

        alerts.alert({
            message: `Really ${newStatus} user "${user.FirstName} ${user.LastName}"?`,
            content: (
                <React.Fragment>
                    <Button
                        onClick={() => onChangeStatusConfirm(id)}
                        title={`Confirm that user ${user.FirstName} ${user.LastName} status will be changed`}
                    >
                        Yes, {newStatus}
                    </Button>

                    <Button
                        variant="secondary"
                        onClick={() => alerts.remove()}
                        title={`Abort changing status for user ${user.FirstName} ${user.LastName}`}
                    >
                        Cancel
                    </Button>
                </React.Fragment>
            ),
        });
    };

    const onChangeStatusConfirm = async (id: number) => {
        const user = users.find(u => u.Id === id)!;
        const newStatus = getStatusLabel(user).toLowerCase();

        const [error] = await patchUser(id, {
            UserStatus: user.UserStatus === 'Active' ? 'Inactive' : 'Active',
        });

        if (error) {
            alerts.error(error);
            return;
        }

        alerts.success(
            `User ${user.FirstName} ${user.LastName} was ${newStatus}d`
        );
        refetch();
    };

    const isModifier = useHasPermission('user-manager:users:modify');
    const isActivator = useHasPermission('user-manager:users:activate');
    const isDetailViewer = useHasPermission('user-manager:users:details');

    return (
        <React.Fragment>
            <Heading>User Management</Heading>
            <p>Manage users within the user manager.</p>

            {isModifier && (
                <Link className="Button" to="/user-manager/users/new">
                    Create new user
                </Link>
            )}

            {hasCompanyFilter() && (
                <Link className="Button" to="/user-manager/users">
                    Remove Company Filter
                </Link>
            )}

            <TwoColumnLayout className="mt--1 mb--1">
                <Dropdown<keyof UserResponse>
                    label="Sorting"
                    options={[
                        {
                            label: 'By Status',
                            value: 'UserStatus',
                        },
                        {
                            label: 'By First Name',
                            value: 'FirstName',
                        },
                        {
                            label: 'By Last Name',
                            value: 'LastName',
                        },
                    ]}
                    value={orderByProps.orderingBy}
                    onValueChange={orderByProps.orderBy}
                />
            </TwoColumnLayout>

            <ContentLayout>
                {users.map(user => {
                    return (
                        <UserCard
                            key={user.Id}
                            status={getUserStatus(user)}
                            operations={getOperationOptions(user, {
                                isActivator,
                                isDetailViewer,
                                isModifier,
                            })}
                            onDelete={onDelete}
                            onUserStatusChange={onUserStatusChange}
                            user={user}
                        />
                    );
                })}

                {!users.length && !isLoading && <div>No results</div>}
                {!users.length && !isLoading && <div>Loading...</div>}
            </ContentLayout>

            <Switch<number>
                className="mt--1"
                items={[
                    { value: 100, label: '100' },
                    { value: 500, label: '500' },
                ]}
                value={limitProps.limit}
                onValueChange={limitProps.limitTo}
                aria-label="Limit results to"
            />
        </React.Fragment>
    );
};

export const UsersList = provide(__UsersList, withUsers({ limit: 100 }));
