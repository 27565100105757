import React, {
    FunctionComponent,
    useContext,
    useEffect,
    useState,
} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Heading, DetailField } from '@oetkerdigital/eden-design-system-react';
import { useAlerts } from '../../../components/AlertProvider';
import { CompanyResponse } from '../../../api-types/user-manager';
import { CompaniesContext, withCompanies } from './CompaniesProvider';

interface RouteParams {
    companyId: string;
}

const __CompaniesDetail: FunctionComponent<RouteComponentProps<RouteParams>> =
    ({ match }) => {
        const companyId = match.params.companyId;

        const [isLoading, setIsLoading] = useState(false);

        const [companyData, setCompanyData] = useState<CompanyResponse | null>(
            null
        );

        const { fetchOne: fetchCompany } = useContext(CompaniesContext);

        const alerts = useAlerts();

        useEffect(() => {
            let isCancelled = false;

            const loadData = async () => {
                setIsLoading(true);

                const [error, data] = await fetchCompany(companyId);

                if (isCancelled) {
                    return;
                }

                setIsLoading(false);

                if (error) {
                    alerts.error(error);
                    return;
                }

                setCompanyData(data);
            };

            loadData();

            return () => {
                isCancelled = true;
            };
            // We don't want to rely on fetchCompany
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [companyId]);

        return (
            <React.Fragment>
                <Heading>Company: {companyData && companyData.Name}</Heading>
                <p>Company detailed information can be checked here</p>

                {companyData && (
                    <React.Fragment>
                        <Heading as="h2" className="mt--1">
                            Main company data
                        </Heading>

                        <DetailField name="Company Name">
                            {companyData.Name}
                        </DetailField>

                        <DetailField name="Street">
                            {companyData.Street}
                        </DetailField>

                        <DetailField name="No.">
                            {companyData.HouseNumber}
                        </DetailField>

                        <DetailField name="Zip code">
                            {companyData.ZipCode}
                        </DetailField>

                        <DetailField name="City">
                            {companyData.City}
                        </DetailField>

                        <DetailField name="State">
                            {companyData.State || '-'}
                        </DetailField>

                        <DetailField name="Country">
                            {companyData.Country}
                        </DetailField>

                        <Heading as="h2" className="mt--1">
                            Additional information
                        </Heading>

                        <DetailField name="Email address">
                            {companyData.Email || '-'}
                        </DetailField>

                        <DetailField name="Phone number">
                            {companyData.TelephoneNumber || '-'}
                        </DetailField>

                        <DetailField name="Tax number">
                            {companyData.TaxNumber || '-'}
                        </DetailField>
                    </React.Fragment>
                )}

                {!companyData && isLoading && <p>Loading...</p>}

                {!companyData && !isLoading && <p>No data found</p>}
            </React.Fragment>
        );
    };

export const CompaniesDetail = withCompanies({ runInitialFetch: false })(
    __CompaniesDetail
);
