import React, {
    createContext,
    FunctionComponent,
    useContext,
    useEffect,
    useState,
} from 'react';
import {
    SyncWebsiteScheduleResponse,
    WebsiteSettingsResponse,
    WebsiteStatusResponse,
} from '../../../api-types/search';
import { AuthContext } from '../../../components/AuthGate';
import config from '../../../config';
import { createProviderHoC } from '../../../util/createProviderHoC';
import { ApiRequestResult } from '../../../util/createRequest';
import { useSettings, withSettings } from '../SettingsProvider';

export interface PatchedWebsiteStatusResponse extends WebsiteStatusResponse {
    SyncSettings: SyncWebsiteScheduleResponse;
    Settings: WebsiteSettingsResponse;
}

type IndexingState = {
    settingsStatus: PatchedWebsiteStatusResponse | null;
    indexOne: (url: string) => Promise<ApiRequestResult>;
    indexAll: () => Promise<ApiRequestResult>;
    refetch: () => void;
};

const defaults: IndexingState = {
    settingsStatus: null,
    indexOne: /* istanbul ignore next - unused default case */ () =>
        Promise.resolve([null, null]),
    indexAll: /* istanbul ignore next - unused default case */ () =>
        Promise.resolve([null, null]),
    refetch: /* istanbul ignore next - unused default case */ () => {},
};

const IndexingContext = createContext<IndexingState>(defaults);

const IndexingProvider: FunctionComponent = ({ children }) => {
    const { getRequestHeaders, searchIndex } = useSettings();

    const { api } = useContext(AuthContext);

    const [settingsStatus, setSettingsStatus] = useState(
        defaults.settingsStatus
    );

    const [isStale, setIsStale] = useState(!!searchIndex);

    // After search index changes, refetch data
    useEffect(() => {
        if (!searchIndex) {
            return;
        }

        setIsStale(true);
    }, [searchIndex]);

    useEffect(() => {
        if (!isStale) {
            return;
        }

        let isCancelled = false;

        const load = async () => {
            const [
                error,
                data,
            ]: ApiRequestResult<PatchedWebsiteStatusResponse> = await api.get({
                baseUrl: config.SEARCH_OPTIMIZATION_API_BASE_URL,
                url: '/settings/status',
                headers: getRequestHeaders(),
            });

            /* istanbul ignore if */
            if (isCancelled) {
                return;
            }

            setIsStale(false);

            // First el is error in the response
            if (error) {
                console.error(error);
                return;
            }

            if (data) {
                setSettingsStatus(data);
                return;
            }
        };

        load();

        return () => {
            isCancelled = true;
        };
    }, [isStale, api, getRequestHeaders]);

    const indexOne = async (url: string) => {
        const requestResult: ApiRequestResult = await api.post({
            baseUrl: config.SEARCH_OPTIMIZATION_API_BASE_URL,
            url: '/documents/index',
            headers: getRequestHeaders(),
            body: {
                Url: url,
            },
        });

        return requestResult;
    };

    const indexAll = async () => {
        const requestResult: ApiRequestResult = await api.put({
            baseUrl: config.SEARCH_OPTIMIZATION_API_BASE_URL,
            url: '/settings/reindex',
            headers: getRequestHeaders(),
        });

        return requestResult;
    };

    const refetch = () => {
        setIsStale(true);
    };

    return (
        <IndexingContext.Provider
            value={{ settingsStatus, indexOne, indexAll, refetch }}
        >
            {children}
        </IndexingContext.Provider>
    );
};

const withIndexing = createProviderHoC(withSettings()(IndexingProvider));

export { IndexingContext, withIndexing };
export default withSettings()(IndexingProvider);
