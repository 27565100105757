/**
 * Create a hasFeature function based on a config object that can be used to do
 * simple feature checking.
 * @param config
 */
export const createHasFeature = (config: { DISABLED_FEATURES: string[] }) => {
    const hasFeature = (name: string) => {
        return !config.DISABLED_FEATURES.includes(name);
    };

    return hasFeature;
};
