import React, { FunctionComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
    Heading,
    Link,
    EmptyLayout,
} from '@oetkerdigital/eden-design-system-react';

export const NotFound: FunctionComponent<RouteComponentProps> = () => {
    return (
        <EmptyLayout>
            <Heading>There's nothing here...</Heading>
            <Link href="/">Back to Start</Link>
        </EmptyLayout>
    );
};
