export type Validator = (value: string) => string | undefined;

export const validators = {
    /**
     * Defines if field has any value
     * @param message: Validation error message to notify user
     */
    required: (message: string) => (value: string) => {
        if (value.length === 0) {
            return message;
        }
    },
    /**
     * Validate the email string to ensure that it matches the typical traditional email address format.
     * Example valid email addresses
     *
     * mysite@ourearth.com
     * my.ownsite@ourearth.org
     * mysite@you.me.net
     */
    email: (message: string) => (email: string) => {
        const re = /^\w[\w.-]*\w@\w[\w.-]*\w\.\w{2,3}$/;
        if (!re.test(email.toLowerCase())) {
            return message;
        }
    },
};
