import { produce } from 'immer';
import React, { createContext, FunctionComponent, ReactNode } from 'react';
import { PermissionResponse } from '../../../api-types/user-manager';
import config from '../../../config';
import {
    PaginatedApiState,
    paginatedApiStateDefaults,
    usePaginatedApi,
} from '../../../hooks/usePaginatedApi';
import { createProviderHoC } from '../../../util/createProviderHoC';

interface PermissionsProviderProps {
    children?: ReactNode;
    runInitialFetch?: boolean;
}

type PermissionsContextState = PaginatedApiState<
    PermissionResponse,
    PermissionRequest
>;

const defaults = {
    orderingBy: 'Scope' as keyof PermissionResponse,
    limit: 500, // TODO: if we ever get more than 500, we have a problem...
};

const PermissionsContext = createContext<PermissionsContextState>(
    produce(paginatedApiStateDefaults, draft => {
        draft.orderByProps.orderingBy = defaults.orderingBy;
    })
);

const PermissionsProvider: FunctionComponent<PermissionsProviderProps> = ({
    children,
    runInitialFetch = true,
}) => {
    const paginatedApiState = usePaginatedApi<
        PermissionResponse,
        PermissionRequest
    >({
        baseUrl: config.USER_MANAGER_API_BASE_URL,
        url: '/permissions',
        runInitialFetch,
        defaults,
    });

    return (
        <PermissionsContext.Provider value={paginatedApiState}>
            {children}
        </PermissionsContext.Provider>
    );
};

const withPermissions = createProviderHoC(PermissionsProvider);

export { PermissionsContext, withPermissions };
export default PermissionsProvider;
