import React, {
    FocusEvent,
    FormEvent,
    FunctionComponent,
    useContext,
    useEffect,
    useState,
} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
    Button,
    Textfield,
    Heading,
    Link,
    Image,
    CenteredLayout,
} from '@oetkerdigital/eden-design-system-react';
import Logo from '@oetkerdigital/eden-design-system-core/dist/img/logo.svg';
import { OidcContext } from '../../../components/OidcProvider';
import config from '../../../config';
import { ElementWithValue } from '../../../hooks/useFormField';
import { createRequest } from '../../../util/createRequest';
import { getQueryParam } from '../../../util/getQueryParam';
import {
    PasswordSettingsContext,
    withPasswordSettings,
} from '../PasswordSettingsProvider';
import { useAlerts } from '../../../components/AlertProvider';

const request = createRequest({
    defaultBaseUrl: config.USER_MANAGER_API_BASE_URL,
});

const REDIRECT_TIMEOUT = 30000; // 30sec

const __ResetPassword: FunctionComponent<RouteComponentProps> = ({
    location,
}) => {
    const resetToken = getQueryParam(location.search, 'reset-token');
    const invitationCode = getQueryParam(location.search, 'invitation-code');

    const alerts = useAlerts();

    const [resetCompleted, setResetCompleted] = useState(false);
    const [passwordFieldError, setPasswordFieldError] = useState('');
    const [passwordRepeatFieldError, setPasswordRepeatFieldError] =
        useState('');
    const [passwordFieldValue, setPasswordFieldValue] = useState('');
    const [passwordRepeatFieldValue, setPasswordRepeatFieldValue] =
        useState('');

    const isPasswordFieldEmpty = passwordFieldValue.length === 0;
    const isPasswordRepeatFieldEmpty = passwordRepeatFieldValue.length === 0;
    const isPasswordErrorEmpty = passwordFieldError.length === 0;
    const isPasswordRepeatErrorEmpty = passwordRepeatFieldError.length === 0;

    const isValid =
        !isPasswordFieldEmpty &&
        !isPasswordRepeatFieldEmpty &&
        isPasswordErrorEmpty &&
        isPasswordRepeatErrorEmpty;

    const { controller } = useContext(OidcContext);

    const { passwordRequirements, validatePassword: validatePasswordWithApi } =
        useContext(PasswordSettingsContext);

    const handlePasswordChange = (value: string) => {
        setPasswordFieldValue(value);
        setPasswordFieldError('');

        // Just start checking against the passwordRepeat field if there is a value
        if (!isPasswordRepeatFieldEmpty) {
            comparePasswords(value, passwordRepeatFieldValue);
        }
    };

    const handlePasswordRepeatChange = (value: string) => {
        setPasswordRepeatFieldValue(value);
        comparePasswords(value);
    };

    useEffect(() => {
        document.title = 'EDEN - Password Recovery';
    }, []);

    // Redirect user to login page after the password reset is completed
    useEffect(() => {
        let timer;

        if (resetCompleted) {
            timer = setTimeout(() => {
                controller.signinRedirect();
            }, REDIRECT_TIMEOUT);
        }

        return () => clearTimeout(timer);
    }, [controller, resetCompleted]);

    // If there's no reset token or invitation code, just render a blank page.
    if (!resetToken && !invitationCode) {
        return null;
    }

    /**
     * On submit, first check if everything is valid, and then send request.
     * @param e
     */
    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (!isValid) {
            return;
        }

        // If there is resetToken than user is existing and we want to update user password
        if (resetToken) {
            const [error] = await request({
                url: '/accounts/password',
                method: 'PUT',
                body: {
                    PasswordResetToken: resetToken,
                    Password: passwordFieldValue,
                },
            });

            if (error) {
                setPasswordFieldError(error);
                return;
            }

            setPasswordFieldError('');
        }

        // If there is an invitationCode then we want to create password for the user
        if (invitationCode) {
            const [inviteError] = await request({
                url: `/accounts/invitation/${invitationCode}`,
                method: 'POST',
                body: {
                    Password: passwordFieldValue,
                },
            });

            if (inviteError) {
                alerts.error(inviteError);
                return;
            }
        }

        alerts.alert({
            message:
                'Your password has been successfully reset. You can login with your new password now.',
            content: (
                <Link href="/" isButton={true} isSecondary={true}>
                    Go to Login
                </Link>
            ),
            variant: 'success',
        });

        // Reset field values
        setPasswordFieldValue('');
        setPasswordRepeatFieldValue('');

        // Schedule redirect to login page
        setResetCompleted(true);
    };

    /**
     * Validate the password the user just entered with the API.
     * @param e
     */
    const validatePassword = async (e: FocusEvent<ElementWithValue>) => {
        const [error] = await validatePasswordWithApi(e.target.value);

        if (error) {
            setPasswordFieldError(error);
            return;
        }

        setPasswordFieldError('');
    };

    /**
     * Compare password from a blur event or string
     * with the password in the first password field (default value) or the value given as the compareWith parameter.
     * @param e
     * @param compareWith
     */
    const comparePasswords = (
        e: FocusEvent<ElementWithValue> | string,
        compareWith = passwordFieldValue
    ) => {
        const value = typeof e === 'string' ? e : e.target.value;

        if (value !== compareWith) {
            setPasswordRepeatFieldError(`Password doesn't match`);
        } else {
            setPasswordRepeatFieldError('');
        }
    };

    return (
        <CenteredLayout>
            <Image width="50" height="55" src={Logo} alt="logo" />

            <Heading>Reset password</Heading>

            <form className="ContentLayout" onSubmit={onSubmit}>
                {!!passwordRequirements.length && (
                    <p>
                        Please set a new password. Your password needs to
                        satisfy the following criteria:{' '}
                        {passwordRequirements.join(', ')}.
                    </p>
                )}

                <Textfield
                    type="password"
                    label="Password"
                    value={passwordFieldValue}
                    onValueChange={handlePasswordChange}
                    variant={!isPasswordErrorEmpty ? 'error' : undefined}
                    onBlur={validatePassword}
                    hint={passwordFieldError}
                />

                <Textfield
                    type="password"
                    label="Repeat password"
                    value={passwordRepeatFieldValue}
                    onValueChange={handlePasswordRepeatChange}
                    variant={!isPasswordRepeatErrorEmpty ? 'error' : undefined}
                    onBlur={comparePasswords}
                    hint={passwordRepeatFieldError}
                />

                <Button disabled={!isValid}>Save password</Button>
            </form>
        </CenteredLayout>
    );
};

export const ResetPassword = withPasswordSettings()(__ResetPassword);
