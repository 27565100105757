import React, {
    FormEvent,
    FunctionComponent,
    useContext,
    useEffect,
    useState,
} from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import {
    Heading,
    Button,
    Textfield,
} from '@oetkerdigital/eden-design-system-react';
import { SproutItemResponse } from '../../../api-types/sprout';
import { useAlerts } from '../../../components/AlertProvider';
import { BrandsContext, withBrands } from './BrandsProvider';

interface RouteParams {
    brandId: string;
}

const __BrandForm: FunctionComponent<RouteComponentProps<RouteParams>> = ({
    match,
}) => {
    const brandId = match.params.brandId;
    const isUpdating = !!brandId;

    const [nameValue, setNameValue] = useState('');
    const [codeValue, setCodeValue] = useState('');

    const { create, update, fetchOne } = useContext(BrandsContext);

    const [brand, setBrand] = useState<SproutItemResponse | null>(null);

    const alerts = useAlerts();
    const history = useHistory();

    // When the brandId parameter in the URL changes and is present, fetch data
    useEffect(() => {
        if (!brandId) {
            return;
        }

        const fetch = async () => {
            const [error, data] = await fetchOne(brandId);

            if (error || !data) {
                // TODO: handle error
                return;
            }

            setBrand(data);
        };

        fetch();
        // No need to re-run when fetchOne changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brandId]);

    // When we have a new brand, fill the fields with the new values
    useEffect(() => {
        if (!brand) {
            return;
        }

        setNameValue(brand.Name);
        setCodeValue(brand.Code);

        // We do not want to re-run this when the fields change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brand]);

    // Brands are only valid if all fields are filled
    const isValid = !!nameValue && !!codeValue;

    // On form submit, gather values and either update or create brand
    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (!isValid) {
            return;
        }

        const body = {
            Name: nameValue,
            Code: codeValue,
        };

        const [error] = isUpdating
            ? await update(brandId, { ...brand, ...body })
            : await create(body);

        if (error) {
            // TODO: handle errors
            return;
        }

        alerts.success('The brand has been saved successfully');
        history.push('/service-configurator/brands');
    };

    return (
        <React.Fragment>
            <Heading>
                {isUpdating ? `Edit brand: ${nameValue}` : 'Create new brand'}
            </Heading>
            <p>
                Please enter data for the brand. The code needs to match the
                brand used in the search index names!
            </p>
            <form className="ContentLayout" onSubmit={onSubmit}>
                <Textfield
                    label="Name"
                    value={nameValue}
                    onValueChange={setNameValue}
                />

                <Textfield
                    label="Code"
                    value={codeValue}
                    onValueChange={setCodeValue}
                />

                <Button disabled={!isValid}>Save</Button>
            </form>
        </React.Fragment>
    );
};

export const BrandForm = withBrands()(__BrandForm);
