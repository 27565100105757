import React, {
    FunctionComponent,
    useContext,
    useEffect,
    useState,
} from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { User } from '../auth';
import { Api, ApiService } from '../services/ApiService';
import { Callback } from './Callback';
import { OidcContext } from './OidcProvider';

export interface AuthContextState {
    onLogout: () => void;
    user: User | null;
    api: Api;
}

export const AuthContext = React.createContext<AuthContextState>({
    onLogout: () => null,
    user: null,
    api: {} as Api,
});

export interface AuthGateProps extends RouteComponentProps {
    children: any;
    onLoginSuccess?: (user: User) => void;
    onLoginError?: (error: Error) => void;
    onLogoutSuccess?: (user: User) => void;
    onLogoutError?: (error: Error) => void;
}

// Private export for testing internally, as we only use it with `withRouter`
export const __AuthGate: FunctionComponent<AuthGateProps> = ({
    location,
    history,
    children,
    onLoginSuccess,
    onLoginError,
    onLogoutSuccess,
    onLogoutError,
}) => {
    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const { isLoadingUser, user, controller } = useContext(OidcContext);

    // Compare current complete path with path set in user manager for login redirect
    // e.g. http://localhost:3000/complete-login
    const isLoginRedirect =
        window.location.origin + location.pathname ===
        controller.settings.redirect_uri;

    // Compare current complete path with path set in user manager for logout redirect
    // e.g. http://localhost:3000/complete-logout
    const isLogoutRedirect =
        window.location.origin + location.pathname ===
        controller.settings.post_logout_redirect_uri;

    // Compare current complete path with path set in user manager for refresh redirect
    // e.g. http://localhost:3000/complete-refresh
    const isRefreshRedirect =
        window.location.origin + location.pathname ===
        controller.settings.silent_redirect_uri;

    // Check if we should be trying to redirct to login right now
    const shouldUserLogin =
        !user &&
        !isLoadingUser &&
        !isLoginRedirect &&
        !isLogoutRedirect &&
        !isLoggingOut;

    // Redirect to login silently if login should happen
    useEffect(() => {
        if (shouldUserLogin) {
            controller.signinRedirect({
                data: { path: location.pathname },
            });
        }
    }, [shouldUserLogin, controller, location.pathname]);

    // Wait for user to be loaded - only takes place very briefly before and
    // after login
    if (isLoadingUser) {
        return null;
    }

    // Most likely redirect paths
    const redirectToHome = () => history.push('/');
    /* istanbul ignore next: Tested through cypress */
    const redirectToSavedPath = (user: User) => {
        // TODO: For some reason, history.push doesn't work here.
        // This is most likely connected to a bug in connected-react-router,
        // since we will most likely be removing it soon, this is just a quick
        // fix to un-break things quickly and restore redirect functionality.
        // @see https://github.com/supasate/connected-react-router/issues/319
        window.location.replace(user.state.path);
    };

    // If it's the login callback page, render loading screen while processing callback
    if (isLoginRedirect) {
        return (
            <Callback
                type="login"
                onSuccess={onLoginSuccess || redirectToSavedPath}
                onError={onLoginError || redirectToHome}
            />
        );
    }

    // If it's the logout callback page, render loading screen while processing callback
    if (isLogoutRedirect) {
        return (
            <Callback
                type="logout"
                onSuccess={onLogoutSuccess || redirectToHome}
                onError={onLogoutError || redirectToHome}
            />
        );
    }

    /* istanbul ignore if: Tested through cypress */
    if (isRefreshRedirect) {
        return <Callback type="refresh" />;
    }

    // This case should only be visible very briefly before the redirect above
    // takes place, so render the same UI the user will find on the login page
    if (!user) {
        return null;
    }

    // Provide logout function to child
    const onLogout = () => {
        // Save that we're logging out, so login is not immediately re-attempted
        setIsLoggingOut(true);

        controller.signoutRedirect({
            id_token_hint: user.id_token,
        });
    };

    const api = ApiService({
        baseUrl: '',
        getAccessToken: () => user.access_token,
    });

    // Add onLogout function to context so any children can use it
    return (
        <AuthContext.Provider value={{ onLogout, user, api }}>
            {children}
        </AuthContext.Provider>
    );
};

export const AuthGate = withRouter(__AuthGate);

export function useApi() {
    const context = useContext(AuthContext);

    /* istanbul ignore if */
    if (!context) {
        throw new Error('`useApi` must be used within `AuthGate`!');
    }

    return context.api;
}
