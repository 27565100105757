import { ChangeEvent, useEffect, useState } from 'react';

/**
 * Utility that works like useState, except pulls the value out of the
 * ChangeEvent of the form field.
 */
export function useFormFieldState<
    ElementType extends { value: string } = HTMLInputElement
>(
    initialValue: string
): [string, (e: ChangeEvent<ElementType>) => void, (value: string) => void] {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const setFromEvent = (e: ChangeEvent<ElementType>) => {
        setValue(e.target.value);
    };

    return [value, setFromEvent, setValue];
}
