import { useContext, useEffect, useState, useMemo } from 'react';
import { AuthContext } from '../../components/AuthGate';
import config from '../../config';
import { ApiRequestResult } from '../../util/createRequest';
import { useSettings } from './SettingsProvider';

type DefaultDataType = string[];
type DefaultDisplayType = string;

interface SettingsApiParams<DataType, DisplayType> {
    url: string;
    toDisplay: (data: DataType) => DisplayType;
    toData: (value: DisplayType) => DataType;
    defaultData: DataType;
}

export interface SettingsApiState<DisplayType = DefaultDisplayType> {
    value: DisplayType;
    fetchError: string;
    saveError: string;
    isLoading: boolean;
    isSaving: boolean;
    save: (value: DisplayType) => Promise<boolean>;
}

function useSettingsApi<
    DataType = DefaultDataType,
    DisplayType = DefaultDisplayType
>({
    url,
    toDisplay,
    toData,
    defaultData,
}: SettingsApiParams<DataType, DisplayType>): SettingsApiState<DisplayType> {
    const { getRequestHeaders, searchIndex } = useSettings();

    const [isStale, setIsStale] = useState(false);

    const [data, setData] = useState<DataType>(defaultData);
    const [fetchError, setFetchError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [saveError, setSaveError] = useState('');
    const [isSaving, setIsSaving] = useState(false);

    const { api } = useContext(AuthContext);

    const refetch = () => setIsStale(true);

    // Refetch when search index changes and is set
    useEffect(() => {
        if (searchIndex) {
            refetch();
        }
    }, [searchIndex]);

    // Fetch when request is marked as stale
    useEffect(() => {
        if (!isStale) {
            return;
        }

        let isCancelled = false;

        const loadData = async () => {
            setIsLoading(true);

            const [error, data]: ApiRequestResult<DataType> = await api.get({
                baseUrl: config.SEARCH_OPTIMIZATION_API_BASE_URL,
                url: `/settings${url}`,
                headers: {
                    ...getRequestHeaders(),
                },
            });

            if (isCancelled) {
                return;
            }

            setIsStale(false);
            setIsLoading(false);

            if (error) {
                setFetchError(error);
                return;
            }

            setData(data);
        };

        loadData();

        return () => {
            isCancelled = true;
        };
    }, [api, url, isStale, getRequestHeaders]);

    /**
     * Save updated data to API. Afterwards, refetch (if successful).
     *
     * @param value
     */
    const save = async (value: DisplayType) => {
        setIsSaving(true);

        const [error] = await api.put({
            baseUrl: config.SEARCH_OPTIMIZATION_API_BASE_URL,
            url: `/settings${url}`,
            headers: { ...getRequestHeaders() },
            body: toData(value),
        });

        setIsSaving(false);

        if (error) {
            setSaveError(error);
        } else {
            setSaveError('');
            refetch();
        }

        return !error;
    };

    const value = useMemo(() => toDisplay(data), [data, toDisplay]);

    return {
        value,
        fetchError,
        saveError,
        isLoading,
        isSaving,
        save,
    };
}

export default useSettingsApi;
