import { RoutingRoute } from '../../components/Routing';
import { createUserPermissionValidator } from '../../util/createUserPermissionValidator';
import { Home } from './Home';

const routes: RoutingRoute[] = [
    {
        path: '/shrty',
        component: Home,
        label: 'Shrty',
        validateUser: createUserPermissionValidator('link-shortener:read'),
    },
];

export default routes;
