import React, { createContext, FunctionComponent, ReactNode } from 'react';
import { createProviderHoC } from '../../../util/createProviderHoC';
import { withSettings } from '../SettingsProvider';
import useSettingsApi, { SettingsApiState } from '../useSettingsApi';

type WordsProviderState = SettingsApiState;

const WordsContext = createContext<WordsProviderState>(
    {} as WordsProviderState
);

interface WordsProviderProps<DataType = any> {
    children?: ReactNode;
    url: string;
    toText: (words: DataType) => string;
    toData: (text: string) => DataType;
}

const WordsProvider: FunctionComponent<WordsProviderProps> = ({
    children,
    url,
    toText,
    toData,
}) => {
    const settingsApi = useSettingsApi({
        url,
        toDisplay: toText,
        toData,
        defaultData: [],
    });

    return (
        <WordsContext.Provider value={settingsApi}>
            {children}
        </WordsContext.Provider>
    );
};

const withWords = createProviderHoC(withSettings()(WordsProvider));

export { WordsContext, withWords };
export default withSettings()(WordsProvider);
