import { endOfDay, startOfDay, subDays } from 'date-fns';
import { produce } from 'immer';
import React, {
    createContext,
    FunctionComponent,
    ReactNode,
    useEffect,
} from 'react';
import { SearchAggregationResponse } from '../../../api-types/search';
import config from '../../../config';
import {
    PaginatedApiState,
    paginatedApiStateDefaults,
    usePaginatedApi,
} from '../../../hooks/usePaginatedApi';
import { createProviderHoC } from '../../../util/createProviderHoC';
import { getRangeFilterQueryString } from '../../../util/dateFormatting';
import { useSettings, withSettings } from '../SettingsProvider';

interface ReportsProviderProps {
    children?: ReactNode;
}

type ReportsContextState = PaginatedApiState<SearchAggregationResponse, any>;

const today = new Date();
const DEFAULT_NUMBER_RANGE_DAYS = 13;

export const initialReportsRange = {
    from: startOfDay(subDays(today, DEFAULT_NUMBER_RANGE_DAYS)),
    to: endOfDay(today),
};

export const initialReportsFilter = `&${getRangeFilterQueryString(
    initialReportsRange
)}`;

const defaults = {
    orderingBy: 'DocumentCount' as keyof SearchAggregationResponse,
    isOrderingByDesc: true,
    filter: initialReportsFilter,
};

const ReportsContext = createContext<ReportsContextState>(
    produce(paginatedApiStateDefaults, draft => {
        draft.orderByProps.orderingBy = defaults.orderingBy;
        draft.orderByProps.isOrderingByDesc = defaults.isOrderingByDesc;
    })
);

const ReportsProvider: FunctionComponent<ReportsProviderProps> = ({
    children,
}) => {
    const { getRequestHeaders, searchIndex } = useSettings();

    const paginatedApiState = usePaginatedApi<SearchAggregationResponse, any>({
        baseUrl: config.SEARCH_OPTIMIZATION_API_BASE_URL,
        url: '/reports/aggregates',
        getAdditionalHeaders: getRequestHeaders,
        // Turn off initial fetch, so we don't fetch before settings are loaded
        runInitialFetch: false,
        defaults,
    });

    // After search index changes, refetch data
    useEffect(() => {
        if (!searchIndex) {
            return;
        }

        paginatedApiState.refetch();

        // We do NOT want to re-fetch if the paginatedApiState changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchIndex]);

    return (
        <ReportsContext.Provider value={paginatedApiState}>
            {children}
        </ReportsContext.Provider>
    );
};

const withReports = createProviderHoC(withSettings()(ReportsProvider));

export { ReportsContext, withReports };
export default withSettings()(ReportsProvider);
