import React, { createContext, FunctionComponent } from 'react';
import { createProviderHoC } from '../../../util/createProviderHoC';
import useSproutApi, { SproutApiState } from '../useSproutApi';

type BrandsContextState = SproutApiState;

const BrandsContext = createContext<BrandsContextState>(
    {} as BrandsContextState
);

const BrandsProvider: FunctionComponent = ({ children }) => {
    const sproutApi = useSproutApi({ categoryCode: 'brand' });

    return (
        <BrandsContext.Provider value={sproutApi}>
            {children}
        </BrandsContext.Provider>
    );
};

const withBrands = createProviderHoC(BrandsProvider);

export { BrandsContext, withBrands };
export default BrandsProvider;
