import React, {
    FunctionComponent,
    useReducer,
    createContext,
    useContext,
    useCallback,
    useMemo,
    useEffect,
} from 'react';
import { AlertProps, Alert } from '@oetkerdigital/eden-design-system-react';

interface State {
    message?: AlertProps['message'];
    content?: AlertProps['children'];
    variant?: AlertProps['variant'];
    isDisappearing?: AlertProps['isDisappearing'];

    /**
     * Whether the alert should disappear automatically
     */
    shouldDisappear?: boolean;
}

type AlertPayload = Pick<State, 'message' | 'content' | 'variant'>;

type Action =
    | { type: 'add'; payload: AlertPayload & Pick<State, 'shouldDisappear'> }
    | { type: 'startRemoving' }
    | { type: 'remove' };

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'add':
            // Make sure we don't just spread the payload here. Otherwise, if we
            // have an alert that replaces another alert, it would just keep any
            // properties that are not set instead of setting them `undefined`.
            return {
                message: action.payload.message,
                content: action.payload.content,
                variant: action.payload.variant,
                shouldDisappear: action.payload.shouldDisappear,
                isDisappearing: false,
            };
        case 'startRemoving':
            return {
                ...state,
                isDisappearing: true,
            };
        case 'remove':
            return {};
    }
};

interface AlertContextState {
    /**
     * Add an alert with the specified payload. Will disappear by default
     * unless `payload.content` is set.
     *
     * @param payload Relevant alert parameters
     */
    alert: (payload: AlertPayload) => void;

    /**
     * Convenience method to add an error alert with just a message.
     *
     * @param message
     */
    error: (message: AlertPayload['message']) => void;

    /**
     * Convenience method to add a success alert with just a message.
     *
     * @param message
     */
    success: (message: AlertPayload['message']) => void;

    /**
     * Remove the current alert. If there is no alert, this is a no-op.
     */
    remove: () => void;
}

const AlertContext = createContext<AlertContextState | undefined>(undefined);

interface AlertProviderProps {
    /**
     * Time in ms until alerts start being removed
     * @default 8000
     */
    startRemovingTimeout?: number;

    /**
     * Time in ms until alerts that are disappearing are removed completely
     * Set to 0 to remove alerts immediately without waiting for the animation
     *
     * @default 300
     */
    removeTimeout?: number;
}

/**
 * # AlertProvider
 *
 * This component is the central point to handle alerts in case of errors,
 * success messages, etc.
 *
 * Only one alert can be displayed at the same time, if another alert is added,
 * it will override any previously shown alert.
 *
 * Alerts disappear after 8 seconds by default if they do not have any content
 * set. If they have content set, it is assumed that the user is supposed to
 * take some form of action or has a lot of text to read, so we don't make the
 * alert disappear.
 */
export const AlertProvider: FunctionComponent<AlertProviderProps> = ({
    children,
    startRemovingTimeout = 8000,
    removeTimeout = 300,
}) => {
    const [state, dispatch] = useReducer(reducer, {});

    // We need to memoize this, otherwise infinite update loops are possible
    // when components trigger an alert in `useEffect` (e.g. `UserProfile`)
    const alert = useCallback(
        (payload: AlertPayload) => {
            const shouldDisappear = !payload.content;

            dispatch({
                type: 'add',
                payload: {
                    ...payload,
                    shouldDisappear,
                },
            });
        },
        [dispatch]
    );

    // Then we need to memoize the value of the context itself too, in case the
    // complete context value is used somewhere instead of just destructured
    const contextValue = useMemo(
        () => ({
            alert,
            error: (message: AlertPayload['message']) =>
                alert({ message, variant: 'error' }),
            success: (message: AlertPayload['message']) =>
                alert({ message, variant: 'success' }),
            remove: () =>
                dispatch({
                    type: 'startRemoving',
                }),
        }),
        [alert]
    );

    // Handle automatic removal of alerts - make sure we abort in case this was
    // unmounted in between when we set the timeouts and when time is up
    useEffect(() => {
        let isMounted = true;

        // A new alert was just added - make it disappear after 8 seconds
        if (!!state.message && !state.isDisappearing && state.shouldDisappear) {
            setTimeout(() => {
                if (!isMounted) {
                    return;
                }

                dispatch({ type: 'startRemoving' });
            }, startRemovingTimeout);
        }

        // An alert just started disappearing - remove it completely after it's done animating
        if (!!state.message && state.isDisappearing) {
            setTimeout(() => {
                if (!isMounted) {
                    return;
                }

                dispatch({ type: 'remove' });
            }, removeTimeout);
        }

        return () => {
            isMounted = false;
        };
    }, [state, startRemovingTimeout, removeTimeout]);

    return (
        <AlertContext.Provider value={contextValue}>
            {!!state.message && (
                <Alert
                    // Set `key` here so React knows when an alert is "new"
                    // so it will not just update the content, but create a
                    // completely new component, so we get the animation again
                    key={state.message}
                    message={state.message}
                    variant={state.variant}
                    isDisappearing={state.isDisappearing}
                >
                    {state.content}
                </Alert>
            )}

            {children}
        </AlertContext.Provider>
    );
};

export const useAlerts = () => {
    const context = useContext(AlertContext);

    if (context === undefined) {
        throw new Error('useAlerts must be used within an AlertProvider!');
    }

    return context;
};

/**
 * "Subscribe" to an error e.g. from a context, and show an error
 * alert when the value changes.
 *
 * @param error
 *
 * @example
 * useErrorAlert(settingsApi.fetchError);
 */
export const useErrorAlert = (error?: string) => {
    const alerts = useAlerts();

    useEffect(() => {
        if (error) {
            alerts.error(error);
        }
    }, [error, alerts]);
};
