import {
    Button,
    Heading,
    Link,
    Textfield,
} from '@oetkerdigital/eden-design-system-react';
import React, {
    FormEvent,
    FunctionComponent,
    useContext,
    useState,
} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AuthContext } from '../../components/AuthGate';
import config from '../../config';
import { useHasPermission } from '../../hooks/useHasPermission';
import { copyToClipboard } from '../../util/copyToClipboard';
import { useAlerts } from '../../components/AlertProvider';

export const Home: FunctionComponent<RouteComponentProps> = () => {
    const { api } = useContext(AuthContext);
    const [linkValue, setLinkValue] = useState('');
    const [error, setError] = useState('');
    const [shortenedUrl, setShortenedUrl] = useState('');

    const isModifier = useHasPermission('link-shortener:modify');

    const alerts = useAlerts();

    const createLink = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const [error, shortUrl] = await api.post({
            baseUrl: config.SHRTY_API_BASE_URL,
            url: '/url',
            body: {
                url: linkValue,
            },
        });

        if (error) {
            setError(error);
            return;
        }

        setLinkValue('');
        setError('');
        setShortenedUrl(`${config.SHRTY_API_BASE_URL}/${shortUrl}`);

        alerts.success('New shortened link has been generated');
    };

    return (
        <React.Fragment>
            <Heading>Shorten your Link</Heading>

            <p>Please paste your link into the textfield and hit the button.</p>

            <form
                onSubmit={createLink}
                className="mt--1 TwoColumnLayout TwoColumnLayout--2-1 TwoColumnLayout--alignEnd"
            >
                {/* TODO: something is wrong with the Textfield types here when using `disabled`, investigate */}
                {isModifier ? (
                    <Textfield
                        label="Link to shorten"
                        value={linkValue}
                        onValueChange={setLinkValue}
                        variant={!!error ? 'error' : undefined}
                        hint={error}
                    />
                ) : (
                    <Textfield label="Link to shorten" value="" disabled />
                )}

                <Button block disabled={!linkValue || !isModifier}>
                    Shorten URL
                </Button>
            </form>

            {shortenedUrl && (
                <React.Fragment>
                    <p>
                        Your link has been successfully shortened:
                        <br />
                        <Link href={shortenedUrl}>{shortenedUrl}</Link>
                    </p>
                    <Button
                        onClick={
                            /* istanbul ignore next: the function is tested and so is the onClick in RDS */ () => {
                                copyToClipboard(shortenedUrl);
                            }
                        }
                    >
                        Copy link
                    </Button>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};
