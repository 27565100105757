import React, {
    FormEvent,
    FunctionComponent,
    useContext,
    useEffect,
    useState,
} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
    Button,
    Heading,
    Textfield,
    TwoColumnLayout,
} from '@oetkerdigital/eden-design-system-react';
import { CompanyResponse } from '../../../api-types/user-manager';
import { useAlerts } from '../../../components/AlertProvider';
import { CompaniesContext, withCompanies } from './CompaniesProvider';

interface CompanyProps {
    companyId?: string;
}

const toCompanyName = (company: CompanyResponse) => company.Name;

const __CompaniesForm: FunctionComponent<RouteComponentProps<CompanyProps>> = ({
    history,
    match,
}) => {
    const companyId = match.params.companyId;

    const {
        data: allCompanies,
        create: createCompany,
        queryProps,
        fetchOne: fetchCompany,
        update: updateCompany,
    } = useContext(CompaniesContext);

    /**
     * If user is on Edit Company page then company which is going to be edited
     * should be excluded from companies array
     **/
    const companies = companyId
        ? allCompanies.filter(company => company.Id !== +companyId)
        : allCompanies;

    const [companyNameValue, setCompanyNameValue] = useState('');
    const [streetValue, setStreetValue] = useState('');
    const [houseNumberValue, setHouseNumberValue] = useState('');
    const [zipCodeValue, setZipCodeValue] = useState('');
    const [cityValue, setCityValue] = useState('');
    const [stateValue, setStateValue] = useState('');
    const [countryValue, setCountryValue] = useState('');
    const [emailValue, setEmailValue] = useState('');
    const [phoneValue, setPhoneValue] = useState('');
    const [taxNumberValue, setTaxNumberValue] = useState('');

    const companyNameError = !!companies.find(
        company => company.Name === companyNameValue
    )
        ? 'Company name already in use!'
        : '';

    const alerts = useAlerts();

    // When the value changes, query companies that might have a similar name
    useEffect(() => {
        queryProps.queryBy(companyNameValue);
    }, [companyNameValue, queryProps]);

    useEffect(() => {
        if (!companyId) {
            return;
        }

        const fetch = async () => {
            const [error, company] = await fetchCompany(companyId);

            if (error) {
                // TODO: handle error
                return;
            }

            setCompanyNameValue(company.Name);

            setStreetValue(company.Street);
            setHouseNumberValue(company.HouseNumber);
            setZipCodeValue(company.ZipCode);

            setCityValue(company.City);
            setStateValue(company.State);
            setCountryValue(company.Country);

            setEmailValue(company.Email);
            setPhoneValue(company.TelephoneNumber);
            setTaxNumberValue(company.TaxNumber);
        };

        fetch();
        // We don't want to make all fields as dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyId]);

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();

        const requestBody = {
            Name: companyNameValue,
            Street: streetValue,
            HouseNumber: houseNumberValue,
            ZipCode: zipCodeValue,
            City: cityValue,
            State: stateValue,
            Country: countryValue,
            Email: emailValue,
            TelephoneNumber: phoneValue,
            TaxNumber: taxNumberValue,
        };

        // Update company if company id exists or in other case create company
        const [error] = companyId
            ? await updateCompany(companyId, requestBody)
            : await createCompany(requestBody);

        if (error) {
            alerts.error(error);
            return;
        }

        alerts.success('The company has been saved successfully');
        history.push('/system-configurator/companies');
    };

    const isValid =
        !!companyNameValue &&
        !companyNameError &&
        !!streetValue &&
        !!houseNumberValue &&
        !!zipCodeValue &&
        !!cityValue &&
        !!countryValue;

    const hasCompanyNameWarning = !!companyNameValue && !!companies.length;

    return (
        <form className="ContentLayout" onSubmit={onSubmit}>
            <Heading>
                {!companyId
                    ? 'Create a new company'
                    : `Edit company: ${companyNameValue}`}
            </Heading>
            <p>
                Please enter the data for a new company. Please note that the
                company name has to be unique, and that entering a company name
                and address is mandatory.
            </p>

            <Heading as="h2" className="mt--1">
                Main company data
            </Heading>

            <Textfield
                label="Company name"
                value={companyNameValue}
                onValueChange={setCompanyNameValue}
                hint={companyNameError}
                variant={companyNameError ? 'error' : undefined}
                required
            />

            {hasCompanyNameWarning && (
                <p role="alert">
                    <strong>
                        <span role="img" aria-label="Warning symbol">
                            ⚠️
                        </span>{' '}
                        Warning, existing companies with similar names:{' '}
                    </strong>
                    <br />
                    <br />
                    {companies.map(toCompanyName).join(', ')}
                </p>
            )}

            <TwoColumnLayout columns="2-1">
                <Textfield
                    label="Street"
                    value={streetValue}
                    onValueChange={setStreetValue}
                    required
                />
                <Textfield
                    label="No."
                    value={houseNumberValue}
                    onValueChange={setHouseNumberValue}
                    required
                />
            </TwoColumnLayout>

            <TwoColumnLayout columns="1-2">
                <Textfield
                    label="Zip code"
                    value={zipCodeValue}
                    onValueChange={setZipCodeValue}
                    required
                />

                <Textfield
                    label="City"
                    value={cityValue}
                    onValueChange={setCityValue}
                    required
                />
            </TwoColumnLayout>

            <TwoColumnLayout columns="2-1">
                <Textfield
                    label="Country"
                    value={countryValue}
                    onValueChange={setCountryValue}
                    required
                />

                <Textfield
                    label="State"
                    value={stateValue}
                    onValueChange={setStateValue}
                />
            </TwoColumnLayout>

            <Heading as="h2" className="mt--1">
                Additional information
            </Heading>

            <Textfield
                label="Email address"
                value={emailValue}
                onValueChange={setEmailValue}
            />

            <TwoColumnLayout>
                <Textfield
                    label="Phone number"
                    value={phoneValue}
                    onValueChange={setPhoneValue}
                />
                <Textfield
                    label="Tax number"
                    value={taxNumberValue}
                    onValueChange={setTaxNumberValue}
                />
            </TwoColumnLayout>

            <Button disabled={!isValid}>Save</Button>
        </form>
    );
};

export const CompaniesForm = withCompanies({ runInitialFetch: false })(
    __CompaniesForm
);
