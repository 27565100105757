import React, {
    createContext,
    FunctionComponent,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import {
    UserResponse,
    UpdateUserProfileRequest,
} from '../../../api-types/user-manager';
import { AuthContext } from '../../../components/AuthGate';
import config from '../../../config';
import { createProviderHoC } from '../../../util/createProviderHoC';
import { ApiRequestResult } from '../../../util/createRequest';

interface CurrentUserProfileProviderProps {
    runInitialFetch?: boolean;
}

/**
 * State of the context - use property names scoped to this provider since
 * they will otherwise clash with other providers.
 */
type CurrentUserProfileContextState = {
    currentUser?: UserResponse;
    currentUserError: string;
    isCurrentUserLoading: boolean;
    refetchCurrentUser: () => void;
    updateProfile: (profile: UpdateUserProfileRequest) => Promise<boolean>;
};

const defaults: Pick<
    CurrentUserProfileContextState,
    'currentUser' | 'currentUserError' | 'isCurrentUserLoading'
> = {
    currentUser: undefined,
    currentUserError: '',
    isCurrentUserLoading: false,
};

/**
 * Cache the current user - we don't need to fetch this multiple times
 */
const cache = {
    currentUser: defaults.currentUser,
};

/**
 * Reset the cache to the defaults - for testing purposes
 */
export const resetCurrentUserCache = () => {
    cache.currentUser = defaults.currentUser;
};

const CurrentUserProfileContext = createContext<
    CurrentUserProfileContextState | undefined
>(undefined);

/**
 * A provider to fetch the current user.
 *
 * Wrap this around views that need access to the current user. The last
 * fetched user will be cached unless otherwise specified, so this will
 * only fetch the user once on first mount, and subsequently read from cache.
 */
const CurrentUserProfileProvider: FunctionComponent<CurrentUserProfileProviderProps> =
    ({ children, runInitialFetch = true }) => {
        const [isStale, setIsStale] = useState(runInitialFetch);

        const [user, setUser] = useState(cache.currentUser);
        const [error, setError] = useState(defaults.currentUserError);
        const [isLoading, setIsLoading] = useState(
            defaults.isCurrentUserLoading
        );

        const { api } = useContext(AuthContext);

        useEffect(() => {
            if (!isStale) {
                return;
            }

            let isCancelled = false;

            const load = async () => {
                setIsLoading(true);

                const [error, data]: ApiRequestResult<UserResponse> =
                    await api.get({
                        baseUrl: config.USER_MANAGER_API_BASE_URL,
                        url: '/users/current/profile',
                    });

                if (isCancelled) {
                    return;
                }

                setIsStale(false);
                setIsLoading(false);

                if (error) {
                    setError(error);
                    return;
                }

                setUser(data);
            };

            if (!user) {
                load();
            }

            return () => {
                isCancelled = true;
            };
        }, [api, user, isStale]);

        // Whenever the user changes, update the cache
        useEffect(() => {
            cache.currentUser = user;
        }, [user]);

        /**
         * Refetch the user (or run initial fetch if runInitialFetch was false)
         */
        const refetch = useCallback(() => setIsStale(true), [setIsStale]);

        const updateProfile = async (profile: UpdateUserProfileRequest) => {
            const [error] = await api.put({
                baseUrl: config.USER_MANAGER_API_BASE_URL,
                url: `/users/current/profile`,
                body: profile,
            });

            return !!error;
        };

        return (
            <CurrentUserProfileContext.Provider
                value={{
                    currentUser: user,
                    currentUserError: error,
                    isCurrentUserLoading: isLoading,
                    refetchCurrentUser: refetch,
                    updateProfile,
                }}
            >
                {children}
            </CurrentUserProfileContext.Provider>
        );
    };

const withCurrentUserProfile = createProviderHoC(CurrentUserProfileProvider);

export { withCurrentUserProfile };
export default CurrentUserProfileProvider;

export const useCurrentUserProfile = () => {
    const context = useContext(CurrentUserProfileContext);

    /* istanbul ignore if */
    if (!context) {
        throw new Error(
            '`useCurrentUser` must be used within a `CurrentUserProvider`!'
        );
    }

    return context;
};
