import React from 'react';
import { Icon } from '@oetkerdigital/eden-design-system-react';

export interface SortableTableHeadCellProps<SortKeyType>
    extends React.ThHTMLAttributes<HTMLTableHeaderCellElement> {
    /**
     * The visible text of the component - limited to strings, as the label is
     * also used within e.g the title attribute.
     */
    children: string;

    /**
     * The key that is assigned to this column, which can be sorted by.
     */
    sortKey: SortKeyType;

    /**
     * The key that the table is currently being ordered by. Based on this and
     * the sortKey, styling, title and functionality is provided depending on
     * whether this is the active column or not.
     */
    orderingBy: SortKeyType;

    /**
     * Whether the table is currently being sorted descending or not.
     */
    isOrderingByDesc: boolean;

    /**
     * Callback to change the ordering of the table, called on click of the cell.
     * This component doesn't provide any logic to figure out the new sorting order,
     * it will just tell the parent component the new key the user would like
     * to order by, which then should update this component's props accordingly
     * to reflect the new state.
     */
    orderBy: (key: SortKeyType) => void;
}

/**
 * ## SortableTableHeadCell
 *
 * This component currently only works in a fully controlled way and does not
 * manage any state itself, so all props need to always be passed in.
 *
 * The component can be instantiated with a SortKeyType to specify the type of
 * valid sort keys. If not provided, the type is inferred through the type signatures
 * of props provided (preferred - do not specify type unless really needed).
 */
export function SortableTableHeadCell<SortKeyType>({
    children,
    sortKey,
    orderingBy,
    isOrderingByDesc,
    orderBy,
    ...props
}: SortableTableHeadCellProps<SortKeyType>) {
    const isSortingByKey = orderingBy === sortKey;
    const isSortingDesc = isSortingByKey && isOrderingByDesc;
    const isSortingAsc = isSortingByKey && !isOrderingByDesc;

    const ariaSort = isSortingDesc
        ? 'descending'
        : isSortingAsc
        ? 'ascending'
        : '';

    let title = isSortingDesc
        ? `Sorting descending by ${children}, click to sort by ${children} ascending`
        : isSortingAsc
        ? `Sorting ascending by ${children}, click to sort by ${children} descending`
        : `Click to sort by ${children} ascending`;

    const ariaAttributes = {
        scope: 'col',
        role: 'columnheader',
        title,
    };

    if (ariaSort) {
        ariaAttributes['aria-sort'] = ariaSort;
    }

    return (
        <th onClick={() => orderBy(sortKey)} {...ariaAttributes} {...props}>
            {children}

            <Icon
                name={
                    isSortingByKey && isSortingDesc
                        ? 'chevron_small_up'
                        : 'chevron_small_down'
                }
            />
        </th>
    );
}
