import React, {
    FunctionComponent,
    useContext,
    useEffect,
    useState,
} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Heading, DetailField } from '@oetkerdigital/eden-design-system-react';
import { UserResponse } from '../../../api-types/user-manager';
import { getCompaniesReadable } from '../util';
import { useAlerts } from '../../../components/AlertProvider';
import { UsersContext, withUsers } from './UsersProvider';

interface RouteParams {
    userId: string;
}

const __UserDetails: FunctionComponent<RouteComponentProps<RouteParams>> = ({
    match,
}) => {
    const userId = match.params.userId;

    const [user, setUser] = useState<UserResponse | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const { fetchOne: fetchUser } = useContext(UsersContext);

    const alerts = useAlerts();

    /**
     * Fetch user by id if user is on edit page
     */
    useEffect(() => {
        const fetchUserById = async () => {
            setIsLoading(true);

            const [error, user] = await fetchUser(userId);

            setIsLoading(false);

            if (error) {
                alerts.error(`User was not fetched. Error: ${error}`);
                return;
            }

            if (user) {
                setUser(user);
            }
            return;
        };

        fetchUserById();
        // We want to fetch User only once and use this effect as componentDidMount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    return (
        <React.Fragment>
            {user && (
                <React.Fragment>
                    <Heading>
                        User: {user.FirstName} {user.LastName}
                    </Heading>
                    <p>User details can be checked here</p>

                    <DetailField name="First Name">
                        {user.FirstName}
                    </DetailField>

                    <DetailField name="Last Name">{user.LastName}</DetailField>

                    <DetailField name="Email Address">{user.Email}</DetailField>

                    <DetailField name="Companies">
                        {getCompaniesReadable(user)}
                    </DetailField>

                    <Heading className="mt--1" as="h2">
                        Roles
                    </Heading>
                    <table className="Table">
                        <thead>
                            <tr>
                                <th>Role Name</th>
                                <th>Brands</th>
                                <th>Countries</th>
                            </tr>
                        </thead>
                        <tbody>
                            {user.UserRoles.map((role: any, index: number) => {
                                return (
                                    <tr key={index}>
                                        <td>{role.Role.Name}</td>
                                        <td>
                                            {role.RoleProperties.brand.join(
                                                ', '
                                            )}
                                        </td>
                                        <td>
                                            {role.RoleProperties.country.join(
                                                ', '
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </React.Fragment>
            )}

            {isLoading && !user && <p>Loading...</p>}

            {!isLoading && !user && (
                <p role="alert">No User with this id exists</p>
            )}
        </React.Fragment>
    );
};

export const UserDetails = withUsers({ runInitialFetch: false })(__UserDetails);
