/* istanbul ignore file, because this file is util and it is tested by being used in components directly */
import { WebsiteScoreValueResponse } from '../../api-types/search';

/**
 * Convert array of array fo strings to a text we can display to the user
 * @param wordArrays
 * @example Synonyms
 */
export const wordArraysToText = (wordArrays: string[][]) => {
    const toLines = (group: string[]) => group.join(', ');
    return wordArrays.map(toLines).join('\n');
};

/**
 * Convert array of strings to a text we can display to the user
 * @param wordArray
 * @example StopWords, NegationWords, SwearWords
 */
export const wordArrayToText = (wordArray: string[]) => {
    return wordArray.join('\n');
};

/**
 * Convert user-entered text back to array of array of strings format for API
 * @param wordsText
 * @example Synonyms
 */
export const textToWordArrays = (wordsText: string) => {
    const byNonEmpty = (text: string) => !!text;
    const toTrimmed = (text: string) => text.trim();

    const toLines = (line: string) => {
        return line.split(',').map(toTrimmed).filter(byNonEmpty);
    };

    return wordsText.split('\n').filter(byNonEmpty).map(toLines);
};

/**
 * Convert user-entered text back to array of strings format for API
 * @param wordsText
 * @example StopWords, NegationWords, SwearWords
 */
export const textToWordArray = (wordsText: string) => {
    const byNonEmpty = (text: string) => !!text;
    const toTrimmed = (text: string) => text.trim();

    return wordsText.split('\n').map(toTrimmed).filter(byNonEmpty);
};

/**
 * Do not convert value and return it as is.
 * @param value
 */
export function identity<DataType extends any>(value: DataType) {
    return value;
}

// TODO: CLEAN THIS UP FURTHER

/**
 *
 * @param scores: Array of ranking scores
 * @description: Sort all ranking scores descending from highest Weight to lowest.
 *
 */
export const sortScores = (
    scores: WebsiteScoreValueResponse[]
): WebsiteScoreValueResponse[] => {
    const byDescendingWeight = (currentItem, nextItem) => {
        if (currentItem.Weight < nextItem.Weight) {
            return 1;
        }

        if (currentItem.Weight > nextItem.Weight) {
            return -1;
        }

        return 0;
    };

    return scores.sort(byDescendingWeight);
};

/**
 *
 * @param scores: Array of ranking scores
 * @description: Remove 'meta_tags.' from rankingScores tags.
 *
 */
export const formatScores = (scores: WebsiteScoreValueResponse[]) => {
    return scores.map(score => ({
        ...score,
        Key: score.Key.replace('meta_tags.', '').replace('.keyword', ''),
    }));
};
/**
 *
 * @param scoreValues Array of scores
 * @description Update score Keys with `meta_tags` prefix and add correct Weight.
 * <br>
 * Order in the array is as follows:
 * - First index should have max weight
 * - Last index should have min weight
 * <br>
 * Weights are applied from 10 to 100.
 */
export const addWeightsToScores = (
    scoreValues: WebsiteScoreValueResponse[]
) => {
    const weightStep = 10;

    scoreValues = [...scoreValues].reverse().map((score, index) => ({
        ...score,
        Key: `meta_tags.${score.Key}.keyword`,
        Weight: (index + 1) * weightStep,
    }));

    return scoreValues;
};

// TODO: move to better location
export type RowsValues = {
    tag: string;
    values: string[];
};

// TODO: move to better location
export type ScoreUpdate = {
    scoresIndex: number;
    value: string;
};

// TODO: move to better location
export interface MetaTagsValues {
    [key: string]: string[];
}

// TODO: move to better location
export interface MinMaxOptions {
    shouldBeLowerThanMax?: Boolean;
    shouldBeHigherThanMin?: Boolean;
}

// TODO: move to better location
export interface AggregationsRequestBody {
    Field: string;
    Size: number;
}

// TODO: move to better location
export interface WebsiteScoreAggregation {
    [name: string]: {
        [name: string]: number; // int32
    };
}
