import React, {
    FormEvent,
    FunctionComponent,
    useContext,
    useEffect,
    useState,
} from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import {
    Heading,
    Textfield,
    Button,
} from '@oetkerdigital/eden-design-system-react';
import { SproutItemResponse } from '../../../api-types/sprout';
import { useAlerts } from '../../../components/AlertProvider';
import { CountriesContext, withCountries } from './CountriesProvider';

interface RouteParams {
    countryId: string;
}

const __CountryForm: FunctionComponent<RouteComponentProps<RouteParams>> = ({
    match,
}) => {
    const countryId = match.params.countryId;
    const isUpdating = !!countryId;

    const [nameValue, setNameValue] = useState('');
    const [codeValue, setCodeValue] = useState('');

    const { create, update, fetchOne } = useContext(CountriesContext);

    const [country, setCountry] = useState<SproutItemResponse | null>(null);

    const alerts = useAlerts();
    const history = useHistory();

    // When the countryId parameter in the URL changes and is present, fetch data
    useEffect(() => {
        if (!countryId) {
            return;
        }

        const fetch = async () => {
            const [error, data] = await fetchOne(countryId);

            if (error || !data) {
                // TODO: handle error
                return;
            }

            setCountry(data);
        };

        fetch();
        // No need to re-run when fetchOne changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryId]);

    // When we have a new country, fill the fields with the new values
    useEffect(() => {
        if (!country) {
            return;
        }

        setNameValue(country.Name);
        setCodeValue(country.Code);

        // We do not want to re-run this when the fields change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [country]);

    // Countries are only valid if all fields are filled
    const isValid = !!nameValue && !!codeValue;

    // On form submit, gather values and either update or create country
    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (!isValid) {
            return;
        }

        const body = {
            Name: nameValue,
            Code: codeValue,
        };

        const [error] = isUpdating
            ? await update(countryId, { ...country, ...body })
            : await create(body);

        if (error) {
            // TODO: handle errors
            return;
        }

        alerts.success('The country has been saved successfully');
        history.push('/service-configurator/countries');
    };

    return (
        <React.Fragment>
            <Heading>
                {isUpdating
                    ? `Edit country: ${nameValue}`
                    : 'Create new country'}
            </Heading>
            <p>
                Please enter data for the country. The code needs to match the
                country used in the search index names!
            </p>
            <form className="ContentLayout" onSubmit={onSubmit}>
                <Textfield
                    label="Name"
                    value={nameValue}
                    onValueChange={setNameValue}
                    required
                />

                <Textfield
                    label="Code"
                    value={codeValue}
                    onValueChange={setCodeValue}
                    required
                />

                <Button disabled={!isValid}>Save</Button>
            </form>
        </React.Fragment>
    );
};

export const CountryForm = withCountries()(__CountryForm);
