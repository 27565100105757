/**
 * Return a param from a query string (simple version)
 * @param {string} queryString
 * @param {string} param
 */
export const getQueryParam = (queryString: string, param: string) => {
    const querySplit = queryString.split(/[?&=]/);

    const paramIndex = querySplit.findIndex(p => p === param);

    return querySplit[paramIndex + 1];
};
