import React from 'react';
import { Redirect } from 'react-router-dom';
import { RoutingRoute } from '../../components/Routing';
import { createUserPermissionValidator } from '../../util/createUserPermissionValidator';
import { BrandForm } from './brands/BrandForm';
import { BrandsList } from './brands/BrandsList';
import { CountriesList } from './countries/CountriesList';
import { CountryForm } from './countries/CountryForm';

const prefix = '/service-configurator';

const routes: RoutingRoute[] = [
    {
        path: prefix,
        label: 'Service Configurator',
        component: () => <Redirect to={`${prefix}/brands`} />,
        validateUser: createUserPermissionValidator('sprout:read'),
        subItems: [
            {
                path: `${prefix}/brands`,
                label: 'Brands',
                component: BrandsList,
            },
            {
                path: `${prefix}/brands/new`,
                label: 'Create new brand',
                component: BrandForm,
                hideInMenu: true,
            },
            {
                path: `${prefix}/brands/:brandId/edit`,
                label: 'Edit brand',
                component: BrandForm,
                hideInMenu: true,
            },
            {
                path: `${prefix}/countries`,
                label: 'Countries',
                component: CountriesList,
            },
            {
                path: `${prefix}/countries/new`,
                label: 'Create new country',
                component: CountryForm,
                hideInMenu: true,
            },
            {
                path: `${prefix}/countries/:countryId/edit`,
                label: 'Edit country',
                component: CountryForm,
                hideInMenu: true,
            },
        ],
    },
];

export default routes;
