/**
 * Copy a string to the user's clipboard
 * @see https://hackernoon.com/copying-text-to-clipboard-with-javascript-df4d4988697f
 * @param {string} textToCopy
 */
export const copyToClipboard = (textToCopy: string) => {
    const el = document.createElement('textarea');
    el.value = textToCopy;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);

    const currentSelection = document.getSelection();
    let previouslySelected;

    // store previous selection
    if (currentSelection !== null && currentSelection.rangeCount > 0) {
        previouslySelected = currentSelection.getRangeAt(0);
    }

    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    // restore previous selection
    if (currentSelection !== null && previouslySelected) {
        currentSelection.removeAllRanges();
        currentSelection.addRange(previouslySelected);
    }
};
