import { UserManager } from 'oidc-client';
import React, { useEffect } from 'react';
import {
    Link,
    Route,
    RouteComponentProps,
    Switch,
    useLocation,
} from 'react-router-dom';
import {
    SidebarProvider,
    SidebarMenuItem,
} from '@oetkerdigital/eden-design-system-react';
import { User } from '../auth';
import { useUser } from '../hooks/useUser';
import { DefaultLayout } from '../application/base/DefaultLayout';
import { AuthGate, AuthGateProps } from './AuthGate';
import { OidcProvider } from './OidcProvider';

interface RoutingProps {
    routes: RoutingRoute[];
    NotFound: React.ComponentType<RouteComponentProps>;
    CustomLayout?: React.ComponentType<any>;
    authController: UserManager;
    onLoginSuccess?: AuthGateProps['onLoginSuccess'];
}

export interface RoutingRoute {
    path: string;
    component?: React.ComponentType<RouteComponentProps<any>>;
    label: string;
    subItems?: RoutingRoute[];
    public?: boolean;
    noLayout?: boolean;
    hideInMenu?: boolean;
    externalLink?: boolean;
    /**
     * Method to validate the user to conditionally show/hide routes depending on permissions.
     */
    validateUser?: (user: User) => boolean;
}

const byProtected = (route: RoutingRoute) => route.public !== true;
const byUnprotected = (route: RoutingRoute) => route.public === true;
const byValid = (user: User | null) => (route: RoutingRoute) => {
    const validateUser = (routeItem: RoutingRoute) =>
        !!user && (!routeItem.validateUser || routeItem.validateUser(user));
    /**
     * If route has any subItems (child routes) they also need to be validated against user permissions
     * to understand if specific user can access certain route
     */
    if (route.subItems && !!route.subItems.length) {
        route.subItems = route.subItems.filter(validateUser);
    }
    return validateUser(route);
};
const byInternalLinks = (route: RoutingRoute) => !route.externalLink;

export const transformToMenuItems = (
    items?: RoutingRoute[]
): SidebarMenuItem[] => {
    if (items && items.length > 0) {
        return items
            .filter(item => !item.hideInMenu)
            .map(item => {
                const hasSubs = item.subItems && item.subItems.length > 0;

                if (item.externalLink) {
                    return {
                        title: item.label,
                        url: item.path,
                    };
                }

                return {
                    title: item.label,
                    linkElement: <Link to={item.path}>{item.label}</Link>,
                    subItems: hasSubs
                        ? transformToMenuItems(item.subItems)
                        : undefined,
                };
            });
    }

    return [];
};

export const findActiveMenuItemTitle = (
    activeUrl: string,
    items: RoutingRoute[]
) => {
    for (let i = 0; i < items.length; i++) {
        const item = items[i];

        if (item.path === activeUrl) {
            return item.label;
        }

        if (item.subItems) {
            const activeSubItemTitle = findActiveMenuItemTitle(
                activeUrl,
                item.subItems
            );

            if (activeSubItemTitle) {
                return activeSubItemTitle;
            }
        }
    }

    return '';
};

const ProtectedRouting = ({
    routes,
    toRouteComponent,
    NotFound,
    onLoginSuccess,
}) => {
    const user = useUser();

    useEffect(() => {
        if (onLoginSuccess) {
            onLoginSuccess(user);
        }
    }, [onLoginSuccess, user]);

    return (
        <Switch>
            {/* Now try all protected routes */}
            {routes
                .filter(byInternalLinks)
                .filter(byProtected)
                .filter(byValid(user))
                .map(toRouteComponent)}

            {/* If there's still no match, render 404 */}
            {/* TODO: Find a way to move it out of ProtectedRouting so that NotFound isn't wrapped with the Layout */}
            <Route component={NotFound} />
        </Switch>
    );
};

const toRoutePath = (route: RoutingRoute) => [
    route.path,
    route.subItems && [...route.subItems.map(toRoutePath)],
];

const PageLayout: React.FC<{
    routes: RoutingRoute[];
    Layout: React.ComponentType<any>;
}> = ({ children, routes, Layout }) => {
    const user = useUser();
    const { pathname } = useLocation();

    const initialActiveMenuItem = findActiveMenuItemTitle(pathname, routes);

    const menuItems = transformToMenuItems(
        routes.filter(route => route.public !== true).filter(byValid(user))
    );

    return (
        <SidebarProvider
            initialActiveMenuItem={initialActiveMenuItem}
            menuItems={menuItems}
        >
            <Layout>{children}</Layout>
        </SidebarProvider>
    );
};

const toFlat = (acc: string[], val: string) => acc.concat(val);

export const Routing = ({
    routes,
    authController,
    NotFound,
    CustomLayout,
    onLoginSuccess,
}: RoutingProps) => {
    const Layout = CustomLayout || DefaultLayout;

    /**
     * Map a route config to a Route component that conditionally renders a layout
     * @param route
     */
    const toRouteComponent = (route: RoutingRoute) => [
        <Route
            exact
            key={route.path}
            path={route.path}
            render={props => {
                if (route.component === undefined) {
                    return null;
                }

                return <route.component {...props} />;
            }}
        />,
        route.subItems && [...route.subItems.map(toRouteComponent)],
    ];

    // Get path of all protected routes + path for handling the logout/login
    const protectedRoutePath = routes
        .filter(byInternalLinks)
        .filter(byProtected)
        .map(toRoutePath)
        // don't use `flat` because people use IE 11
        .map(item => item.reduce(toFlat, []).reduce(toFlat, []))
        .reduce(toFlat, [])
        .filter((item: string | undefined) => item !== undefined)
        .concat('/complete-login', '/complete-logout', '/complete-refresh');

    return (
        // If a default layout is set, render it as fallback for lazy loaded routes
        // This means that any routes that don't use the default layouts should
        // not be lazy-loaded for now, or we will have a brief flash.
        <OidcProvider userManager={authController}>
            <Switch>
                {/* Try all unprotected routes first to check if we have a match */}
                {/* TODO: Handle case for protected routes with noLayout. For now this works */}
                {routes
                    .filter(byInternalLinks)
                    .filter(byUnprotected)
                    .map(toRouteComponent)}

                {/* If no unprotected route matches, render a fallback route that renders the auth gate */}
                <Route
                    path={protectedRoutePath}
                    exact
                    render={() => (
                        <AuthGate>
                            <PageLayout routes={routes} Layout={Layout}>
                                <ProtectedRouting
                                    routes={routes}
                                    toRouteComponent={toRouteComponent}
                                    NotFound={NotFound}
                                    onLoginSuccess={onLoginSuccess}
                                />
                            </PageLayout>
                        </AuthGate>
                    )}
                />

                <Route component={NotFound} />
            </Switch>
        </OidcProvider>
    );
};
